import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { ButtonLink } from '@package/components';

const messages = defineMessages({
  come_arrivare: {
    id: 'COME ARRIVARE',
    defaultMessage: 'COME ARRIVARE',
  },
  dove_dormire: {
    id: 'DOVE DORMIRE',
    defaultMessage: 'DOVE DORMIRE',
  },
  dove_mangiare: {
    id: 'DOVE MANGIARE',
    defaultMessage: 'DOVE MANGIARE',
  },
});

const TouristInfoBox = ({
  how_to_reach_link,
  where_to_eat_link,
  where_to_sleep_link,
  intl,
}) => {
  return how_to_reach_link || where_to_eat_link || where_to_sleep_link ? (
    <div className="info-box">
      <div className="info-box-header">
        <FormattedMessage id="Info utili" defaultMessage="Info utili" />
      </div>

      <div className="link-list">
        {how_to_reach_link && (
          <ButtonLink
            to={how_to_reach_link}
            title={intl.formatMessage(messages.come_arrivare)}
            _className="link-item"
            button={false}
          />
        )}
        {where_to_sleep_link && (
          <ButtonLink
            to={where_to_sleep_link}
            title={intl.formatMessage(messages.dove_dormire)}
            _className="link-item"
            button={false}
          />
        )}
        {where_to_eat_link && (
          <ButtonLink
            to={where_to_eat_link}
            title={intl.formatMessage(messages.dove_mangiare)}
            _className="link-item"
            button={false}
          />
        )}
      </div>
    </div>
  ) : null;
};

TouristInfoBox.propTypes = {
  how_to_reach_link: PropTypes.string,
  where_to_eat_link: PropTypes.string,
  where_to_sleep_link: PropTypes.string,
};

export default injectIntl(TouristInfoBox);
