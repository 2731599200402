import React from 'react';

import { flattenToAppURL } from '@plone/volto/helpers';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/**
 * Gallery view component class.
 * @function Gallery
 * @params {object} content Content object.
 * @params {string} folder name where to find images.
 * @returns {string} Markup of the component.
 */
const Gallery = ({ items, customSettings, imageScale = 'gallery' }) => {
  let images = items.filter((item) => item['@type'] === 'Image');

  const settings = {
    dots: true,
    infinite: images.length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    ...customSettings,
  };

  return (
    <>
      {images.length > 0 ? (
        <div className="carousel-wrapper">
          <Slider {...settings}>
            {images
              .filter((image) => image != null)
              .map((item, i) => (
                <div className="single-slide-wrapper" key={i}>
                  <figure>
                    <img
                      src={flattenToAppURL(
                        item.image?.scales?.[imageScale]?.download,
                      )}
                      alt={item.title}
                      loading="lazy"
                    />
                  </figure>
                </div>
              ))}
          </Slider>
        </div>
      ) : null}
    </>
  );
};
export default Gallery;
