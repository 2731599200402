/**
 * Edit text block.
 * @module components/Widgets/TextEditorWidget/TextEditorWidget
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import { includes, isEqual } from 'lodash';

import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import loadable from '@loadable/component';
import config from '@plone/volto/registry';

const Editor = loadable(() => import('draft-js-plugins-editor'));

const messages = defineMessages({
  text: {
    id: 'Type text…',
    defaultMessage: 'Type text…',
  },
});

/**
 * Edit text block class.
 * @class Edit
 * @extends Component
 */
class TextEditorWidgetComponent extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    fieldName: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    nextFocus: PropTypes.any,
    prevFocus: PropTypes.any,
    blockRenderMap: PropTypes.any,
    showToolbar: PropTypes.bool,
    enablePlugins: PropTypes.bool,
    disableMoveToNearest: PropTypes.bool,
    textPlain: PropTypes.bool,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    showToolbar: true,
    enablePlugins: true,
    textPlain: false,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    if (!__SERVER__) {
      let editorState;
      this.draftConfig = config.settings.richtextEditorSettings(props);
      const {
        EditorState,
        convertFromRaw,
        DefaultDraftBlockRenderMap,
      } = this.props.draftJs;
      const createInlineToolbarPlugin =
        props.draftJsInlineToolbarPlugin.default;
      const { stateFromHTML } = props.draftJsImportHtml;

      if (props.data && props.data[props.fieldName]) {
        if (props.textPlain) {
          editorState = EditorState.createWithContent(
            stateFromHTML(props.data.title),
          );
        } else {
          editorState = EditorState.createWithContent(
            convertFromRaw(props.data[props.fieldName]),
          );
        }
      } else {
        editorState = EditorState.createEmpty();
      }

      // if (props.data) {
      //   if (!props.data.blockRenderMap) {
      //     this.blockRenderMap = this.draftConfig.extendedBlockRenderMap;
      //   } else {
      //     this.blockRenderMap = DefaultDraftBlockRenderMap.merge(
      //       props.data.blockRenderMap,
      //     );
      //   }
      // }

      this.blockRenderMap = props.blockRenderMap
        ? DefaultDraftBlockRenderMap.merge(props.blockRenderMap)
        : this.draftConfig.extendedBlockRenderMap;
      this.blockStyleFn = props.blockStyleFn ?? this.draftConfig.blockStyleFn;

      const inlineToolbarPlugin = createInlineToolbarPlugin({
        structure: this.draftConfig.richTextEditorInlineToolbarButtons,
      });

      this.state = {
        editorState,
        inlineToolbarPlugin,
        addNewBlockOpened: false,
      };
    }
  }

  /**
   * Component will receive props
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (this.props.selected) {
      this.node.focus();
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { EditorState } = this.props.draftJs;

    if (!this.props.selected && nextProps.selected) {
      this.node.focus();

      this.setState({
        editorState: EditorState.moveFocusToEnd(this.state.editorState),
      });
    }
  }

  /**
   * Change handler
   * @method onChange
   * @param {object} editorState Editor state.
   * @returns {undefined}
   */
  onChange = (editorState) => {
    const { convertToRaw } = this.props.draftJs;

    if (
      !isEqual(
        convertToRaw(editorState.getCurrentContent()),
        convertToRaw(this.state.editorState.getCurrentContent()),
      )
    ) {
      this.props.onChangeBlock({
        ...this.props.data,
        [this.props.fieldName]: editorState.getCurrentContent().hasText()
          ? this.props.textPlain
            ? editorState.getCurrentContent().getPlainText()
            : convertToRaw(editorState.getCurrentContent())
          : null,
      });
    }

    this.setState({ editorState });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    const { InlineToolbar } = this.state.inlineToolbarPlugin;
    let placeholder = this.props.placeholder
      ? this.props.placeholder
      : this.props.intl.formatMessage(messages.text);
    let disableMoveToNearest = this.props.disableMoveToNearest;

    const isSoftNewlineEvent = this.props.draftJsLibIsSoftNewlineEvent.default;
    const { RichUtils } = this.props.draftJs;

    return (
      <>
        <div className={[this.props.fieldName]}>
          <Editor
            onChange={this.onChange}
            editorState={this.state.editorState}
            plugins={
              this.props.enablePlugins
                ? [
                    this.state.inlineToolbarPlugin,
                    ...this.draftConfig.richTextEditorPlugins,
                  ]
                : []
            }
            blockRenderMap={this.blockRenderMap}
            blockStyleFn={this.blockStyleFn}
            placeholder={placeholder}
            ref={(node) => {
              this.node = node;
            }}
            handleReturn={(e) => {
              if (isSoftNewlineEvent(e)) {
                this.onChange(
                  RichUtils.insertSoftNewline(this.state.editorState),
                );
                return 'handled';
              }

              if (
                !disableMoveToNearest &&
                this.props.onSelectBlock &&
                this.props.onAddBlock
              ) {
                const selectionState = this.state.editorState.getSelection();
                const anchorKey = selectionState.getAnchorKey();
                const currentContent = this.state.editorState.getCurrentContent();
                const currentContentBlock = currentContent.getBlockForKey(
                  anchorKey,
                );
                const blockType = currentContentBlock.getType();
                if (!includes(config.settings.listBlockTypes, blockType)) {
                  this.props.onSelectBlock(
                    this.props.onAddBlock('text', this.props.index + 1),
                  );
                  return 'handled';
                }
                return 'un-handled';
              }

              return {};
            }}
            onUpArrow={(e) => {
              if (this.props.prevFocus) {
                this.props.prevFocus();
                e.stopPropagation();
              } else {
                if (this.props.disableMoveToNearest) {
                  e.stopPropagation();
                }
              }
            }}
            onDownArrow={(e) => {
              if (this.props.nextFocus) {
                this.props.nextFocus();
                e.stopPropagation();
              } else {
                if (this.props.disableMoveToNearest) {
                  e.stopPropagation();
                }
              }
            }}
          />
          {this.props.showToolbar && <InlineToolbar />}
        </div>
      </>
    );
  }
}

export const TextEditorWidget = compose(
  injectIntl,
  injectLazyLibs([
    'draftJs',
    'draftJsLibIsSoftNewlineEvent',
    'draftJsFilters',
    'draftJsInlineToolbarPlugin',
    'draftJsBlockBreakoutPlugin',
    'draftJsCreateInlineStyleButton',
    'draftJsCreateBlockStyleButton',
    'draftJsImportHtml',
    'immutableLib',
    // TODO: add all plugin dependencies, also in Wysiwyg and Cell
  ]),
)(TextEditorWidgetComponent);

const Preloader = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    Editor.load().then(() => setLoaded(true));
  }, []);
  return loaded ? <TextEditorWidget {...props} /> : null;
};

export default Preloader;
