import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import TokenWidget from '@plone/volto/components/manage/Widgets/TokenWidget';
import QuerystringWidget from '@plone/volto/components/manage/Widgets/QuerystringWidget';

const Sidebar = (props) => {
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="SearchExperiencesBlock"
            defaultMessage="Ricerca esperienze"
          />
        </h2>
      </header>
      <Segment className="form sidebar-listing-data">
        <TokenWidget
          id="prices_filters"
          title="Fasce di prezzo"
          description="Seleziona le fascie di prezzo da mostare nei filtri della ricerca."
          widgetOptions={{
            vocabulary: {
              '@id': 'plone.app.vocabularies.Keywords',
            },
          }}
          value={props.data.prices_filters || []}
          onChange={(name, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              prices_filters: value,
            });
          }}
        />

        <div className="inline field">
          <Grid>
            <Grid.Row stretched>
              <Grid.Column width={12}>
                <div className="simple-field-name">Criteri di default</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              <Grid.Column width={12}>
                <p className="help">
                  Imposta il criterio di default per ricercare le esperienze
                  schedulate (realizzate con il tipo di contenuto Evento.)
                  <br /> Il criterio consigliato è: 'Categoria' ->
                  'Corrispondente a uno o più dei seguenti valori' e scegli le
                  categorie di esperienze con cui hai taggato gli eventi.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <QuerystringWidget
          id="source"
          required={false}
          value={props.data.querystring || []}
          onChange={(name, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              querystring: value,
            });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
