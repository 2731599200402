import React from 'react';
import SliderWrapper from './SliderWrapper';
import { Container } from 'semantic-ui-react';
import { ImageFromUrl } from '@package/components';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';

const Body = (data) => {
  return data?.slides ? (
    <div
      className={`size-${data.size || 'l'} ${
        data.fullwidth ? 'full-width' : ''
      } ${data.leadimage ? 'in-leadimage' : ''}`}
    >
      <SliderWrapper autoplay={data.autoplay} n_items={data?.slides?.length}>
        {data.slides.map((slide) => (
          <div
            className={cx('single-slide-wrapper', {
              'no-opacity': slide.no_opacity,
            })}
          >
            <div className="image-wrapper">
              <ImageFromUrl
                url={slide.image?.[0]?.['@id']}
                className="bg"
                role="presentation"
                alt=""
                size="leadimage"
              />
            </div>
            <div className="text-overlay">
              <Container>
                <div className="text-overlay-content">
                  {slide.title && (
                    <div className="title">
                      {slide.href?.[0] && !slide.href_title ? (
                        <UniversalLink item={slide.href[0]}>
                          {slide.title}
                        </UniversalLink>
                      ) : (
                        <>{slide.title}</>
                      )}
                    </div>
                  )}
                  {slide.description && (
                    <div className="description">{slide.description}</div>
                  )}
                  {slide.href?.[0] && slide.href_title && (
                    <div className="link">
                      <UniversalLink
                        className="ui button yellow"
                        item={slide.href[0]}
                      >
                        {slide.href_title}
                      </UniversalLink>
                    </div>
                  )}
                </div>
              </Container>
            </div>
          </div>
        ))}
      </SliderWrapper>
    </div>
  ) : null;
};
export default Body;
