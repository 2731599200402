import { union } from 'lodash';
import moment from 'moment';

export const datesForDisplay = (start, end) => {
  const mStart = moment(start);
  const mEnd = moment(end);
  if (!mStart.isValid() || !mEnd.isValid()) {
    return null;
  }
  const sameYear = mStart.isSame(mEnd, 'year');
  const sameMonth = mStart.isSame(mEnd, 'month');
  const sameDay = mStart.isSame(mEnd, 'day');
  const sameTime = mStart.isSame(mEnd, 'minute');

  return {
    sameYear,
    sameMonth,
    sameDay,
    sameTime,
    mStart,
    mEnd,
  };
};

export const getQueryFilter = (query, i, o) => {
  let ret = null;
  query.forEach((f) => {
    if (i && f.i === i && o && o === f.o) {
      ret = f;
    }
  });
  return ret;
};

export const getFiltersValues = (data, filters_vocabulary) => {
  let filtersValues = {
    subjects: null,
    prices: null,
  };

  filtersValues.thematic_areas = filters_vocabulary?.data?.thematic_areas?.map(
    (subj) => ({
      key: subj,
      text: subj,
      value: subj,
    }),
  );

  filtersValues.subjects = filters_vocabulary?.data?.Subject?.filter(
    (subj) =>
      !subj.startsWith('APP') && (data.prices_filters ?? []).indexOf(subj) < 0, //escludi quelli che iniziano con APP e che sono fra i filtri dei prezzi
  ).map((subj, i) => ({
    key: subj,
    text: subj,
    value: subj,
  }));

  filtersValues.prices = data.prices_filters?.map((subj) => ({
    key: subj,
    text: subj,
    value: subj,
  }));

  filtersValues.municipalities = filters_vocabulary?.data?.municipality?.map(
    (municipality) => ({
      key: municipality.value,
      text: municipality.label,
      value: municipality.value,
    }),
  );

  return filtersValues;
};

export const getFilters = (
  data,
  text,
  subject,
  subject_prices,
  municipality,
  thematic_areas,
  date,
) => {
  const date_fmt = 'YYYY-MM-DD HH:mm';
  const defaultQuery = data.querystring?.query ?? [];

  let filters = JSON.parse(JSON.stringify(defaultQuery));

  if (text && text.length) {
    filters.push({
      i: 'SearchableText',
      o: 'plone.app.querystring.operation.string.contains',
      v: text + '*',
    });
  }

  const user_subjects = [...(subject ?? []), ...(subject_prices ?? [])];
  if (user_subjects?.length > 0) {
    let defaultSubjectCondition = getQueryFilter(
      filters,
      'Subject',
      'plone.app.querystring.operation.selection.all',
    );

    if (defaultSubjectCondition) {
      defaultSubjectCondition.v = union(defaultSubjectCondition.v, [
        user_subjects,
      ]);
    } else {
      filters.push({
        i: 'Subject',
        o: 'plone.app.querystring.operation.selection.all',
        v: user_subjects,
      });
    }
  }

  if (municipality) {
    let defaultMunicipalityCondition = getQueryFilter(
      filters,
      'municipality',
      'plone.app.querystring.operation.selection.any',
    );

    if (defaultMunicipalityCondition) {
      defaultMunicipalityCondition.v = union(defaultMunicipalityCondition.v, [
        municipality,
      ]);
    } else {
      filters.push({
        i: 'municipality',
        o: 'plone.app.querystring.operation.selection.any',
        v: [municipality],
      });
    }
  }

  if (thematic_areas?.length > 0) {
    filters.push({
      i: 'thematic_area',
      o: 'plone.app.querystring.operation.selection.any',
      v: thematic_areas,
    });
  }

  if (date.startDate) {
    filters.push({
      i: 'start',
      o: 'plone.app.querystring.operation.date.largerThan',
      v: date.startDate.startOf('day')?.format(date_fmt),
    });
  }

  if (date.endDate) {
    filters.push({
      i: 'end',
      o: 'plone.app.querystring.operation.date.lessThan',
      v: date.endDate.endOf('day')?.format(date_fmt),
    });
  }

  return filters;
};

export const getFiltersStrings = (
  intl,
  messages,
  filtersValues,
  date,
  subject,
  municipality,
  subject_prices,
) => {
  let filtersStrings = [];

  if (date.startDate && date.endDate) {
    const { sameYear, sameMonth, mStart, mEnd } = datesForDisplay(
      date.startDate,
      date.endDate,
    );

    filtersStrings.push(
      ` ${intl.formatMessage(
        messages.experienceSearchFromDate,
      )} ${mStart.format(
        sameMonth && sameYear ? 'D' : sameYear ? 'D MMMM' : 'LL',
      )} ${intl.formatMessage(messages.experienceSearchToDate)} ${mEnd.format(
        'LL',
      )}`,
    );
  }

  if (subject) {
    filtersStrings.push(
      ` ${intl.formatMessage(
        messages.experienceSearchInSubject,
      )} '${subject.join(', ')}'`,
    );
  }

  if (municipality && filtersValues.municipalities) {
    let m = filtersValues.municipalities.filter((mun) => {
      if (mun.key === municipality) {
        return true;
      }
      return false;
    })[0];

    filtersStrings.push(
      ` ${intl.formatMessage(messages.experienceSearchInMunicipality)} '${
        m.text
      }'`,
    );
  }

  if (subject_prices?.length > 0) {
    let str_prices = subject_prices.join(', ');
    filtersStrings.push(
      ` ${intl.formatMessage(
        messages.experienceSearchInPrices,
      )} '${str_prices}'`,
    );
  }

  return filtersStrings;
};
