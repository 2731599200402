/**
 * Document view component.
 * @module components/theme/View/DefaultView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import { defineMessages, injectIntl } from 'react-intl';

import { Container, Image, Grid } from 'semantic-ui-react';
import { map } from 'lodash';
import { srcset } from '@package/helpers';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
  getBaseUrl,
} from '@plone/volto/helpers';
import { SideMenu } from '@package/components';

import config from '@plone/volto/registry';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

/**
 * Component to display the default view.
 * @function DefaultView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const DefaultView = ({ content, intl, location }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const withSubmenu = content.root_side_menu?.length > 0;

  return hasBlocksData(content) ? (
    <div id="page-document" className="ui container">
      <Helmet title={content.title} />
      <Grid stackable>
        <Grid.Column computer={withSubmenu ? 8 : 12} tablet={12}>
          {map(content[blocksLayoutFieldname].items, (block, index) => {
            const Block =
              config.blocks.blocksConfig[
                content[blocksFieldname]?.[block]?.['@type']
              ]?.['view'] || null;
            return Block !== null ? (
              <Block
                key={block + index}
                id={block}
                properties={content}
                data={content[blocksFieldname][block]}
                path={getBaseUrl(location?.pathname || '')}
              />
            ) : (
              <div key={block + index}>
                {intl.formatMessage(messages.unknownBlock, {
                  block: content[blocksFieldname]?.[block]?.['@type'],
                })}
              </div>
            );
          })}
        </Grid.Column>
        {withSubmenu && (
          <Grid.Column computer={4} tablet={12}>
            <SideMenu
              menuRoot={content.root_side_menu[0]['@id']}
              path={content['@id']}
            />
          </Grid.Column>
        )}
      </Grid>
    </div>
  ) : (
    <Container id="page-document">
      <Helmet title={content.title} />
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.image && (
        <Image
          className="document-image"
          src={content.image.scales.thumb.download}
          srcSet={srcset(content.image, 'preview')}
          alt=""
          role="presentation"
          floated="right"
          loading="lazy"
        />
      )}
      {content.remoteUrl && (
        <span>
          The link address is:
          <a href={content.remoteUrl}>{content.remoteUrl}</a>
        </span>
      )}
      {content.text && (
        <div
          dangerouslySetInnerHTML={{
            __html: content.text.data.replace(
              /a href="([^"]*\.[^"]*)"/g,
              `a href="${config.settings.apiPath}$1/download/file"`,
            ),
          }}
        />
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DefaultView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Text of the object
     */
    text: PropTypes.shape({
      /**
       * Data of the text of the object
       */
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default injectIntl(DefaultView);
