import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { getContent, resetContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { GoogleMap } from '@package/components';
import config from '@plone/volto/registry';

//http://localhost:8080/Plone/some-content@same_thematic_area
const ThematicAreaPoints = ({ path }) => {
  const url =
    (path.includes(config.settings.apiPath) ? flattenToAppURL(path) : path) +
    '/@same_thematic_area';

  const content = useSelector((state) => state.content.subrequests[url]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContent(url, null, url));

    return () => dispatch(resetContent(url));
  }, [url, dispatch]);

  //console.log('ThematicAreaPoints', content?.data?.items);
  let venues = [];

  if (content?.data?.items?.length > 0) {
    let filteredItems = content.data.items.filter((item) => {
      return item.geolocation?.lat && item.geolocation?.lng;
    });
    venues = filteredItems.map((item) => {
      return {
        lat: parseFloat(item.geolocation.lat),
        lng: parseFloat(item.geolocation.lng),
        title: item.title,
        price: item.prices?.length ? item.prices[0] : null,
        link: flattenToAppURL(item['@id']),
      };
    });
  }
  return venues.length > 0 ? (
    <section className="same-thematicarea-points">
      <h2>
        <FormattedMessage
          id="Punti di interesse dello stesso tema"
          defaultMessage="Punti di interesse dello stesso tema"
        />
      </h2>

      <GoogleMap venues={venues} height="650px" defaultZoom={18} />
    </section>
  ) : null;
};

ThematicAreaPoints.propTypes = {
  path: PropTypes.string.isRequired,
};

export default ThematicAreaPoints;
