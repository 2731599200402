import React from 'react';

import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';
import { AlignWidget } from '@package/components/';

const messages = defineMessages({
  align: {
    id: 'Align title',
    defaultMessage: 'Allinea',
  },
});

const Sidebar = ({ data, onChangeBlock, block, intl }) => {
  if (!data.align) {
    data.align = 'center';
  }
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="TitleVM" defaultMessage="Titolo" />
        </h2>
      </header>

      <Segment className="form sidebar-titleVM">
        <AlignWidget
          align={data.align}
          onChange={align => {
            onChangeBlock('align', align);
          }}
          alignments={['center', 'left']}
          label={intl.formatMessage(messages.align)}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  block: PropTypes.string.isRequired,
};

export default compose(withObjectBrowser, injectIntl)(Sidebar);
