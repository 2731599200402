/**
 * View html block.
 * @module components/manage/Blocks/HTML/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DangerouslySetHtmlContent } from '@package/components';
/**
 * View html block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => (
  <DangerouslySetHtmlContent
    html={data.html}
    className="block trekksoft-embed"
  />
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
