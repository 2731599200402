/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import voltoSocialSettings from 'volto-social-settings';
import voltoSubblocks from 'volto-subblocks';
import voltoDropdownmenu from 'volto-dropdownmenu';
import voltoGoogleAnalytics from 'volto-google-analytics';
import voltoGdprPrivacy from 'volto-gdpr-privacy';

const addonsInfo = [{"name":"volto-social-settings","version":"2.2.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/builds/redturtle/modenatur/modenatur-volto/node_modules/volto-social-settings/src","packageJson":"/builds/redturtle/modenatur/modenatur-volto/node_modules/volto-social-settings/package.json","addons":[]},{"name":"volto-subblocks","version":"1.2.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/builds/redturtle/modenatur/modenatur-volto/node_modules/volto-subblocks/src","packageJson":"/builds/redturtle/modenatur/modenatur-volto/node_modules/volto-subblocks/package.json","addons":[]},{"name":"volto-dropdownmenu","version":"2.4.3","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/builds/redturtle/modenatur/modenatur-volto/node_modules/volto-dropdownmenu/src","packageJson":"/builds/redturtle/modenatur/modenatur-volto/node_modules/volto-dropdownmenu/package.json","addons":[]},{"name":"volto-google-analytics","version":"1.3.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/builds/redturtle/modenatur/modenatur-volto/node_modules/volto-google-analytics/src","packageJson":"/builds/redturtle/modenatur/modenatur-volto/node_modules/volto-google-analytics/package.json","addons":[]},{"name":"volto-gdpr-privacy","version":"1.3.11","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/builds/redturtle/modenatur/modenatur-volto/node_modules/volto-gdpr-privacy/src","packageJson":"/builds/redturtle/modenatur/modenatur-volto/node_modules/volto-gdpr-privacy/package.json","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [voltoSocialSettings, voltoSubblocks, voltoDropdownmenu, voltoGoogleAnalytics, voltoGdprPrivacy];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
