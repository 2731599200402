/**
 * View text block.
 * @module components/manage/Blocks/Text/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import ViewBlock from './Block/ViewBlock';
import { Grid } from 'semantic-ui-react';
/**
 * View text block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  return (
    <div className="textBlocks">
      <Grid stackable columns="equal" verticalAlign="top">
        <Grid.Row columns={3}>
          {data.subblocks.map((subblock, index) => (
            <Grid.Column key={subblock.id}>
              <ViewBlock data={subblock} />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
