import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { Accordion, Grid, Segment, Button } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import {
  CheckboxWidget,
  Icon,
  TextWidget,
  SelectWidget,
  ObjectBrowserWidget,
} from '@plone/volto/components';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';

import {
  AlignWidget,
  LinkToWidget,
  ColorListWidget,
  ImageSidebarWidget,
} from '@package/components/';

const messages = defineMessages({
  Align: {
    id: 'Alignment',
    defaultMessage: 'Allineamento',
  },
  LinkSettings: {
    id: 'Link settings',
    defaultMessage: 'Impostazioni Link',
  },
  LinkToTitle: {
    id: 'Link title',
    defaultMessage: 'Titolo link',
  },
  showArrowOnLink: {
    id: 'Show arrow on button',
    defaultMessage: 'Mostra la freccia sul bottone',
  },
  Settings: {
    id: 'Settings',
    defaultMessage: 'Impostazioni',
  },
  NoImageSelected: {
    id: 'No image selected',
    defaultMessage: 'Nessuna immagine selezionata',
  },
  externalURL: {
    id: 'External URL',
    defaultMessage: 'URL esterno',
  },
  size: {
    id: 'Size',
    defaultMessage: 'Dimensione',
  },
  sizeBig: {
    id: 'Size Big',
    defaultMessage: 'Grande',
  },
  sizeMedium: {
    id: 'Size Medium',
    defaultMessage: 'Media',
  },
  sizeSmall: {
    id: 'Size Small',
    defaultMessage: 'Piccola',
  },
  Color: {
    id: 'Color',
    defaultMessage: 'Colore',
  },
  red: {
    id: 'Red',
    defaultMessage: 'Rosso',
  },
  brown: {
    id: 'Brown',
    defaultMessage: 'Marrone',
  },
  blue: {
    id: 'Blue',
    defaultMessage: 'Blu',
  },
  transparent: {
    id: 'Transparent',
    defaultMessage: 'Trasparente',
  }
});

const Sidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
}) => {
  const [activeAccIndex, setActiveAccIndex] = useState(0);

  function handleAccClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeAccIndex === index ? -1 : index;

    setActiveAccIndex(newIndex);
  }

  const linkColors = ['blue', 'yellow', 'transparent'];

  useEffect(() => {
    if (!data.bgType) {
      onChangeBlock(block, {
        ...data,
        bgType: 'image',
      });
    }
  }, [data, block, onChangeBlock]);
  return (
    <Segment.Group raised>
      <Segment className="form sidebar-listing-data">
        <SelectWidget
          id="bgType"
          title="Tipo di sfondo"
          required={true}
          value={data.bgType || 'image'}
          onChange={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          choices={[
            ['image', 'Immagine'],
            ['video', 'Video'],
          ]}
          noValueOption={false}
        />
      </Segment>

      {data.bgType === 'video' ? (
        <Segment className="form sidebar-listing-data">
          <ObjectBrowserWidget
            id="video"
            title="Video"
            description=""
            required={false}
            widgetOptions={{
              pattern_options: { selectableTypes: ['File'] },
            }}
            value={data.video ?? []}
            onChange={(id, value) =>
              onChangeBlock(block, { ...data, [id]: value })
            }
          />
          <ObjectBrowserWidget
            id="video_poster"
            title="Immagine di anteprima"
            description="Carica un'immagine che verrà mostrata finchè il video non sarà caricato in pagina"
            required={false}
            widgetOptions={{
              pattern_options: { selectableTypes: ['Image'] },
            }}
            value={data.video_poster ?? []}
            onChange={(id, value) => {
              onChangeBlock(block, { ...data, [id]: value });
            }}
          />
        </Segment>
      ) : (
        <ImageSidebarWidget
          data={data}
          onChange={(data) => onChangeBlock(block, data)}
          openObjectBrowser={openObjectBrowser}
        />
      )}

      {(data.url || data.video || data.video_poster) && (
        <>
          <Accordion fluid styled className="form">
            <Accordion.Title
              active={activeAccIndex === 0}
              index={0}
              onClick={handleAccClick}
            >
              {intl.formatMessage(messages.Settings)}
              {activeAccIndex === 0 ? (
                <Icon name={upSVG} size="20px" />
              ) : (
                <Icon name={downSVG} size="20px" />
              )}
            </Accordion.Title>
            <Accordion.Content active={activeAccIndex === 0}>
              <Form.Field inline required={required}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="4">
                      <div className="wrapper">
                        <label htmlFor="field-align">
                          {intl.formatMessage(messages.size)}
                        </label>
                      </div>
                    </Grid.Column>
                    <Grid.Column width="8" className="align-tools size-tools">
                      <div>
                        <Button.Group>
                          <Button
                            basic
                            icon
                            onClick={(name, value) => {
                              onChangeBlock(block, {
                                ...data,
                                size: 'big',
                              });
                            }}
                            active={data.size === 'big'}
                            content={intl.formatMessage(messages.sizeBig)}
                          />

                          <Button
                            basic
                            icon
                            onClick={(name, value) => {
                              onChangeBlock(block, {
                                ...data,
                                size: 'medium',
                              });
                            }}
                            active={data.size === 'medium'}
                            content={intl.formatMessage(messages.sizeMedium)}
                          />

                          <Button
                            basic
                            icon
                            aria-label="Small"
                            onClick={(name, value) => {
                              onChangeBlock(block, {
                                ...data,
                                size: 'small',
                              });
                            }}
                            active={data.size === 'small'}
                            content={intl.formatMessage(messages.sizeSmall)}
                          />
                        </Button.Group>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Accordion.Content>
            <Accordion.Title
              active={activeAccIndex === 1}
              index={1}
              onClick={handleAccClick}
            >
              {intl.formatMessage(messages.LinkSettings)}
              {activeAccIndex === 1 ? (
                <Icon name={upSVG} size="20px" />
              ) : (
                <Icon name={downSVG} size="20px" />
              )}
            </Accordion.Title>
            <Accordion.Content active={activeAccIndex === 1}>
              <TextWidget
                id="linkText"
                title={intl.formatMessage(messages.LinkToTitle)}
                required={false}
                value={data.linkTitle}
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    linkTitle: value,
                  });
                }}
              />
              <LinkToWidget
                data={data}
                openObjectBrowser={openObjectBrowser}
                onChange={(name, value) =>
                  onChangeBlock(block, {
                    ...data,
                    [name]: value,
                  })
                }
              />

              <ColorListWidget
                id="buttonLinkColor"
                title={intl.formatMessage(messages.Color)}
                required={false}
                value={data.buttonLinkColor}
                colors={linkColors}
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    buttonLinkColor: value,
                  });
                }}
              />

              <AlignWidget
                align={data.buttonLinkAlign}
                onChange={(align) => {
                  onChangeBlock(block, {
                    ...data,
                    buttonLinkAlign: align,
                  });
                }}
                alignments={['left', 'center']}
                label={intl.formatMessage(messages.Align)}
              />

              <CheckboxWidget
                id="showArrow"
                title={intl.formatMessage(messages.showArrowOnLink)}
                value={data.showArrowOnLink ? data.showArrowOnLink : false}
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    showArrowOnLink: value,
                  });
                }}
              />
            </Accordion.Content>
          </Accordion>
        </>
      )}
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
