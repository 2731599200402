import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { LinkMore } from '@package/components';

const StoriesTemplate = (data) => {
  const { items, isEditMode } = data;

  return items.length > 0 ? (
    <div className="stories-template">
      {items.map((item) => {
        return (
          <div className="story" key={item['@id']}>
            <div className="image">
              <ConditionalLink
                to={flattenToAppURL(item['@id'])}
                condition={!isEditMode}
                aria-hidden="true"
                role="presentation"
                tabIndex="-1"
              >
                <figure>
                  {item.quote_author_image && (
                    <img
                      src={item.quote_author_image.scales.preview.download}
                      alt={item.quote_author}
                      loading="lazy"
                    />
                  )}
                </figure>
              </ConditionalLink>
            </div>
            <div className="quote">
              <ConditionalLink
                to={flattenToAppURL(item['@id'])}
                condition={!isEditMode}
              >
                {item.quote}
                {(item.quote_author || item.quote_author_description) && (
                  <div className="author">
                    {item.quote_author && (
                      <div className="author-name">{item.quote_author}</div>
                    )}
                    {item.quote_author_description && (
                      <div className="author-description">
                        {item.quote_author_description}
                      </div>
                    )}
                  </div>
                )}
              </ConditionalLink>
            </div>
          </div>
        );
      })}

      <LinkMore {...data} />
    </div>
  ) : null;
};

StoriesTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default StoriesTemplate;
