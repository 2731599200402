import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import { Helmet, flattenHTMLToAppURL } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';

import {
  TypeLabel,
  ViewTitle,
  QuickInfo,
  Ticket,
  RelatedItems,
  BookingExperience,
} from '@package/components';

import DayExpanded from './DayExpanded';
import DayCollapsed from './DayCollapsed';

import ViewCTABox from '@package/components/Blocks/CtaBox/View';
import WeekendEvents from '../../WeekendEvents/WeekendEvents';
import TouristInfoBox from './TouristInfoBox';

const ItinerarioView = ({ content, location }) => {
  const weekendEvents = <WeekendEvents />;
  return (
    <Container className="itinerario-view">
      <Helmet title={content.title} />
      <article id="content">
        <Grid stackable columns={2} relaxed="very">
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <header>
              <TypeLabel type={content['@type']} />
              <ViewTitle
                title={content.title}
                description={content.description}
                url={location?.pathname}
                content={content}
              />
            </header>

            <QuickInfo obj={content} />
            <section id="content-core">
              {content.text && (
                <div
                  className="p-no-margin"
                  dangerouslySetInnerHTML={{
                    __html: flattenHTMLToAppURL(content.text.data),
                  }}
                />
              )}
            </section>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={4}>
            <aside className="right-column">
              <div className="mobile hidden tablet hidden">{weekendEvents}</div>
              <Ticket content={content} />
              <BookingExperience content={content} />
            </aside>
          </Grid.Column>
        </Grid>

        {content.items?.length === 1 && <DayExpanded day={content.items[0]} />}
        {content.items?.length > 1 &&
          content.items.map((day, index) => (
            <DayCollapsed day={day} key={`day${index}`} />
          ))}

        <section className="booking-infos">
          <Grid verticalAlign="top">
            {content.call_to_action && (
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <ViewCTABox data={content.call_to_action} />
              </Grid.Column>
            )}
            <Grid.Column mobile={16} tablet={4} computer={4}>
              <TouristInfoBox
                how_to_reach_link={content.how_to_reach_link}
                where_to_eat_link={content.where_to_eat_link}
                where_to_sleep_link={content.where_to_sleep_link}
              />

              {content.brochure?.download && (
                <div className="cta-ticket">
                  <div className="cta-ticket-link">
                    <a
                      href={content.brochure.download}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FormattedMessage
                        id="Download brochure"
                        defaultMessage="Scarica la brochure!"
                      />
                    </a>
                  </div>
                </div>
              )}
            </Grid.Column>
          </Grid>
        </section>

        {content.related_experiences?.length > 0 && (
          <FormattedMessage
            id="relatedExperiencesItinerario"
            defaultMessage="Prenota le esperienze di questo itinerario"
          >
            {(message) => (
              <RelatedItems
                items={content.related_experiences}
                title={message}
              />
            )}
          </FormattedMessage>
        )}

        {content.relatedItems?.length > 0 && (
          <FormattedMessage
            id="scopriDiPiu_itinerari"
            defaultMessage="Scopri di più"
          >
            {(message) => (
              <RelatedItems items={content.relatedItems} title={message} />
            )}
          </FormattedMessage>
        )}

        <div className="mobile only tablet only">{weekendEvents}</div>
      </article>
    </Container>
  );
};
ItinerarioView.propTypes = {
  content: PropTypes.shape({
    '@type': PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    text_body: PropTypes.shape({
      data: PropTypes.string,
    }),
    duration: PropTypes.string,
    needs_booking: PropTypes.bool,
    target_audience: PropTypes.shape({
      title: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        image_caption: PropTypes.string,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default ItinerarioView;
