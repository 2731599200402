/**
 * Edit image block.
 * @module components/manage/Blocks/Title/Edit
 */

import React from 'react';
import { Map } from 'immutable';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';

import { Grid, Container } from 'semantic-ui-react';
import { TextEditorWidget } from '@package/components';

import {
  withDNDContext,
  SubblocksEdit,
  SubblocksWrapper,
} from 'volto-subblocks';

import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebar';
import EditBlock from './Block/EditBlock';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Descrizione',
  },
});

const blockRenderMap = Map({
  unstyled: {
    element: 'h2',
  },
});

/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
class Edit extends SubblocksEdit {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <SubblocksWrapper
        node={this.node}
        className={`subblocks full-width ${
          this.props.data.backgroundColor ? this.props.data.backgroundColor : ''
        }`}
      >
        <Container>
          <div className="titleVM center">
            <TextEditorWidget
              data={this.props.data}
              fieldName="title"
              selected={true}
              block={this.props.block}
              onChangeBlock={(data) =>
                this.props.onChangeBlock(this.props.block, data)
              }
              placeholder={this.props.intl.formatMessage(messages.title)}
              showToolbar={false}
              blockRenderMap={blockRenderMap}
            />
          </div>

          <TextEditorWidget
            data={this.props.data}
            fieldName="description"
            selected={false}
            block={this.props.block}
            onChangeBlock={(data) =>
              this.props.onChangeBlock(this.props.block, data)
            }
            placeholder={this.props.intl.formatMessage(messages.description)}
            showToolbar={true}
          />

          <Grid stackable columns="equal" verticalAlign="top">
            <Grid.Row columns={3}>
              {this.state.subblocks.map((subblock, subindex) => (
                <Grid.Column key={subblock.id}>
                  <EditBlock
                    data={subblock}
                    index={subindex}
                    selected={this.isSubblockSelected(subindex)}
                    {...this.subblockProps}
                    openObjectBrowser={this.props.openObjectBrowser}
                    pathname={this.props.pathname}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
            {this.props.selected && (
              <Grid.Row>
                <Grid.Column textAlign="center">
                  {this.renderAddBlockButton()}
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Container>
        <SidebarPortal selected={this.props.selected}>
          <Sidebar
            {...this.props}
            data={this.props.data}
            onChangeSubblock={this.onChangeSubblocks}
            onChangeBlock={this.props.onChangeBlock}
            selected={this.state.subIndexSelected}
            setSelected={this.onSubblockChangeFocus}
          />
        </SidebarPortal>
      </SubblocksWrapper>
    );
  }
}

export default compose(injectIntl)(React.memo(withDNDContext(Edit)));
