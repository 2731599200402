import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from 'semantic-ui-react';
import { ItemTemplates, DefaultItem, LinkMore } from '@package/components';

const InEvidenzaTemplate = (data) => {
  const { items, isEditMode } = data;

  const FirstItem = items[0]
    ? ItemTemplates[items[0]['@type']] || DefaultItem
    : null;
  const SecondItem = items[1]
    ? ItemTemplates[items[1]['@type']] || DefaultItem
    : null;
  const ThirdItem = items[2]
    ? ItemTemplates[items[2]['@type']] || DefaultItem
    : null;

  const otherItems = items.length > 3 ? items.slice(3) : [];

  const align =
    otherItems.filter(
      (i) => i['@type'] === 'Esperienza' || i['@type'] === 'SchedaTematica',
    ).length === otherItems.length
      ? 'bottom'
      : 'top';

  return items.length > 0 ? (
    <div className="inevidenza-template">
      <Grid stackable verticalAlign="top">
        <Grid.Row stretched>
          <Grid.Column width={7} className="primary-items">
            {FirstItem && (
              <FirstItem
                item={items[0]}
                isEditMode={isEditMode}
                withTags={true}
                infoInTitle={true}
                showDescription={false}
              />
            )}
          </Grid.Column>
          <Grid.Column width={5} className="secondary-items">
            {SecondItem && (
              <SecondItem
                item={items[1]}
                isEditMode={isEditMode}
                withTags={true}
                infoInTitle={true}
                showDescription={false}
              />
            )}

            {ThirdItem && (
              <ThirdItem
                item={items[2]}
                isEditMode={isEditMode}
                withTags={true}
                infoInTitle={true}
                showDescription={false}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid columns={4} stackable verticalAlign={align} className="other-items">
        {otherItems.map((item) => {
          let ItemTemplate = ItemTemplates[item['@type']] || DefaultItem;

          return (
            <Grid.Column key={item['@id']}>
              <ItemTemplate
                item={item}
                isEditMode={isEditMode}
                withTags={true}
                infoInTitle={true}
                showDescription={false}
              />
            </Grid.Column>
          );
        })}
      </Grid>

      <LinkMore {...data} />
    </div>
  ) : null;
};

InEvidenzaTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default InEvidenzaTemplate;
