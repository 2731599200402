/**
 * Edit image block.
 * @module components/manage/Blocks/ImageBlocks/Block/Edit
 */

import React from 'react';
import { compose } from 'redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { blockTextRenderMap } from './settings';

import {
  ImageDropzoneWidget,
  TextEditorWidget,
  ImageFromUrl,
} from '@package/components';
import { DNDSubblocks, SubblockEdit, Subblock } from 'volto-subblocks';

const messages = defineMessages({
  titlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Title...',
  },
  descriptionPlaceholder: {
    id: 'Description placeholder',
    defaultMessage: 'Description...',
  },
});

/**
 * Edit text block class.
 * @class Edit
 * @extends Component
 */
class EditBlock extends SubblockEdit {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      focusOn: 'title',
    };
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <Subblock subblock={this}>
        <ImageDropzoneWidget
          {...this.props}
          onChangeBlock={(name, value) => {
            this.onChange({ ...this.props.data, [name]: value });
          }}
        >
          <div className="header">
            <ImageFromUrl
              url={this.props.data.url}
              className="bg"
              size="preview"
            />
            <div className="overlay-text">
              <Container>
                <TextEditorWidget
                  data={this.props.data}
                  fieldName="title"
                  selected={
                    this.props.selected || this.state.focusOn === 'title'
                  }
                  block={this.props.block}
                  blockRenderMap={blockTextRenderMap}
                  onChangeBlock={this.onChange}
                  placeholder={this.props.intl.formatMessage(
                    messages.titlePlaceholder,
                  )}
                  focusOn={this.focusOn}
                  nextFocus="description"
                />
              </Container>
            </div>
          </div>
          <div className="description">
            <TextEditorWidget
              data={this.props.data}
              fieldName="description"
              selected={this.state.focusOn === 'description'}
              block={this.props.block}
              blockRenderMap={blockTextRenderMap}
              onChangeBlock={this.onChange}
              placeholder={this.props.intl.formatMessage(
                messages.descriptionPlaceholder,
              )}
            />
          </div>
        </ImageDropzoneWidget>
      </Subblock>
    );
  }
}

export default React.memo(compose(injectIntl, ...DNDSubblocks)(EditBlock));
