/**
 * ViewBlock.
 * @module components/manage/CtaBox/Icon/ViewIcon
 */

import React from 'react';
import PropTypes from 'prop-types';
import CTAIcons from './icons';
import { Icon } from '@plone/volto/components';

/**
 * ViewIcon class.
 * @class ViewIcon
 * @extends Component
 */
const ViewIcon = ({ data }) => {
  return (
    <>
      {data.name && (
        <div className="cta-icon">
          <Icon name={CTAIcons[data.name]} />
        </div>
      )}
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ViewIcon.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewIcon;
