import React from 'react';

import { PreviewImage } from '@plone/volto/components';
import config from '@plone/volto/registry';
/**
 * Renders a preview image for a catalog brain result item.
 *
 */
const ListingImage = ({ item, ...rest }) => {
  return (
    <figure>
      {item[config.settings.listingPreviewImageField] ? (
        <img
          src={
            item[config.settings.listingPreviewImageField].scales.preview
              .download
          }
          alt={item.title}
          loading="lazy"
          {...rest}
        />
      ) : (
        <PreviewImage
          {...rest}
          item={{ ...item, image_field: 'image' }}
          alt={item.title}
          loading="lazy"
        />
      )}
    </figure>
  );
};

export default ListingImage;
