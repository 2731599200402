import React from 'react';
import PropTypes from 'prop-types';

import { flattenToAppURL } from '@plone/volto/helpers';
import { ConditionalLink } from '@plone/volto/components';
import { PriceRangeIndications, ListingImage } from '@package/components';
import TagsView from './TagsView';
import config from '@plone/volto/registry';

const ExperienceItem = ({ item = [], withTags = true, isEditMode }) => {
  const url = item['@id'].includes(config.settings.apiPath)
    ? flattenToAppURL(item['@id'])
    : item['@id'];

  return (
    <div className="item experience-item">
      <div className="item-title">
        <ConditionalLink
          to={flattenToAppURL(item['@id'])}
          title={item.title}
          condition={!isEditMode}
        >
          <h3>{item.title}</h3>
        </ConditionalLink>
      </div>

      <div className="item-border" />

      <div className="item-content">
        <ConditionalLink
          to={url}
          title={item.title}
          condition={!isEditMode}
          aria-hidden="true"
          role="presentation"
          tabIndex="-1"
        >
          <ListingImage item={item} />

          {withTags && item.thematic_area && (
            <TagsView subjects={[item.thematic_area.title]} />
          )}
          <PriceRangeIndications item={item} />
        </ConditionalLink>
      </div>
    </div>
  );
};

ExperienceItem.propTypes = {
  item: PropTypes.object,
  withTags: PropTypes.bool,
};

export default ExperienceItem;
