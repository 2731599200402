import React from 'react';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ConditionalLink } from '@plone/volto/components';

import { EventWhen } from '@package/components';
import TagsView from './TagsView';
import { PriceRangeIndications, ListingImage } from '@package/components';

const EventItem = ({
  item,
  isEditMode,
  infoInTitle = false,
  showDates = true,
  showTags = true,
  showPrice = true,

}) => {
  const infos = showDates ? (
    <div className="item-info">
      <EventWhen
        start={item.start}
        end={item.end}
        whole_day={item.whole_day}
        open_end={item.open_end}
        show_times={false}
      />
    </div>
  ) : (
    <></>
    // <div className="item-info item-description">
    //   {item.description}
    // </div>
  );

  return (
    <div className="item event-item">
      <div className="item-content">
        <ConditionalLink
          to={flattenToAppURL(item['@id'])}
          condition={!isEditMode}
          aria-hidden="true"
          role="presentation"
          tabIndex="-1"
        >
          {showTags && item.thematic_area && (
            <TagsView subjects={[item.thematic_area.title]} />
          )}
          {!infoInTitle && infos}

          <ListingImage item={item} />
          {showPrice && (
            <PriceRangeIndications item={item} />
          )}

        </ConditionalLink>
      </div>

      <div className="item-title">
        <ConditionalLink
          to={flattenToAppURL(item['@id'])}
          condition={!isEditMode}
        >
          <h3>{item.title ? item.title : item.id}</h3>
        </ConditionalLink>
        {infoInTitle && infos}
      </div>
    </div>
  );
};

EventItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default EventItem;
