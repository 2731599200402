import React from 'react';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ConditionalLink } from '@plone/volto/components';

import { ListingImage } from '@package/components';
import TagsView from './TagsView';

const ItinerarioItem = ({ item, isEditMode, infoInTitle = false }) => {
  return (
    <div className="item itinerario-item">
      <div className="item-content">
        <ConditionalLink
          to={flattenToAppURL(item['@id'])}
          condition={!isEditMode}
          aria-hidden="true"
          role="presentation"
          tabIndex="-1"
        >
          {item.thematic_area && (
            <TagsView subjects={[item.thematic_area.title]} />
          )}

          <ListingImage item={item} />
        </ConditionalLink>
      </div>

      <div className="item-title">
        <ConditionalLink
          to={flattenToAppURL(item['@id'])}
          condition={!isEditMode}
        >
          <h3>{item.title ? item.title : item.id}</h3>
          {infoInTitle && item.description && (
            <div className="item-description">{item.description}</div>
          )}
        </ConditionalLink>
      </div>
      {!infoInTitle && item.description && (
        <div className="item-description">{item.description}</div>
      )}
    </div>
  );
};

ItinerarioItem.propTypes = {
  item: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
};

export default ItinerarioItem;
