/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Login container.
 * @module components/theme/Sitemap/Sitemap
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';

import { getNavigation } from '@plone/volto/actions';
import { getSitemapSettings } from '@package/actions';

import config from '@plone/volto/registry';

const messages = defineMessages({
  Sitemap: {
    id: 'Sitemap',
    defaultMessage: 'Sitemap',
  },
});

const Sitemap = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigationItems = useSelector((state) => state.navigation.items);
  const sitemapSettings = useSelector(
    (state) => state.sitemapSettings.settings,
  );

  useEffect(() => {
    dispatch(getSitemapSettings());
  }, []);

  useEffect(() => {
    if (sitemapSettings?.depth) {
      dispatch(
        getNavigation(
          config.settings.isMultilingual ? `/${intl.locale}` : '/',
          sitemapSettings.depth,
        ),
      );
    }
  }, [sitemapSettings?.depth]);

  const renderItems = (items) => (
    <ul>
      {items.map((item) => (
        <li
          key={item.url}
          className={item.items?.length > 0 ? 'with-children' : ''}
        >
          <Link to={item.url}>{item.title}</Link>
          {item.items && renderItems(item.items)}
        </li>
      ))}
    </ul>
  );

  return (
    <div id="page-sitemap">
      <Helmet title={intl.formatMessage(messages.Sitemap)} />
      <Container className="view-wrapper">
        <h1>{intl.formatMessage(messages.Sitemap)} </h1>
        {navigationItems?.length > 0 && renderItems(navigationItems)}
      </Container>
    </div>
  );
};

export default Sitemap;
