import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';
import DayExpanded from '../ItinerarioView/DayExpanded';

const GiornataItinerarioView = ({ content, location }) => {
  return (
    <Container className="itinerario-view">
      <Helmet title={content.title} />

      <article id="content">
        <DayExpanded day={content} showTitle={false} />
      </article>
    </Container>
  );
};

GiornataItinerarioView.propTypes = {
  content: PropTypes.object.isRequired,
};

export default GiornataItinerarioView;
