import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import cx from 'classnames';
import { Icon } from '@plone/volto/components';
import { useWhishlist } from '@package/helpers';
import { Button } from 'semantic-ui-react';
import HeartEmpty from '@package/icons/heart-regular.svg';
import HeartFull from '@package/icons/heart-solid.svg';

const messages = defineMessages({
  add_to_whishlist: {
    id: 'add_to_whishlist',
    defaultMessage: 'Aggiungi ai preferiti',
  },
  remove_from_whishlist: {
    id: 'remove_from_whishlist',
    defaultMessage: 'Rimuovi dai preferiti',
  },
});

const WhishlistButton = ({ id }) => {
  const intl = useIntl();

  const { isInWhishlist, toggleWhishlist } = useWhishlist();
  const inWhishlist = isInWhishlist(id);

  return (
    <Button
      size="mini"
      icon
      title={
        inWhishlist
          ? intl.formatMessage(messages.remove_from_whishlist)
          : intl.formatMessage(messages.add_to_whishlist)
      }
      onClick={() => {
        toggleWhishlist(id);
      }}
      className={cx('add-to-whishlist', {
        'is-in-whishlist': inWhishlist,
      })}
    >
      <Icon name={inWhishlist ? HeartFull : HeartEmpty} className="icon" />
    </Button>
  );
};
export default WhishlistButton;
