/**
 * View icons block.
 * @module components/manage/Blocks/IconsBlocks/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import ViewBlock from './Block/ViewBlock';
import { Grid } from 'semantic-ui-react';
/**
 * View icons blocks class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  return (
    <div className="block iconsBlocks">
      <Grid columns="equal" verticalAlign="top">
        <Grid.Row>
          {data.subblocks.map((subblock, index) => (
            <Grid.Column key={subblock.id} mobile={12} tablet={6} computer={3}>
              <ViewBlock data={subblock} />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
