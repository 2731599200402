import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { PreviewImage } from '@plone/volto/components';
import { Container } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';

const LinksTemplate = (data) => {
  const { items, isEditMode } = data;


  const settings = {
    dots: true,
    infinite: false,
    autoplay: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    swipe: true,
    swipeToSlide: true,
    focusOnSelect: true,
    draggable: true,
    accessibility: true,
    adaptiveHeight: false,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const links = items.filter((item) => ['Link', 'Image'].includes(item['@type']));

  return links.length > 0 ? (
    <div className="full-width links-template">
      <Container>
        <Slider {...settings}>
          {links.map((item) => {
            return (<div key={item['@id']}>

              {item['@type'] == 'Link' &&
              <ConditionalLink
              to={item.remoteUrl}
              condition={!isEditMode}
              target="_blank"
              title={item.title}>
              {item.preview_image && (
                <PreviewImage
                  item={{ ...item, image_field: 'preview_image' }}
                  alt={item.title}
                  size="mini"
                  loading="lazy"
                />
              )}
            </ConditionalLink>
            }
            {item['@type'] == 'Image' &&
                <PreviewImage
                item={{ ...item, image_field: 'image' }}
                alt={item.title}
                size="mini"
                loading="lazy"
              />

            }

            </div>)
          })}
        </Slider>
      </Container>
    </div>
  ) : null;
};

LinksTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default LinksTemplate;
