import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { getContextNavigation, resetContextNavigation } from '@package/actions';
import { getBaseUrl, flattenToAppURL } from '@plone/volto/helpers';
import { List } from 'semantic-ui-react';
import cx from 'classnames';

const SideMenu = (props, intl) => {
  const root = props.menuRoot;

  const url = flattenToAppURL(getBaseUrl(root));
  const response = useSelector(
    (state) => state.contextNavigation.subrequests[url],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContextNavigation(url, 3, url));
    return () => dispatch(resetContextNavigation(url));
  }, [url, dispatch]);

  const renderItems = (items, level) => {
    return (
      <List key={new Date().getTime()}>
        {items.map((item) => (
          <List.Item
            key={item.url + level}
            className={cx(
              `level${level}`,
              props.path.indexOf(item.url) >= 0 ? 'open' : 'close',
            )}
          >
            <Link
              to={item.url}
              onClick={(e) => {
                if (item.items) {
                  e.preventDefault();
                  e.target.parentNode.classList.toggle('open');
                  e.target.parentNode.classList.toggle('close');
                }
              }}
            >
              {item.title}
            </Link>
            {item.items && renderItems(item.items, level + 1)}
          </List.Item>
        ))}
      </List>
    );
  };

  return response ? (
    <section id="side-menu">
      {response.items && renderItems(response.items, 0)}
    </section>
  ) : null;
};

export default injectIntl(SideMenu);
