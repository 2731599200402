/**
 * Footer component.
 * @module components/theme/Footer/Footer
 *
 * added logo link-image
 */

import React from 'react';
import { Container, List, Segment, Grid, Image, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { SocialLinks } from 'volto-social-settings';
import { useGoogleAnalytics } from 'volto-google-analytics';
import config from '@plone/volto/registry';
import { useSelector } from 'react-redux';

import LogoImage from '@package/components/layout/images/logo/Modenatur-LogoBianco.png';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
  comune_modena: {
    id: 'Comune di Modena',
    defaultMessage: 'Comune di Modena',
  },
  comune_unesco: {
    id: 'Unesco - Patrimonio Mondiale',
    defaultMessage: 'Unesco - Patrimonio Mondiale',
  },
  emiliaromagnaturismo: {
    id: 'Emilia Romagna turismo',
    defaultMessage: 'Emilia Romagna turismo',
  },
  subscribe_newsletter_label: {
    id: 'Subscribe Newsletter Label',
    defaultMessage: 'Iscriviti alla nostra newsletter',
  },
  subscribe_newsletter_submit: {
    id: 'Subscribe Newsletter Submit',
    defaultMessage: 'Invia',
  },
  download_app: {
    id: 'Download App',
    defaultMessage: 'Scarica la nostra app mobile!',
  },
  organize_your_event: {
    id: 'organize your event',
    defaultMessage: 'Organizza il tuo evento',
  },
  chi_siamo: {
    id: 'Chi siamo',
    defaultMessage: 'Chi siamo',
  },
  condizioni_generali: {
    id: 'Condizioni generali',
    defaultMessage: 'Condizioni generali',
  },
  contattaci: {
    id: 'Contattaci',
    defaultMessage: 'Contattaci',
  },
  privacy_policy: {
    id: 'Privacy policy',
    defaultMessage: 'Privacy policy',
  },
  cookie_policy: {
    id: 'Cookie policy',
    defaultMessage: 'Cookie policy',
  },
  contributi: {
    id: 'Contributi',
    defaultMessage: 'Contributi',
  },
  sitemap: {
    id: 'Mappa del sito',
    defaultMessage: 'Mappa del sito',
  },
  dichiarazione_accessibilita: {
    id: 'Dichiarazione di accessibilità',
    defaultMessage: 'Accessibilità',
  },
  not_found: {
    id:
      'We apologize for the inconvenience, but the page you were trying to access is not at this address. You can use the links below to help you find what you are looking for.',
    defaultMessage:
      "Ci scusiamo per l'inconveniente, la pagina cui stai provando ad accedere non esiste a questo indirizzo.",
  },
  covid_link: {
    id: 'covid_link',
    defaultMessage:
      'https://www.modenatur.it/it/pianifica-il-viaggio/covid-cosa-sapere',
  },
  covid_infos: {
    id: 'covid_infos',
    defaultMessage: 'Covid 19 - Infomazioni utili',
  },
  candidatura_unesco: {
    id: 'candidatura_unesco',
    defaultMessage: 'Candidatura Unesco - City of Media Arts',
  },
  logo_europark: {
    id: 'logo_europark',
    defaultMessage: 'Europark - Sustainable Tourism in Protected Areas',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */

const Footer = ({ intl, pathname }) => {
  const locale_links = {
    chi_siamo: {
      it: '/modenatur',
      en: '/modenatur'
    },
    condizioni_generali: {
      it: '/condizionigenerali',
      en: '/terms-and-conditions'
    },
    contattaci: {
      it: '/modenatur/contattaci',
      en: '/modenatur/contact-us',
    },
  };
  const lang = useSelector((state) => state.intl.locale);

  useGoogleAnalytics();

  return (
    <div id="footer" role="contentinfo" className="footer-wrapper">
      <Segment
        vertical
        padded
        inverted
        color="blue"
        textAlign="center"
        className="footer"
      >
        <Grid
          container
          stackable
          celled="internally"
          reversed="mobile tablet vertically"
        >
          {/* Logo */}
          <Grid.Column
            mobile={12}
            tablet={12}
            computer={4}
            className="tablet-border-bottom"
            style={{display:'flex', 'align-items': 'center'}}
          >
            <Grid celled="internally" className="loghi">
              <Grid.Row verticalAlign="middle" style={{display:'flex', 'align-items': 'center'}}>
                  <Image
                    src={LogoImage}
                    alt={intl.formatMessage(messages.comune_modena)}
                    title={intl.formatMessage(messages.comune_modena)}
                    loading="lazy"
                  />
              </Grid.Row>

            </Grid>
          </Grid.Column>

          {/* Links */}
          <Grid.Column
            mobile={12}
            tablet={12}
            computer={4}
            className="tablet-border-bottom"
            style={{display:'flex', 'align-items': 'center'}}
          >
            <Grid celled="internally">

              <Grid.Row>

                <Grid.Column width={6}>
                    {__CLIENT__ && (
                      <List horizontal className="footer-links" role={null}>

                        <List.Item
                          as={Link}
                          to={`/${intl.locale}${locale_links.chi_siamo[intl.locale]}`}
                          role={null}>
                          {intl.formatMessage(messages.chi_siamo)}
                        </List.Item>

                        <List.Item
                          as={Link}
                          to={`/${intl.locale}${locale_links.contattaci[intl.locale]}`}
                          role={null}>
                          {intl.formatMessage(messages.contattaci)}
                        </List.Item>

                        {intl.locale == 'it' &&
                        <List.Item
                          as={Link}
                          to='/it/blog'
                          role={null}>
                          Blog
                        </List.Item>
                        }

                        <List.Item
                          as={Link}
                          to={`/${intl.locale}/sitemap`}
                          role={null}>
                          {intl.formatMessage(messages.sitemap)}
                      </List.Item>

                      </List>
                    )}
                </Grid.Column>

                <Grid.Column width={6}>
                  {__CLIENT__ && (
                    <List horizontal className="footer-links footer-links--right" role={null}>

                      <List.Item
                        as={Link}
                        to={`/${intl.locale}${locale_links.condizioni_generali[intl.locale]}`}
                        role={null}>
                        {intl.formatMessage(messages.condizioni_generali)}
                      </List.Item>

                      <List.Item
                        as={Link}
                        to={`/${intl.locale}/privacypolicy`}
                        role={null}>
                        {intl.formatMessage(messages.privacy_policy)}
                      </List.Item>

                      <List.Item
                        as={Link}
                        to={`/${intl.locale}/cookiepolicy`}
                        role={null}>
                        {intl.formatMessage(messages.cookie_policy)}
                      </List.Item>

                      {intl.locale == 'it' &&
                      <List.Item
                        as={Link}
                        to={`/${intl.locale}/contributi`}
                        role={null}>
                        {intl.formatMessage(messages.contributi)}
                      </List.Item>
                      }

                    </List>
                    )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>

          {/* Copyright */}
          <Grid.Column
            mobile={12}
            tablet={12}
            computer={4}
            className="tablet-border-bottom"
            style={{display:'flex', 'align-items': 'center'}}
          >
            <Grid celled="internally" textAlign="left">
              <Grid.Row className="tablet-no-border">
                <Grid.Column className='footer-copyright'>
                  <div>
                    <span>Modenatur s.c.a.r.l.</span>
                    <span>Via Scudari 10, 41121, Modena Italy</span>
                  </div>
                  <div>
                    <span>Tel: <a href="tel://+39059220022">39 059220022</a></span>
                  </div>
                  <div>
                    <span>Email: <a href="mailto:info@modenatur.it">info@modenatur.it</a></span>
                    <span>PEC: <a href="mailto:modenatur@legalmail.it">modenatur@legalmail.it</a></span>
                  </div>
                  <div>
                    <span>P.I. e C.F 02374350367</span>
                    <span>REA: MO 285204</span>
                    <span>SDI CMBAR97</span>
                  </div>
                  <div>
                    <span>©2024</span>
                    <span>All rights reserved.</span>
                  </div>
                </Grid.Column>
              </Grid.Row>

            </Grid>
          </Grid.Column>
        </Grid>
      </Segment>

      <Segment vertical padded secondary textAlign="center" className="footer">
        <Container>
          <Grid columns={3} stackable>
            <Grid.Row>
              <Grid.Column
                textAlign="left"
                mobile={12}
                tablet={12}
                computer={4}
              >

              <Grid.Column>
              {__CLIENT__ && (
              <div className="label semibold footer-newsletter">
                <a href="https://modenatur.voxmail.it/" target="_blank">
                <Icon name="envelope outline" />
                  <span>{intl.formatMessage(messages.subscribe_newsletter_label)}</span>
                </a>
              </div>
              )}
              </Grid.Column>


              </Grid.Column>
              <Grid.Column
                textAlign="center"
                mobile={12}
                tablet={12}
                computer={4}
                className="tablet-border-bottom"
                verticalAlign="middle"
              >
                <SocialLinks
                  wrapperCssClass="ui horizontal list socials"
                  itemCssClass="item"
                />
              </Grid.Column>
              <Grid.Column
                verticalAlign="middle"
                mobile={12}
                tablet={12}
                computer={4}
                className="language"
                textAlign="right"
              >
                {intl.locale}
                {__CLIENT__ && (
                  <Image
                    src={require(`@package/components/Footer/Languages/${intl.locale}.svg`)}
                    alt={intl.locale}
                    title={intl.locale}
                    loading="lazy"
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
