import React, { useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  mainView: {
    id: 'skiplink-main-content',
    defaultMessage: 'Skip to main content',
  },
  navigation: {
    id: 'skiplink-navigation',
    defaultMessage: 'Skip to navigation',
  },
  footer: {
    id: 'skiplink-footer',
    defaultMessage: 'Skip to footer',
  },
  showGdprBanner: {
    id: 'skiplink-gdpr-banner',
    defaultMessage: 'Skip to cookie banner',
  },
  surveyBanner: {
    id: 'skiplink-survey-banner',
    defaultMessage: 'Skip to survey banner',
  },
});

const SkipLinks = () => {
  const intl = useIntl();

  useEffect(() => {
    //se all'apertura del sito, il banner dei cookie è aperto, sposto lì il focus.
    setTimeout(function () {
      if (document.querySelector('.gdpr-privacy-banner')) {
        document
          .querySelector('.close-button.gdpr-privacy-banner-button')
          .focus();
      }
    }, 100);
  }, []);

  return (
    <div
      className="skiplinks-wrapper"
      role="complementary"
      aria-label="skiplinks"
    >
      <a
        className="skiplink"
        href="#cookies"
        onClick={(e) => {
          e.preventDefault();
          if (document.querySelector('.gdpr-privacy-banner')) {
            document
              .querySelector('.close-button.gdpr-privacy-banner-button')
              .focus();
          } else {
            document.querySelector('.gdpr-privacy-show-banner').click();
            setTimeout(function () {
              document
                .querySelector('.close-button.gdpr-privacy-banner-button')
                .focus();
            }, 100);
          }
        }}
      >
        {intl.formatMessage(messages.showGdprBanner)}
      </a>
      <a className="skiplink" href="#view">
        {intl.formatMessage(messages.mainView)}
      </a>
      <a
        className="skiplink"
        href="#navigation"
        onClick={(e) => {
          e.preventDefault();

          document
            .querySelector(
              '.navigation.navigation-dropdownmenu button.dropdownmenu-item',
            )
            .focus();
        }}
      >
        {intl.formatMessage(messages.navigation)}
      </a>
      <a
        className="skiplink"
        href="#survey"
        onClick={() =>
          (document.querySelector('.survey-wrapper').style.display = 'flex')
        }
      >
        {intl.formatMessage(messages.surveyBanner)}
      </a>
      <a className="skiplink" href="#footer">
        {intl.formatMessage(messages.footer)}
      </a>
    </div>
  );
};

export default SkipLinks;
