import config from '@plone/volto/registry';

export const GET_EXPERIENCES_RESULTS = 'GET_EXPERIENCES_RESULTS';

export function getExperiencesResult(path, data, subrequest, page) {
  const { settings } = config;

  // fixes https://github.com/plone/volto/issues/1059
  let requestData = JSON.parse(JSON.stringify(data));

  if (data?.depth != null) {
    delete requestData.depth;
    requestData.query.forEach((q) => {
      if (q.i === 'path') {
        q.v += '::' + data.depth;
      }
    });
  }

  // fixes https://github.com/plone/volto/issues/2397
  if (requestData?.sort_order !== null) {
    if (typeof requestData.sort_order === 'boolean') {
      requestData.sort_order = requestData.sort_order
        ? 'descending'
        : 'ascending';
    }
  }

  return {
    type: GET_EXPERIENCES_RESULTS,
    subrequest,
    request: {
      op: 'post',
      path: `${path}/@experiences-search`,
      data: {
        ...requestData,
        ...(!requestData.b_size && {
          b_size: settings.defaultPageSize,
        }),
        ...(page && {
          b_start: requestData.b_size
            ? data.b_size * (page - 1)
            : settings.defaultPageSize * (page - 1),
        }),
        query: requestData?.query,
      },
    },
  };
}
