/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { load } from 'redux-localstorage-simple';
import {
  toggleWhishlist as toggleWhishlistAction,
  clearWhishlist as clearWhishlistAction,
  setWhishlist,
} from '@package/actions';

export const useWhishlist = () => {
  const dispatch = useDispatch();
  const whishlist = useSelector((state) => state.whishlist);

  const loadFromLocalStorage = () => {
    try {
      return load({ states: ['whishlist'] });
    } catch (e) {
      return [];
    }
  };

  const isInWhishlist = (url) => {
    return whishlist?.indexOf(url) >= 0;
  };

  const toggleWhishlist = (url) => {
    dispatch(toggleWhishlistAction(url));
  };

  const clearWhishlist = (url) => {
    dispatch(clearWhishlistAction());
  };

  useEffect(() => {
    if (!whishlist) {
      //inint
      dispatch(setWhishlist(loadFromLocalStorage()));
    }
  }, []);
  return { whishlist, isInWhishlist, toggleWhishlist, clearWhishlist };
};
