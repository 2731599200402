import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { flattenHTMLToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';

const ContactsInfos = ({ content, timetable_title, intl }) => {
  const getTextareaHTML = (text) => {
    if (text) {
      return text.split(/\r|\n/).join('<br/>');
    }
    return '';
  };

  return (
    <>
      {(content.phone ||
        content.email ||
        content.website ||
        content.timetable) && (
        <section className="info-box">
          {(content.phone || content.email || content.website) && (
            <>
              <div className="info-box-header">
                <FormattedMessage
                  id="Contacts data"
                  defaultMessage="Dati di contatto"
                />
              </div>
              <div className="info-box-text">
                {content.phone && (
                  <p className="info-box-icon-detail">
                    <Icon name="phone" />{' '}
                    <span className="detail">
                      <UniversalLink href={'tel:' + content.phone}>
                        {content.phone}
                      </UniversalLink>
                    </span>
                  </p>
                )}

                {content.email && (
                  <p className="info-box-icon-detail">
                    <Icon name="mail" />
                    <span className="detail">
                      <UniversalLink href={'mailto:' + content.email}>
                        {content.email}
                      </UniversalLink>
                    </span>
                  </p>
                )}

                {content.website && (
                  <p className="info-box-icon-detail">
                    <Icon name="world" />
                    <span className="detail">
                      <UniversalLink
                        href={content.website}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedMessage
                          id="Go to site"
                          defaultMessage="Vai al sito"
                        />
                        <Icon name="angle right" />
                      </UniversalLink>
                    </span>
                  </p>
                )}
              </div>
            </>
          )}
          {content.timetable && (
            <>
              <div className="info-box-header">
                {timetable_title || (
                  <FormattedMessage
                    id="Opening dates and times"
                    defaultMessage="Date e orari di apertura"
                  />
                )}
              </div>
              <div className="info-box-text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: flattenHTMLToAppURL(
                      getTextareaHTML(content.timetable),
                    ),
                  }}
                />
              </div>
            </>
          )}
        </section>
      )}
    </>
  );
};
export default injectIntl(ContactsInfos);
