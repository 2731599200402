/* eslint-disable react-hooks/exhaustive-deps */
/**
 * View icons block.
 * @module components/manage/Blocks/IconsBlocks/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import Body from './Body';
import { Portal } from 'react-portal';

/**
 * View icons blocks class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  const [isClient, setIsClient] = React.useState(null);
  const [leadimagePortal, setLeadimagePortal] = React.useState(null);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  React.useEffect(() => {
    if (isClient) {
      let portal = document.getElementById('leadimage-portal');
      setLeadimagePortal(portal);
      if (data.leadimage && portal) {
        document.getElementById('leadimage-portal').classList.add('with-slider');
      }
    }
  }, [isClient]);


  return data.leadimage && leadimagePortal ? (
    <Portal
      node={isClient && document.getElementById('leadimage-portal')}
      className="lead-slider"
    >
      <div className="block slider">
        <Body {...data} />
      </div>
    </Portal>
  ) : (
    <div className="block slider">
      <Body {...data} />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
