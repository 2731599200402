import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';
import config from '@plone/volto/registry';

import {
  flattenHTMLToAppURL,
  hasBlocksData,
  getBaseUrl,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';

const messages = defineMessages({
  unknownBlock: {
    id: 'unknownBlock',
    defaultMessage: 'Blocco sconosciuto',
  },
});

const TextOrBlocks = ({ content, location }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const intl = useIntl();

  return (
    <>
      {hasBlocksData(content)
        ? map(content[blocksLayoutFieldname].items, (block) => {
            const blockType = content[blocksFieldname]?.[block]?.['@type'];
            if (['title', 'pageDescription'].indexOf(blockType) > -1)
              return null;

            const Block =
              config.blocks.blocksConfig[blockType]?.['view'] ?? null;
            return Block !== null ? (
              <Block
                key={block}
                id={block}
                properties={content}
                data={content[blocksFieldname][block]}
                path={getBaseUrl(location?.pathname || '')}
              />
            ) : (
              <div key={block}>
                {intl.formatMessage(messages.unknownBlock, {
                  block: content[blocksFieldname]?.[block]?.['@type'],
                })}
              </div>
            );
          })
        : content.text?.data && (
            <div
              dangerouslySetInnerHTML={{
                __html: flattenHTMLToAppURL(content.text.data),
              }}
            />
          )}
    </>
  );
};

TextOrBlocks.propTypes = {
  content: PropTypes.object.isRequired,
};

export default TextOrBlocks;
