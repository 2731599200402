import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { flattenHTMLToAppURL } from '@plone/volto/helpers';
import { GoogleMap } from '@package/components';

const Location = ({ content, intl }) => {
  const getTextareaHTML = (text) => {
    if (text) {
      return text.split(/\r|\n/).join('<br/>');
    }
    return '';
  };

  const hasLocation =
    content.geolocation && Object.keys(content.geolocation).length > 0;

  return (
    <>
      {(content.where || hasLocation) && (
        <section className="info-box no-bg center">
          <div className="info-box-header">
            <FormattedMessage id="WHERE WE ARE" defaultMessage="DOVE SIAMO" />
          </div>
          {content.where && (
            <div className="info-box-text">
              <div
                dangerouslySetInnerHTML={{
                  __html: flattenHTMLToAppURL(getTextareaHTML(content.where)),
                }}
              />
            </div>
          )}
          {hasLocation && (
            <>
              <div className="map-description">
                {content.geolocation.description}
              </div>
              <div className="map">
                <GoogleMap
                  showTitle={false}
                  showPrice={false}
                  height="450px"
                  venues={[
                    {
                      lat: parseFloat(content.geolocation.lat),
                      lng: parseFloat(content.geolocation.lng),
                    },
                  ]}
                />
              </div>
            </>
          )}
        </section>
      )}
    </>
  );
};
export default injectIntl(Location);
