import BookOpen from './book-open-solid.svg';
import BriefCase from './briefcase-regular.svg';
import Car from './car-regular.svg';
import Clock from './clock-regular.svg';
import Envelope from './envelope-solid.svg';
import Euro from './euro-sign-solid.svg';
import Exclamation from './exclamation-solid.svg';
import Fax from './fax-solid.svg';
import Globe from './globe-solid.svg';
import MapMarkedAlt from './map-marked-alt-light.svg';
import MapMarkerAlt from './map-marker-alt-regular.svg';
import PaperPlane from './paper-plane-light.svg';
import Parking from './parking-solid.svg';
import Phone from './phone-solid.svg';
import TShirt from './tshirt-solid.svg';
import Utensils from './utensils-regular.svg';
import Info from './info.svg';
import WineGlass from './wine-glass.svg';
import RestRoom from './restroom-solid.svg';
import WCSign from './wc-sign.svg';

const IconList = {
  BookOpen,
  BriefCase,
  Car,
  Clock,
  Envelope,
  Euro,
  Exclamation,
  Fax,
  Globe,
  MapMarkedAlt,
  MapMarkerAlt,
  PaperPlane,
  Parking,
  Phone,
  TShirt,
  Utensils,
  Info,
  WineGlass,
  RestRoom,
  WCSign,
};
export default IconList;
