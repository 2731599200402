/**
 * Edit text block.
 * @module components/manage/Blocks/Title/Edit
 */

import React from 'react';
import { Grid } from 'semantic-ui-react';
import EditBlock from './Block/EditBlock';
import {
  withDNDContext,
  SubblocksEdit,
  SubblocksWrapper,
} from 'volto-subblocks';

/**
 * Edit text block class.
 * @class Edit
 * @extends Component
 */
class Edit extends SubblocksEdit {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <SubblocksWrapper node={this.node}>
        <Grid stackable columns="equal" verticalAlign="top">
          <Grid.Row
            columns={
              this.state.subblocks.length < 3 ? this.state.subblocks.length : 3
            }
          >
            {this.state.subblocks.map((subblock, subindex) => (
              <Grid.Column key={subblock.id}>
                <EditBlock
                  data={subblock}
                  index={subindex}
                  selected={this.isSubblockSelected(subindex)}
                  {...this.subblockProps}
                  openObjectBrowser={this.props.openObjectBrowser}
                />
              </Grid.Column>
            ))}

            {this.props.selected && (
              <Grid.Column>{this.renderAddBlockButton()}</Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </SubblocksWrapper>
    );
  }
}

export default React.memo(withDNDContext(Edit));
