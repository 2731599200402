import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { DangerouslySetHtmlContent } from '@package/components';

const messages = defineMessages({
  tiket_subscribe: {
    id: 'Sign up from the comfort of your home',
    defaultMessage: 'Iscriviti comodamente da casa',
  },
  ticket_book_now: {
    id: 'Book experience now',
    defaultMessage: "Acquista ora l'esperienza!",
  },
});

const BookingExperience = ({ content, intl }) => {
  if (
    !content.booking_experience_button_url &&
    !content.booking_experience_embed_html
  ) {
    return null;
  }
  const description =
    content.booking_experience_box_description ||
    intl.formatMessage(messages.tiket_subscribe);
  const url_label =
    content.booking_experience_button_label ??
    intl.formatMessage(messages.ticket_book_now);

  let link = (
    <a
      href={content.booking_experience_button_url}
      rel="noopener noreferrer"
      target="_blank"
    >
      {url_label}
    </a>
  );
  if (content.booking_experience_embed_html) {
    link = (
      <DangerouslySetHtmlContent
        html={content.booking_experience_embed_html}
        className="embed-html"
      />
      // <div
      //   className="embed-html"
      //   dangerouslySetInnerHTML={{
      //     __html: content.booking_experience_embed_html,
      //   }}
      // />
    );
  }

  return (
    <section className="cta-ticket">
      <div className="cta-ticket-text">{description}</div>
      <div className="cta-ticket-link">{link}</div>
    </section>
  );
};

BookingExperience.propTypes = {
  content: PropTypes.object.isRequired,
};

export default injectIntl(BookingExperience);
