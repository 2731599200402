import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { Grid, Image } from 'semantic-ui-react';

import { GoogleMap } from '@package/components';
import { flattenToAppURL, flattenHTMLToAppURL } from '@plone/volto/helpers';
import { srcset } from '@package/helpers';

const TappaExpanded = ({ tappa, number }) => {
  return (
    <div className="tappa">
      <h2>
        {number} <FormattedMessage id="tappa" defaultMessage="tappa" />:{' '}
        {tappa.title}
      </h2>
      <div className="tappa-content">
        {tappa.description && (
          <div className="description">{tappa.description}</div>
        )}
        {tappa.text && (
          <div className="text">
            <div
              className="p-no-margin"
              dangerouslySetInnerHTML={{
                __html: flattenHTMLToAppURL(tappa.text.data),
              }}
            />
          </div>
        )}

        <Grid stackable verticalAlign="top">
          <Grid.Column width={tappa.geolocation?.lat ? 8 : 12}>
            {/* images */}
            {(tappa.image1?.scales?.large?.download ||
              tappa.image2?.scales?.large?.download ||
              tappa.image3?.scales?.large?.download) && (
              <Grid className="tappa-images">
                {tappa.image1?.scales?.large?.download && (
                  <Grid.Column
                    width={
                      tappa.image2?.scales?.large?.download ||
                      tappa.image3?.scales?.large?.download
                        ? 8
                        : 12
                    }
                  >
                    <figure>
                      <Image
                        src={flattenToAppURL(
                          tappa.image1.scales.large.download,
                        )}
                        alt={tappa.title}
                        loading="lazy"
                        srcSet={srcset(tappa.image1, 'large')}
                      />
                    </figure>
                  </Grid.Column>
                )}
                {(tappa.image2?.scales?.large?.download ||
                  tappa.image3?.scales?.large?.download) && (
                  <Grid.Column
                    width={tappa.image1 ? 4 : 12}
                    className="right-column"
                  >
                    {tappa.image2?.scales?.large?.download && (
                      <figure>
                        <Image
                          src={flattenToAppURL(
                            tappa.image2.scales.large.download,
                          )}
                          alt={tappa.title}
                          loading="lazy"
                          srcSet={srcset(tappa.image2, 'large')}
                        />
                      </figure>
                    )}
                    {tappa.image3?.scales?.large?.download && (
                      <figure>
                        <Image
                          src={flattenToAppURL(
                            tappa.image3.scales.large.download,
                          )}
                          alt={tappa.title}
                          loading="lazy"
                          srcSet={srcset(tappa.image3, 'large')}
                        />
                      </figure>
                    )}
                  </Grid.Column>
                )}
              </Grid>
            )}
          </Grid.Column>

          {tappa.geolocation?.lat && (
            <Grid.Column width={4} className="map">
              <h3>
                <FormattedMessage id="Mappa" defaultMessage="Mappa" />
              </h3>
              <div className="map-wrapper">
                <GoogleMap
                  showTitle={false}
                  showPrice={false}
                  height="450px"
                  venues={[
                    {
                      lat: parseFloat(tappa.geolocation.lat),
                      lng: parseFloat(tappa.geolocation.lng),
                    },
                  ]}
                />
              </div>
            </Grid.Column>
          )}
        </Grid>
      </div>
    </div>
  );
};

TappaExpanded.propTypes = {
  path: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
};
export default TappaExpanded;
