/**
 * View image block.
 * @module components/Blocks/FullImage/View
 */

import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { ButtonLink, ImageFromUrl } from '@package/components/';
import { Icon, Grid } from 'semantic-ui-react';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = ({ data, detached }) => {
  return (
    <div
      className={cx('download-block', {
        detached,
      })}
    >
      <Grid stackable stretched verticalAlign="middle">
        <Grid.Row>
          {data.url && (
            <Grid.Column width={8} className="image">
              <ImageFromUrl
                url={data.url}
                title={data.title}
                className="bg"
                size="large"
              />
            </Grid.Column>
          )}
          <Grid.Column className="text" width={data.url ? 4 : 12}>
            <ButtonLink to={data.href} title={data.title} targetBlank={true}>
              <Icon name="download" />
            </ButtonLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
