/**
 * Logo component.
 * @module components/theme/Logo/Logo
 *
 * Added logo image
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { Image } from 'semantic-ui-react';

import LogoImage from '@package/components/layout/images/logo/Modenatur-LogoBlu.png';
import LogoImageAlternative from '@package/components/layout/images/logo/Modenatur-LogoBianco.png';

/**
 * Logo component class.
 * @function Logo
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component.
 */
const Logo = ({ alternative=false }) => {
  const lang = useSelector((state) => state.intl.locale);

  return (
    <Link to={config.settings.isMultilingual ? `/${lang}` : '/'}>
      {alternative
        ? <Image key="logo-alt" src={LogoImageAlternative} alt="ModenaTur" title="ModenaTur" />
        : <Image key="logo" src={LogoImage} alt="ModenaTur" title="ModenaTur" />
      }
    </Link>
  );
};

export default Logo;
