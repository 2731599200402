import React from 'react';
import PropTypes from 'prop-types';

import { Image, Grid } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { srcset } from '@package/helpers';

const TappaCollapsed = ({ tappa }) => (
  <div className="tappa">
    <h3>{tappa.title}</h3>
    <div className="tappa-content">
      {tappa.description && (
        <div className="description">{tappa.description}</div>
      )}
      {tappa.text && (
        <div className="text">
          <div
            className="p-no-margin"
            dangerouslySetInnerHTML={{
              __html: tappa.text.data,
            }}
          />
        </div>
      )}

      {/* images */}
      {(tappa.image1?.scales?.large?.download ||
        tappa.image2?.scales?.large?.download ||
        tappa.image3?.scales?.large?.download) && (
        <Grid className="tappa-images">
          {tappa.image1?.scales?.large?.download && (
            <Grid.Column width={tappa.image2 || tappa.image3 ? 8 : 12}>
              <figure>
                <Image
                  src={flattenToAppURL(tappa.image1.scales.large.download)}
                  alt={tappa.title}
                  loading="lazy"
                  srcSet={srcset(tappa.image1, 'large')}
                />
              </figure>
            </Grid.Column>
          )}
          {(tappa.image2?.scales?.large?.download ||
            tappa.image3?.scales?.large?.download) && (
            <Grid.Column width={tappa.image1 ? 4 : 12} className="right-column">
              {tappa.image2?.scales?.large?.download && (
                <figure>
                  <Image
                    src={flattenToAppURL(tappa.image2.scales.large.download)}
                    alt={tappa.title}
                    loading="lazy"
                    srcset={srcset(tappa.image2, 'large')}
                  />
                </figure>
              )}
              {tappa.image3?.scales?.large?.download && (
                <figure>
                  <Image
                    src={flattenToAppURL(tappa.image3.scales.large.download)}
                    alt={tappa.title}
                    loading="lazy"
                    srcset={srcset(tappa.image3, 'large')}
                  />
                </figure>
              )}
            </Grid.Column>
          )}
        </Grid>
      )}
    </div>
  </div>
);

TappaCollapsed.propTypes = {
  path: PropTypes.string.isRequired,
};
export default TappaCollapsed;
