/**
 * Edit text block.
 * @module components/manage/Blocks/Title/Edit
 */

import React from 'react';
import { compose } from 'redux';

import { injectIntl, defineMessages } from 'react-intl';
import { TextEditorWidget } from '@package/components';
import { SubblockEdit, Subblock } from 'volto-subblocks';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
});
/**
 * Edit text block class.
 * @class Edit
 * @extends Component
 */
class EditBlock extends SubblockEdit {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs EditBlock
   */
  constructor(props) {
    super(props);
    this.state = {
      focusOn: 'title',
    };
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <Subblock subblock={this} draggable={false}>
        <TextEditorWidget
          data={this.props.data}
          fieldName="title"
          selected={this.state.focusOn === 'description'}
          block={this.props.block}
          onChangeBlock={this.onChange}
          placeholder={this.props.intl.formatMessage(messages.title)}
          showToolbar={false}
        />
      </Subblock>
    );
  }
}

export default React.memo(compose(injectIntl)(EditBlock));
