/**
 * Edit NewsletterBox block.
 * @module components/Blocks/NewsletterBox/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Map } from 'immutable';
import { defineMessages, injectIntl } from 'react-intl';

import { SidebarPortal } from '@plone/volto/components';
import { createContent } from '@plone/volto/actions';

import { Box, TextEditorWidget } from '@package/components';
import NewsletterForm from './NewsletterForm';
import Sidebar from './Sidebar';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Descrizione',
  },
});

const blockTitleRenderMap = Map({
  unstyled: {
    element: 'div',
  },
});

const blockDescriptionRenderMap = Map({
  unstyled: {
    element: 'div',
  },
});

/**
 * Edit cta box class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeBlock: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {};
    if (!__SERVER__) {
      this.state = {
        currentFocused: 'title',
      };
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <>
        <Box className="newsletterBox">
          <div className="title">
            <TextEditorWidget
              data={this.props.data}
              fieldName="title"
              selected={this.state.currentFocused === 'title'}
              block={this.props.block}
              onChangeBlock={(data) =>
                this.props.onChangeBlock(this.props.block, data)
              }
              placeholder={this.props.intl.formatMessage(messages.title)}
              showToolbar={false}
              blockRenderMap={blockTitleRenderMap}
              blockStyleFn={() => 'text-editor'}
              nextFocus={() => {
                this.setState({
                  currentFocused: 'description',
                });
              }}
              enablePlugins={false}
              textPlain={true}
            />
          </div>
          <div className="description">
            <TextEditorWidget
              data={this.props.data}
              fieldName="description"
              selected={this.state.currentFocused === 'description'}
              block={this.props.block}
              onChangeBlock={(data) =>
                this.props.onChangeBlock(this.props.block, data)
              }
              placeholder={this.props.intl.formatMessage(messages.description)}
              showToolbar={false}
              blockRenderMap={blockDescriptionRenderMap}
              blockStyleFn={() => 'text-editor'}
              prevFocus={() => {
                this.setState({
                  currentFocused: 'title',
                });
              }}
              enablePlugins={false}
              textPlain={true}
            />
          </div>
          <NewsletterForm {...this.props} />
        </Box>
        <SidebarPortal selected={this.props.selected}>
          <Sidebar {...this.props} />
        </SidebarPortal>
      </>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      request: state.content.create,
      content: state.content.data,
    }),
    { createContent },
  ),
)(Edit);
