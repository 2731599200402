/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { srcset } from '@package/helpers/images';
import config from '@plone/volto/registry';

/**
 * ImageFromUrl component class.
 * @class ImageFromUrl
 * @extends Component
 */
class ImageFromUrl extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { url, size, title, className } = this.props;
    const imageParams = '/@@images/image' + (size ? '/' + size : '');
    if (url) {
      let src = url.includes(config.settings.apiPath)
        ? flattenToAppURL(url)
        : url;
      src = src.endsWith(imageParams) ? src : src + imageParams;

      return (
        <>
          {src ? (
            <img
              alt={title ?? ''}
              role={!title ? 'presentation' : null}
              src={src}
              loading="lazy"
              className={className ?? null}
              srcSet={srcset(src, size)}
            />
          ) : null}
        </>
      );
    } else {
      return null;
    }
  }
}

export default ImageFromUrl;
