import React, { useRef, useState } from 'react';

import { TextArea } from 'semantic-ui-react';

const TextAreaWidget = ({ name, value, placeholder, rows = 1, onChange }) => {
  const node = useRef();
  const [fieldRows, setFieldRows] = useState(rows);

  const resizeTextarea = (target, name) => {
    const textareaLineHeight = name === 'title' ? 34 : 23;
    const minRows = 1;
    const maxRows = 10;

    const previousRows = target.rows;
    target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      target.rows = currentRows;
    }

    if (currentRows >= previousRows) {
      target.rows = currentRows;
      target.scrollTop = target.scrollHeight;
    }
    setFieldRows(currentRows < maxRows ? currentRows : maxRows);
  };

  return (
    <TextArea
      id={name}
      name={name}
      placeholder={placeholder}
      value={value || ''}
      onChange={({ target }) => {
        resizeTextarea(target, name);
        onChange({
          [target.id]: target.value === '' ? undefined : target.value,
        });
      }}
      rows={fieldRows}
      ref={node}
    />
  );
};

export default TextAreaWidget;
