import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  view_all: { id: 'Vedi tutto', defaultMessage: 'Vedi tutto' },
});

const LinkMore = ({ linkTitle, linkHref, className }) => {
  const intl = useIntl();
  const linkMore = linkHref?.[0]?.['@id'];

  return linkMore ? (
    <div className={`footer ${className ?? ''}`}>
      <UniversalLink href={flattenToAppURL(linkMore)}>
        {linkTitle || intl.formatMessage(messages.view_all)}
      </UniversalLink>
    </div>
  ) : (
    <></>
  );
};

export default LinkMore;
