/**
 * Navigation reducer.
 * @module reducers/contextNavigation/contextNavigation
 */

import { map, omit } from 'lodash';

import { GET_CONTEXTNAVIGATION } from '@package/actions/contextNavigation/contextNavigation';
import { RESET_CONTEXTNAVIGATION } from '@package/actions/contextNavigation/contextNavigation';

import config from '@plone/volto/registry';

const initialState = {
  error: null,
  items: [],
  loaded: false,
  loading: false,
  subrequests: {},
};

/**
 * Recursive function that process the items returned by the navigation
 * endpoint
 * @function getRecursiveItems
 * @param {array} items The items inside a navigation response.
 * @returns {*} The navigation items object (recursive)
 */
function getRecursiveItems(items) {
  return map(items, (item) => ({
    title: item.title,
    url: item['@id'].replace(config.settings.apiPath, ''),
    ...(item.items && { items: getRecursiveItems(item.items) }),
  }));
}

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Navigation reducer.
 * @function contextNavigationReducer
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function contextNavigationReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case `${GET_CONTEXTNAVIGATION}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  items: [],
                }),
                loaded: false,
                loading: true,
                error: null,
              },
            },
          }
        : {
            ...state,
            [getRequestKey(action.type)]: {
              loading: true,
              loaded: false,
              error: null,
            },
          };

    case `${GET_CONTEXTNAVIGATION}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                loading: false,
                loaded: true,
                error: null,
                items:
                  action.result &&
                  action.result.items &&
                  getRecursiveItems(action.result.items),
              },
            },
          }
        : {
            ...state,

            items:
              action.result &&
              action.result.items &&
              getRecursiveItems(action.result.items),

            [getRequestKey(action.type)]: {
              loading: false,
              loaded: true,
              error: null,
            },
          };
    case `${GET_CONTEXTNAVIGATION}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                items: [],
                loading: false,
                loaded: false,
                error: action.error,
              },
            },
          }
        : {
            ...state,
            items: [],
            [getRequestKey(action.type)]: {
              loading: false,
              loaded: false,
              error: action.error,
            },
          };
    case RESET_CONTEXTNAVIGATION:
      return action.subrequest
        ? {
            ...state,
            subrequests: omit(state.subrequests, action.subrequest),
          }
        : {
            ...state,
            items: [],
          };
    default:
      return state;
  }
}
