import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { Icon as VoltoIcon } from '@plone/volto/components';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import { WhishlistButton } from '@package/components';
import PrintSVG from '@package/icons/print.svg';

const messages = defineMessages({
  share: {
    id: 'share',
    defaultMessage: 'Condividi',
  },
  close: {
    id: 'close',
    defaultMessage: 'Chiudi',
  },
});

const ViewTitle = ({
  title,
  description,
  showShare = true,
  url = '/',
  content,
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const shareUrl =
    typeof window === 'undefined'
      ? `https://www.modenatur.it${url}`
      : window.location.href;

  return (
    <>
    <div
      className={cx('view-title', {
        'with-desc': !!description,
        'with-share': showShare,
      })}
    >
      {showShare && (
        <div className={cx('share-buttons', { open })}>
          {!open ? (
            <button
              type="button"
              className="open-share"
              onClick={() => setOpen(true)}
              title={intl.formatMessage(messages.share)}
            >
              <Icon size="big" name="share alternate square" />
            </button>
          ) : (
            <>
              <button
                type="button"
                className="close-share"
                onClick={() => setOpen(false)}
                title={intl.formatMessage(messages.close)}
              >
                <Icon size="big" name="close" />
              </button>
              <FacebookShareButton url={shareUrl} quote={title}>
                <div className="fb-share" title="Facebook">
                  <Icon size="big" name="facebook official" />
                </div>
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={title}>
                <div className="tw-share" title="Twitter">
                  <Icon size="big" name="twitter square" />
                </div>
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl}>
                <div className="in-share" title="Linkedin">
                  <Icon size="big" name="linkedin" />
                </div>
              </LinkedinShareButton>
              <div
                role="button"
                class="SocialMediaShareButton SocialMediaShareButton--print"
              >
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    return window.print();
                  }}
                  className="print"
                >
                  <VoltoIcon size="48px" name={PrintSVG} />
                </button>
              </div>
            </>
          )}
          <WhishlistButton id={content?.['UID']} />
        </div>
      )}
      <h1 className="documentFirstHeading">{title}</h1>

    </div>
    {description && <div className="documentDescription">{description}</div>}
    </>
  );
};

export default ViewTitle;
