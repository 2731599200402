import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';

import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { SelectWidget } from '@plone/volto/components';

const Sidebar = ({ data, onChangeBlock, block, intl }) => {
  if (!data.align) {
    data.align = 'center';
  }
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="SearchStrutturaRicettiva"
            defaultMessage="Ricerca strutture ricettive"
          />
        </h2>
      </header>

      <Segment className="form">
        <SelectWidget
          id="search_type"
          title="Tipo di struttura"
          required={true}
          description="Se impostato, il blocco visualizzerà le strutture corrispondenti al tipo selezionato"
          value={data.search_type || []}
          onChange={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          choices={[
            ['StrutturaRicettiva', 'Struttura ricettiva'],
            ['Ristorante', 'Ristorante'],
          ]}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  block: PropTypes.string.isRequired,
};

export default injectIntl(Sidebar);
