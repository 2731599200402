import React from 'react';
import { Helmet } from '@plone/volto/helpers';

const Favicon = () => (
  <Helmet>
    {/* <link rel="icon" href="/favicon-16x16.png" sizes="16x16" /> */}
    {/* <link rel="icon" href="/favicon-32x32.png" sizes="32x32" /> */}
    {/* <link rel="icon" href="/android-chrome-192x192.png" sizes="192x192" /> */}

    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
    <meta name="msapplication-TileColor" content="#ffffff" />
    <meta name="theme-color" content="#ffffff" />

  </Helmet>
);

export default Favicon;
