import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { Icon as VoltoIcon } from '@plone/volto/components';

import Bike from './icons/bike.svg';
import EBike from './icons/e-bike.svg';
import Horse from './icons/horse.svg';
import Ski from './icons/ski.svg';
import Snowshoes from './icons/snowshoes.svg';

const IconList = {
  bike: Bike,
  'e-bike': EBike,
  horse: Horse,
  ski: Ski,
  snowshoes: Snowshoes,
};

const QuickInfo = ({ obj }) => {
  const show =
    (obj.recipients && obj.recipients.length > 0) ||
    obj.duration ||
    obj.booking_flag ||
    obj.transport_methods ||
    obj.tour_length ||
    obj['@type'] === 'Itinerario';
  if (!show) {
    return '';
  }
  return (
    <section id="quick-info">
      {obj.recipients &&
        obj.recipients.map((r) => (
          <div className="recipients info" key={r}>
            <span className="label">
              <FormattedMessage id="recipients for" defaultMessage="per" /> {r}
            </span>
            <span className="emphasis">
              <Icon name="users" />
            </span>
          </div>
        ))}
      {obj.duration && (
        <div className="duration info">
          <FormattedMessage id="duration" defaultMessage="Durata">
            {(message) => <span className="label">{message}</span>}
          </FormattedMessage>
          <span className="duration-value emphasis">{obj.duration}</span>
        </div>
      )}
      {obj.booking_flag && (
        <div className="needs-booking info">
          <span className="label">
            <FormattedMessage
              id="needs_booking"
              defaultMessage="Da prenotare"
            />
          </span>
        </div>
      )}

      {obj.transport_methods && (
        <div className="transport info">
          <span className="label">
            <FormattedMessage id="TransportMethod" defaultMessage="Trasporti" />
          </span>
          <span className="emphasis">
            {obj.transport_methods.map((method) =>
              Object.keys(IconList).indexOf(method.token) >= 0 ? (
                <VoltoIcon name={IconList[method.token]} size="1em" />
              ) : (
                <Icon name={method.token} key={method.token} />
              ),
            )}
          </span>
        </div>
      )}

      {/* {obj['@type'] === 'Itinerario' &&
        obj.items_total === 1 &&
        obj.items[0].steps?.length > 0 && (
          <div className="stop info">
            <span className="label">
              <FormattedMessage id="Tappe" defaultMessage="Tappe" />
            </span>
            <span className="emphasis">{obj.items[0].steps.length}</span>
          </div>
        )}
      {obj['@type'] === 'Itinerario' && obj.items_total > 1 && (
        <div className="stop info">
          <span className="label">
            <FormattedMessage id="Giorni" defaultMessage="Giorni" />
          </span>
          <span className="emphasis">{obj.items_total}</span>
        </div>
      )} */}

      {obj.tour_length && (
        <div className="tour-length info">
          <span className="label">KM</span>
          <span className="emphasis">{obj.tour_length}</span>
        </div>
      )}
    </section>
  );
};
export default QuickInfo;
