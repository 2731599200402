import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';

import {
  TypeLabel,
  ViewTitle,
  Booking,
  BookingExperience,
  Prices,
  Location,
  ContactsInfos,
  Gallery,
  //RelatedItems,
} from '@package/components';

//import ViewCTABox from '@package/components/Blocks/CtaBox/View';

const GallerySettings = {
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const StrutturaRicettivaView = ({ content, location }) => {
  return (
    <Container className="struttura-ricettiva-view">
      <Helmet title={content.title} />
      <article id="content">
        <Grid stackable columns={2} relaxed="very">
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <header>
              <TypeLabel type={content['@type']} />
              <ViewTitle
                title={content.title}
                description={
                  content.accomodation_type
                    ? content.accomodation_type.title
                    : null
                }
                url={location?.pathname}
                content={content}
              />
            </header>

            <ContactsInfos content={content} />

            {content.description && (
              <section id="description">{content.description}</section>
            )}

            {/* <section id="content-core">
              {content.text && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: flattenHTMLToAppURL(content.text.data),
                  }}
                />
              )}
            </section> */}

            {/* {content.call_to_action && (
              <ViewCTABox data={content.call_to_action} />
            )} */}
          </Grid.Column>

          <Grid.Column mobile={16} tablet={4} computer={4}>
            <aside className="right-column">
              <Prices prices={content.prices} />

              <Booking content={content} />

              <Location content={content} />

              {/* {content.how_to_reach && (
                <section className="info-box no-bg center">
                  <div className="info-box-header">
                    <FormattedMessage
                      id="How to reach"
                      defaultMessage="Come arrivare"
                    />
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: flattenHTMLToAppURL(content.how_to_reach.data),
                    }}
                  />
                </section>
              )} */}
              <BookingExperience content={content} />
            </aside>
          </Grid.Column>
        </Grid>
        <section className="images">
          {content.items && (
            <Gallery
              items={content.items}
              customSettings={GallerySettings}
              imageScale="large"
            />
          )}
        </section>

        {/* <Services services={content.services} columns={3} /> */}

        {/* {content.additional_infos && (
          <section className="additional_infos">
            <h3>
              <FormattedMessage
                id="Additional infos"
                defaultMessage="Informazioni aggiuntive"
              >
                {(message) => message}
              </FormattedMessage>
            </h3>

            <div
              dangerouslySetInnerHTML={{
                __html: flattenHTMLToAppURL(content.additional_infos.data),
              }}
            />
          </section>
        )} */}
        {/* {content.around_here_map && (
          <section className="around">
            <h3>
              <FormattedMessage
                id="Discover around the hotel"
                defaultMessage="Scopri i dintorni dell'hotel"
              >
                {(message) => message}
              </FormattedMessage>
            </h3>

            <div
              dangerouslySetInnerHTML={{
                __html: content.around_here_map,
              }}
            />
          </section>
        )} */}

        {/* {content.related_events?.length > 0 && (
          <FormattedMessage
            id="StrutturaRicettiva_relatedEvents"
            defaultMessage="Eventi per completare il tuo soggiorno"
          >
            {(message) => (
              <RelatedItems items={content.related_events} title={message} />
            )}
          </FormattedMessage>
        )} */}
      </article>
    </Container>
  );
};
StrutturaRicettivaView.propTypes = {
  content: PropTypes.shape({
    '@type': PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    text_body: PropTypes.shape({
      data: PropTypes.string,
    }),
    duration: PropTypes.string,
    needs_booking: PropTypes.bool,
    target_audience: PropTypes.shape({
      title: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        image_caption: PropTypes.string,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default StrutturaRicettivaView;
