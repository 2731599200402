import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from 'semantic-ui-react';
import { ItemTemplates, DefaultItem, LinkMore } from '@package/components';

const SmallTemplate = (data) => {
  const { items, isEditMode } = data;

  const align =
    items.filter(
      (i) => i['@type'] === 'Esperienza' || i['@type'] === 'SchedaTematica',
    ).length === items.length
      ? 'bottom'
      : 'top';

  return items.length > 0 ? (
    <div className="small-template">
      <Grid stackable columns={4} verticalAlign={align}>
        {items.map((item) => {
          let ItemTemplate = ItemTemplates[item['@type']] || DefaultItem;

          return (
            <Grid.Column key={item['@id']}>
              <ItemTemplate
                item={item}
                isEditMode={isEditMode}
                withTags={true}
                belowTitle={true}
                infoInTitle = {true}
                showDates = {false}
                showTags = {false}
                showPrice = {false}
              />
            </Grid.Column>
          );
        })}
      </Grid>

      <LinkMore {...data} />
    </div>
  ) : null;
};

SmallTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default SmallTemplate;
