/**
 * View NewsletterBox block.
 * @module components/Blocks/NewsletterBox/View
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '~/components';
import NewsletterForm from './NewsletterForm';

/**
 * View NewsletterBox block class.
 * @class View
 * @extends Component
 */
const View = ({ data, detached }) => {
  return (
    <Box className="newsletterBox">
      {data.title && (
        <div className="title" id="newsletterBox-title">
          {data.title}
        </div>
      )}
      {data.description && (
        <div className="description">{data.description}</div>
      )}
      <NewsletterForm data={data} />
    </Box>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
