import headingSVG from '@plone/volto/icons/heading.svg';
import ViewTitleVM from '@package/components/Blocks/TitleVM/View';
import EditTitleVM from '@package/components/Blocks/TitleVM/Edit';

import imageFullSVG from '@plone/volto/icons/image-full.svg';
import ViewFullImage from '@package/components/Blocks/FullImage/View';
import EditFullImage from '@package/components/Blocks/FullImage/Edit';

import mailSVG from '@plone/volto/icons/email.svg';
import ViewNewsletterBox from '@package/components/Blocks/NewsletterBox/View';
import EditNewsletterBox from '@package/components/Blocks/NewsletterBox/Edit';

import textBlocksSVG from '@plone/volto/icons/apps.svg';
import ViewTextBlocks from '@package/components/Blocks/TextBlocks/View';
import EditTextBlocks from '@package/components/Blocks/TextBlocks/Edit';

import iconsBlocksSVG from '@plone/volto/icons/dots.svg';
import ViewIconsBlocks from '@package/components/Blocks/IconsBlocks/View';
import EditIconsBlocks from '@package/components/Blocks/IconsBlocks/Edit';

import contactsBlocksSVG from '@plone/volto/icons/book.svg';
import ViewContactsBlocks from '@package/components/Blocks/ContactsBlocks/View';
import EditContactsBlocks from '@package/components/Blocks/ContactsBlocks/Edit';

import ViewImageBlocks from '@package/components/Blocks/ImageBlocks/View';
import EditImageBlocks from '@package/components/Blocks/ImageBlocks/Edit';

import downloadSVG from '@plone/volto/icons/download.svg';
import ViewDownload from '@package/components/Blocks/Download/View';
import EditDownload from '@package/components/Blocks/Download/Edit';

import contentListingSVG from '@plone/volto/icons/content-listing.svg';
import ViewSectionLinks from '@package/components/Blocks/SectionLinks/View';
import EditSectionLinks from '@package/components/Blocks/SectionLinks/Edit';

import buttonSVG from '@plone/volto/icons/link.svg';
import ViewButton from '@package/components/Blocks/Button/View';
import EditButton from '@package/components/Blocks/Button/Edit';

import selectAllSVG from '@plone/volto/icons/select-all.svg';

import ViewCTABox from '@package/components/Blocks/CtaBox/View';
import EditCTABox from '@package/components/Blocks/CtaBox/EditCTABox';

import formSVG from '@plone/volto/icons/form.svg';
import ViewContactForm from '@package/components/Blocks/ContactForm/View';
import EditContactForm from '@package/components/Blocks/ContactForm/Edit';

import zoomSVG from '@plone/volto/icons/zoom.svg';
import ViewSearchStrutturaRicettiva from '@package/components/Blocks/SearchStrutturaRicettiva/View';
import EditSearchStrutturaRicettiva from '@package/components/Blocks/SearchStrutturaRicettiva/Edit';

import EventSearchView from '@package/components/Blocks/EventSearch/View';
import EventSearchEdit from '@package/components/Blocks/EventSearch/Edit';

import codeSVG from '@plone/volto/icons/code.svg';
import TrekksoftEmbedView from '@package/components/Blocks/Trekksoft/View';
import TrekksoftEmbedEdit from '@package/components/Blocks/Trekksoft/Edit';

import sliderSVG from '@plone/volto/icons/slider.svg';
import SliderView from '@package/components/Blocks/Slider/View';
import SliderEdit from '@package/components/Blocks/Slider/Edit';

import SmallListingBlockTemplate from '@package/components/Blocks/Listing/SmallTemplate';
import InEvidenzaListingBlockTemplate from '@package/components/Blocks/Listing/InEvidenzaTemplate';
import EmphasisListingBlockTemplate from '@package/components/Blocks/Listing/EmphasisTemplate';
import MapListingBlockTemplate from '@package/components/Blocks/Listing/MapTemplate';
import ListListingBlockTemplate from '@package/components/Blocks/Listing/ListTemplate';
import StoriesListingBlockTemplate from '@package/components/Blocks/Listing/StoriesTemplate';
import LinksListingBlockTemplate from '@package/components/Blocks/Listing/LinksTemplate';

import ExperiencesSearchView from '@package/components/Blocks/ExperiencesSearch/View';
import ExperiencesSearchEdit from '@package/components/Blocks/ExperiencesSearch/Edit';

export const VisitBlocks = (config) => {
  config.blocks = {
    ...config.blocks,
    blocksConfig: {
      ...config.blocks.blocksConfig,
      titleVM: {
        id: 'titleVM',
        title: 'Titolo',
        icon: headingSVG,
        group: 'text',
        view: ViewTitleVM,
        edit: EditTitleVM,
        restricted: false,
        mostUsed: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      fullImage: {
        id: 'fullImage',
        title: 'Fascia immagine / video',
        icon: imageFullSVG,
        group: 'media',
        view: ViewFullImage,
        edit: EditFullImage,
        restricted: false,
        mostUsed: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      textBlocks: {
        id: 'textBlocks',
        title: 'Blocchi di testo',
        icon: textBlocksSVG,
        group: 'text',
        view: ViewTextBlocks,
        edit: EditTextBlocks,
        restricted: false,
        mostUsed: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      iconsBlocks: {
        id: 'iconsBlocks',
        title: 'Blocchi con icone',
        icon: iconsBlocksSVG,
        group: 'text',
        view: ViewIconsBlocks,
        edit: EditIconsBlocks,
        restricted: false,
        mostUsed: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      imageBlocks: {
        id: 'imageBlocks',
        title: 'Blocchi con immagine',
        icon: textBlocksSVG,
        group: 'media',
        view: ViewImageBlocks,
        edit: EditImageBlocks,
        restricted: false,
        mostUsed: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      contactsBlocks: {
        id: 'contactsBlocks',
        title: 'Blocchi contatti',
        icon: contactsBlocksSVG,
        group: 'text',
        view: ViewContactsBlocks,
        edit: EditContactsBlocks,
        restricted: false,
        mostUsed: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      ctaBox: {
        id: 'ctaBox',
        title: 'Box Call to action',
        icon: selectAllSVG,
        group: 'text',
        view: ViewCTABox,
        edit: EditCTABox,
        restricted: false,
        mostUsed: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
        blockHasOwnFocusManagement: true,
      },
      newsletterBox: {
        id: 'newsletterBox',
        title: 'Box Newsletter',
        icon: mailSVG,
        group: 'common',
        view: ViewNewsletterBox,
        edit: EditNewsletterBox,
        restricted: false,
        mostUsed: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      download: {
        id: 'download',
        title: 'Download',
        icon: downloadSVG,
        group: 'media',
        view: ViewDownload,
        edit: EditDownload,
        restricted: false,
        mostUsed: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      'button-block': {
        id: 'button-block',
        title: 'Bottone',
        icon: buttonSVG,
        group: 'media',
        view: ViewButton,
        edit: EditButton,
        restricted: false,
        mostUsed: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
        blockHasOwnFocusManagement: false,
      },
      section_links: {
        id: 'section_links',
        title: 'Link di sezione',
        icon: contentListingSVG,
        group: 'text',
        view: ViewSectionLinks,
        edit: EditSectionLinks,
        restricted: false,
        mostUsed: true,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      contactForm: {
        id: 'contactForm',
        title: 'Form di contatto',
        icon: formSVG,
        group: 'common',
        view: ViewContactForm,
        edit: EditContactForm,
        restricted: false,
        mostUsed: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      // searchStrutturaRicettiva: {
      //   id: 'searchStrutturaRicettiva',
      //   title: 'Ricerca strutture ricettive',
      //   icon: zoomSVG,
      //   group: 'search',
      //   view: ViewSearchStrutturaRicettiva,
      //   edit: EditSearchStrutturaRicettiva,
      //   restricted: false,
      //   mostUsed: true,
      //   security: {
      //     addPermission: [],
      //     view: [],
      //   },
      //   sidebarTab: 1,
      // },
      searchEvents: {
        id: 'searchEvents',
        title: 'Ricerca eventi',
        icon: zoomSVG,
        group: 'search',
        view: EventSearchView,
        edit: EventSearchEdit,
        restricted: false,
        mostUsed: false,
        blockHasOwnFocusManagement: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      searchExperiences: {
        id: 'searchExperiences',
        title: 'Ricerca esperienze',
        icon: zoomSVG,
        group: 'search',
        view: ExperiencesSearchView,
        edit: ExperiencesSearchEdit,
        restricted: false,
        mostUsed: false,
        blockHasOwnFocusManagement: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      trekksoftEmbed: {
        id: 'trekksoftEmbed',
        title: 'Embed Trekksoft',
        icon: codeSVG,
        group: 'common',
        view: TrekksoftEmbedView,
        edit: TrekksoftEmbedEdit,
        restricted: false,
        mostUsed: false,
        blockHasOwnFocusManagement: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
      slider: {
        id: 'slider',
        title: 'Slider',
        icon: sliderSVG,
        group: 'media',
        view: SliderView,
        edit: SliderEdit,
        restricted: false,
        mostUsed: false,
        blockHasOwnFocusManagement: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },

      listing: {
        ...config.blocks.blocksConfig.listing,
        showLinkMore: true,
        variations: [
          ...config.blocks.blocksConfig.listing.variations.map((v) => {
            v.fullobjects = true;
            return v;
          }),
          {
            id: 'small',
            isDefault: false,
            title: '4 per riga',
            template: SmallListingBlockTemplate,
            fullobjects: true,
          },
          {
            id: 'in_evidenza',
            isDefault: false,
            title: 'In evidenza',
            template: InEvidenzaListingBlockTemplate,
            fullobjects: true,
          },
          {
            id: 'emphasis',
            isDefault: false,
            title: 'Enfasi',
            template: EmphasisListingBlockTemplate,
            fullobjects: true,
          },
          {
            id: 'map',
            isDefault: false,
            title: 'Mappa',
            template: MapListingBlockTemplate,
            fullobjects: true,
          },
          {
            id: 'list',
            isDefault: false,
            title: 'Elenco',
            template: ListListingBlockTemplate,
            fullobjects: true,
          },
          {
            id: 'stories',
            isDefault: false,
            title: 'Storie',
            template: StoriesListingBlockTemplate,
            fullobjects: true,
          },
          {
            id: 'links',
            isDefault: false,
            title: 'Loghi',
            template: LinksListingBlockTemplate,
            fullobjects: true,
          },
        ],
      },
    },
    groupBlocksOrder: [
      ...config.blocks.groupBlocksOrder,
      { id: 'search', title: 'Ricerca' },
    ],
  };

  let indexOfTemplateSummary = config.blocks.blocksConfig.listing.variations.findIndex(
    (x) => x.id === 'summary',
  );

  config.blocks.blocksConfig.listing.variations.splice(
    indexOfTemplateSummary,
    1,
  );
};
