import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { GoogleMap, LinkMore } from '@package/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const MapTemplate = (data) => {
  const { items, isEditMode, addMarkerLink = true } = data;
  const mapItems = items.filter(
    (item) => item.geolocation && Object.keys(item.geolocation).length > 0,
  );

  const venues = mapItems.map((item) => {
    return {
      lat: parseFloat(item.geolocation.lat),
      lng: parseFloat(item.geolocation.lng),
      title: item.title,
      price: item.prices?.length ? item.prices[0] : null,
      link: flattenToAppURL(item['@id']),
    };
  });

  return items.length > 0 ? (
    <div className="map-template">
      {venues.length > 0 && (
        <>
          <GoogleMap
            venues={venues}
            addMarkerLink={addMarkerLink}
            height="500px"
          />
          <LinkMore {...data} />
        </>
      )}
      {!venues.length && isEditMode && (
        <FormattedMessage
          id="No results found to draw map"
          defaultMessage="Nessun elemento trovato per disegnare la mappa"
        />
      )}
    </div>
  ) : null;
};

MapTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default MapTemplate;
