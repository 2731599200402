/**
 * View title block.
 * @module components/manage/Blocks/Title/View
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * View title block class.
 * @class View
 * @extends Component
 */
const View = ({ properties }) => {
  //if content has lead-image, the title is displayed in lead-image component, so return null
  if (properties.image && properties.image.download) return null;
  else return <h1 className="documentFirstHeading">{properties.title}</h1>;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
