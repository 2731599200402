/**
 * Edit text block.
 * @module components/manage/Blocks/Title/Edit
 */

import React from 'react';
import { compose } from 'redux';
import { injectIntl, defineMessages } from 'react-intl';
import { DNDSubblocks, SubblockEdit, Subblock } from 'volto-subblocks';
import { Placeholder } from 'semantic-ui-react';

import { TextEditorWidget } from '@package/components';
import ViewIcon from './ViewIcon';

const messages = defineMessages({
  iconPlaceholder: {
    id: 'Icon placeholder',
    defaultMessage: 'Select an icon...',
  },
  descriptionPlaceholder: {
    id: 'Description placeholder',
    defaultMessage: 'Description...',
  },
});
/**
 * Edit text block class.
 * @class Edit
 * @extends Component
 */
class EditBlock extends SubblockEdit {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      focusOn: 'description',
    };
    if (!this.props.data.block_style) {
      this.props.data.block_style = 'underline';
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <Subblock subblock={this} className={this.props.data.block_style}>
        {!this.props.data.icon && (
          <Placeholder style={{ height: 50, width: 50 }}>
            <Placeholder.Image />
          </Placeholder>
        )}
        <ViewIcon icon={this.props.data.icon} size="40px" />

        <TextEditorWidget
          data={this.props.data}
          fieldName="description"
          selected={this.state.focusOn === 'description'}
          block={this.props.block}
          onChangeBlock={this.onChange}
          placeholder={this.props.intl.formatMessage(
            messages.descriptionPlaceholder,
          )}
        />
      </Subblock>
    );
  }
}

export default React.memo(compose(injectIntl, ...DNDSubblocks)(EditBlock));
