import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';

import {
  TypeLabel,
  ViewTitle,
  RelatedItems,
  TextOrBlocks,
} from '@package/components';
import CitazioneStoria from './CitazioneStoria';

const StoriaView = ({ content, location }) => {
  return (
    <Container className="experience-view">
      <Helmet title={content.title} />
      <article id="content">
        <header>
          <TypeLabel type={content['@type']} />
          <ViewTitle
            title={content.title}
            description={content.description}
            url={location?.pathname}
            content={content}
          />
        </header>

        <CitazioneStoria storia={content} />

        <section id="content-core">
          <TextOrBlocks content={content} />
        </section>

        {content.relatedItems?.length > 0 && (
          <FormattedMessage
            id="proposteRedazione"
            defaultMessage="Le proposte della redazione"
          >
            {(message) => (
              <RelatedItems items={content.relatedItems} title={message} />
            )}
          </FormattedMessage>
        )}
      </article>
    </Container>
  );
};
StoriaView.propTypes = {
  content: PropTypes.shape({
    '@type': PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    text_body: PropTypes.shape({
      data: PropTypes.string,
    }),
    duration: PropTypes.string,
    needs_booking: PropTypes.bool,
    target_audience: PropTypes.shape({
      title: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        image_caption: PropTypes.string,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default StoriaView;
