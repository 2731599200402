import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Accordion, Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Icon, TextWidget } from '@plone/volto/components';

import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';

const messages = defineMessages({
  actionUrl: {
    id: 'Action url',
    defaultMessage: 'Action url',
  },
  fieldName: {
    id: 'Field name',
    defaultMessage: 'Field name',
  },
  Settings: {
    id: 'Settings',
    defaultMessage: 'Impostazioni',
  },
});

const Sidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
}) => {
  const [activeAccIndex, setActiveAccIndex] = useState(0);

  function handleAccClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeAccIndex === index ? -1 : index;

    setActiveAccIndex(newIndex);
  }

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="NewsletterBox"
            defaultMessage="Newsletter box"
          />
          :<br /> {data.title}
        </h2>
      </header>

      <>
        <Accordion fluid styled className="form">
          <Accordion.Title
            active={activeAccIndex === 0}
            index={0}
            onClick={handleAccClick}
          >
            {intl.formatMessage(messages.Settings)}
            {activeAccIndex === 0 ? (
              <Icon name={upSVG} size="20px" />
            ) : (
              <Icon name={downSVG} size="20px" />
            )}
          </Accordion.Title>
          <Accordion.Content active={activeAccIndex === 0}>
            <TextWidget
              id="action"
              title={intl.formatMessage(messages.actionUrl)}
              required={true}
              value={data.actionUrl}
              onChange={(name, value) => {
                onChangeBlock(block, {
                  ...data,
                  actionUrl: value,
                });
              }}
            />
            <TextWidget
              id="name"
              title={intl.formatMessage(messages.fieldName)}
              required={false}
              value={data.fieldName}
              onChange={(name, value) => {
                onChangeBlock(block, {
                  ...data,
                  fieldName: value,
                });
              }}
            />
          </Accordion.Content>
        </Accordion>
      </>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
