/**
 * Navigation actions.
 * @module actions/navigation/navigation
 */

export const RESET_CONTEXTNAVIGATION = 'RESET_CONTEXT_NAVIGATION';
export const GET_CONTEXTNAVIGATION = 'GET_CONTEXT_NAVIGATION';

/**
 * Get navigation.
 * @function getNavigation
 * @param {string} url Content url.
 * @param {number} depth Depth of the navigation tree.
 * @param {string} subrequest Key of the subrequest
 * @returns {Object} Get navigation action.

 */
export function getContextNavigation(url, depth, subrequest = null) {
  // Note: Depth can't be 0 in plone.restapi
  return {
    type: GET_CONTEXTNAVIGATION,
    subrequest,
    request: {
      op: 'get',
      path: `${url}/@context-navigation${
        depth ? `?expand.navigation.depth=${depth}` : ''
      }`,
    },
  };
}

/**
 * Reset navigation function
 * @function resetNavigaton
 * @param {string} subrequest Key of the subrequest.
 * @returns {Object} Get navigation action
 */
export function resetContextNavigation(subrequest = null) {
  return {
    type: RESET_CONTEXTNAVIGATION,
    subrequest,
  };
}
