import React from 'react';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ConditionalLink } from '@plone/volto/components';

import { EventWhen } from '@package/components';
import { PriceRangeIndications, ListingImage } from '@package/components';

const Item = ({ item }) => {
  const infos =
    item['@type'] === 'Event' ? (
      <div className="item-info">
        <EventWhen
          start={item.start}
          end={item.end}
          whole_day={item.whole_day}
          open_end={item.open_end}
          show_times={false}
        />
      </div>
    ) : null;

  return (
    <div className="item event-item">
      <div className="item-content">
        <ConditionalLink to={flattenToAppURL(item['@id'])} condition={true}>
          {infos && infos}

          <ListingImage item={item} />

          <PriceRangeIndications item={item} />
        </ConditionalLink>
      </div>

      <div className="item-title">
        <ConditionalLink to={flattenToAppURL(item['@id'])} condition={true}>
          <h3>{item.title ? item.title : item.id}</h3>
        </ConditionalLink>
        {infos && infos}
      </div>
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Item;
