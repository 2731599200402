import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Button, Grid, Form } from 'semantic-ui-react';
import Geosuggest from 'react-geosuggest';
import { GoogleMap } from '@package/components';

const messages = defineMessages({
  geolocation: {
    id: 'geolocation',
    defaultMessage: 'Geolocalizzazione',
  },
  geolocationPlaceholder: {
    id: 'geolocation_placeholder',
    defaultMessage: 'Cerca un luogo...',
  },
  geolocationNoResults: {
    id: 'geolocation_noresults',
    defaultMessage: 'No results for "{userInput}"',
  },
  geolocationSelected: {
    id: 'geolocation_selected',
    defaultMessage: 'Selezionato',
  },
});

const GeoLocationWidget = ({
  value,
  id,
  onChange,
  intl,
  required,
  title,
  description,
}) => {
  const onSuggestSelect = ({ description, location: { lat, lng } }) =>
    onChange(id, { description, lat, lng });

  return (
    <Form.Field inline required={required} id={id}>
      <Grid>
        <Grid.Row>
          <Grid.Column width="4">
            <div className="wrapper">
              <label htmlFor="geolocation-search">
                {title ?? intl.formatMessage(messages.geolocation)}
              </label>
            </div>
          </Grid.Column>
          <Grid.Column width="8" className="geolocation-widget">
            {value && Object.keys(value).length > 0 ? (
              <React.Fragment>
                <GoogleMap showTitle={false} venues={[value]} />
                <div className="geolocation-selected-wrapper">
                  {value.description && (
                    <span className="geolocation-selected">
                      <small>
                        {`${intl.formatMessage(
                          messages.geolocationSelected,
                        )}: `}
                      </small>
                      {value.description}
                    </span>
                  )}
                  <Button
                    icon="trash"
                    size="mini"
                    onClick={() => onChange(id, {})}
                  />
                </div>
              </React.Fragment>
            ) : (
              <div className="ui input">
                <Geosuggest
                  onSuggestSelect={(suggest) =>
                    suggest ? onSuggestSelect(suggest) : null
                  }
                  placeholder={intl.formatMessage(
                    messages.geolocationPlaceholder,
                  )}
                  autoActivateFirstSuggest
                  country="IT"
                  onSuggestNoResults={(userInput) =>
                    intl.formatMessage(messages.geolocationNoResults, {
                      userInput,
                    })
                  }
                />
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
        {description && (
          <Grid.Row stretched>
            <Grid.Column stretched width="12">
              <p className="help">{description}</p>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Form.Field>
  );
};

export default injectIntl(GeoLocationWidget);
