/**
 * Sitemap settings reducers.
 * @module reducers/sitemapSettings
 */
import { GET_SITEMAP_SETTINGS } from '@package/actions/sitemapSettings';

const initialState = {
  error: null,
  settings: {},
  loaded: false,
  loading: false,
};

/**
 * Sitempap settings reducer.
 * @function sitemapSettingsReducer
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */

export default function sitemapSettingsReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case `${GET_SITEMAP_SETTINGS}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${GET_SITEMAP_SETTINGS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        settings: action.result ?? {},
      };
    case `${GET_SITEMAP_SETTINGS}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
