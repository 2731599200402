import React from 'react';
import PropTypes from 'prop-types';

const TagsView = ({ subjects }) => {
  return subjects?.length > 0 ? (
    <div className="category-wrapper">
      <div className="category-container">
        <span className="category-label">{subjects.join(', ')}</span>
      </div>
    </div>
  ) : null;
};

TagsView.propTypes = {
  tags: PropTypes.array,
};

export default TagsView;
