/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Image } from 'semantic-ui-react';
import { defineMessages } from 'react-intl';
import { map } from 'lodash';
import { FormattedMessage } from 'react-intl';
import {
  Helmet,
  flattenHTMLToAppURL,
  hasBlocksData,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  getBaseUrl,
} from '@plone/volto/helpers';
import { srcset } from '@package/helpers';
import { ViewTitle, RelatedItems } from '@package/components';
import config from '@plone/volto/registry';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});
/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = ({ content, intl, location }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  return (
    <Container className="view-wrapper news-item-view">
      <Helmet title={content.title} />
      <article id="content">
        <header>
          <ViewTitle title={content.title} showShare url={location?.pathname} />
        </header>
        <section id="content-core">
          {content.image && (
            <figure className="news-image">
              <Image
                src={content.image.download}
                alt={content?.title ?? ''}
                loading="lazy"
                srcSet={srcset(content.image.download, 'leadimage')}
              />
            </figure>
          )}
          {hasBlocksData(content)
            ? map(content[blocksLayoutFieldname].items, (block) => {
                const blockType = content[blocksFieldname]?.[block]?.['@type'];
                if (['title', 'pageDescription'].indexOf(blockType) > -1) {
                  return null;
                }

                const Block =
                  config.blocks.blocksConfig[blockType]?.['view'] ?? null;

                return Block !== null ? (
                  <Block
                    key={block}
                    id={block}
                    properties={content}
                    data={content[blocksFieldname][block]}
                    path={getBaseUrl(location?.pathname || '')}
                  />
                ) : (
                  <div key={block}>
                    {intl.formatMessage(messages.unknownBlock, {
                      block: content[blocksFieldname]?.[block]?.['@type'],
                    })}
                  </div>
                );
              })
            : content.text?.data && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: flattenHTMLToAppURL(content.text.data),
                  }}
                />
              )}
        </section>

        {content.relatedItems?.length > 0 && (
          <FormattedMessage
            id="ti_potrebbe_interessare_anche"
            defaultMessage="Ti potrebbe interessare anche..."
          >
            {(message) => (
              <RelatedItems items={content.relatedItems} title={message} />
            )}
          </FormattedMessage>
        )}
      </article>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;
