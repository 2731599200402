import React from 'react';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import alignCenterSVG from '@plone/volto/icons/align-center.svg';
import quoteSVG from '@plone/volto/icons/quote.svg';
//import createLinkDetectionPlugin from '@plone/volto/components/manage/LinkDetectionPlugin/link-detection-plugin';

const AlignCenterButton = (props) =>
  props.draftJsCreateBlockStyleButton.default({
    blockType: 'align-center',
    children: <Icon name={alignCenterSVG} size="24px" />,
  });

const AlignCenterToHtmlRenderer = {
  'align-center': (children, { keys }) =>
    children.map((child, i) => (
      <p id={keys[i]} key={keys[i]} className="align-center">
        {child}
      </p>
    )),
};

const BlockquoteBigButton = (props) =>
  props.draftJsCreateBlockStyleButton.default({
    blockType: 'blockquote-big',
    children: <Icon name={quoteSVG} size="30px" />,
  });

export const toolbarButtons = (props) => {
  return {
    AlignCenterButton: AlignCenterButton(props),
    BlockquoteBigButton: BlockquoteBigButton(props),
  };
};
export const richTextRenderers = {
  AlignCenterToHtmlRenderer: AlignCenterToHtmlRenderer,
};
