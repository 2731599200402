import React from 'react';
import PropTypes from 'prop-types';
import Body from '@package/components/Blocks/EventSearch/Body';
import { SidebarPortal } from '@plone/volto/components';

import Sidebar from '@package/components/Blocks/EventSearch/Sidebar';

const Edit = ({ data, block, onChangeBlock, selected, pathname }) => {
  return (
    <div className="event-search">
      <Body data={data} isEditMode={true} onChangeBlock={onChangeBlock} />

      <SidebarPortal selected={selected}>
        <Sidebar data={data} block={block} onChangeBlock={onChangeBlock} />
      </SidebarPortal>
    </div>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  block: PropTypes.string.isRequired,
  selected: PropTypes.any,
  intl: PropTypes.any,
  onChangeBlock: PropTypes.func.isRequired,
};

export default Edit;
