import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Accordion, Segment } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';

import { LinkToWidget, ImageSidebarWidget } from '@package/components';

import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';

const messages = defineMessages({
  LinkSettings: {
    id: 'Link settings',
    defaultMessage: 'Impostazioni Link',
  },
  Settings: {
    id: 'Settings',
    defaultMessage: 'Impostazioni',
  },
});

const Sidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
}) => {
  const [activeAccIndex, setActiveAccIndex] = useState(0);

  function handleAccClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeAccIndex === index ? -1 : index;

    setActiveAccIndex(newIndex);
  }

  return (
    <Segment.Group raised>
      <ImageSidebarWidget
        data={data}
        onChange={(data) => onChangeBlock(block, data)}
        openObjectBrowser={openObjectBrowser}
      />

      {data.url && (
        <>
          <Accordion fluid styled className="form">
            <Accordion.Title
              active={activeAccIndex === 1}
              index={1}
              onClick={handleAccClick}
            >
              {intl.formatMessage(messages.LinkSettings)}
              {activeAccIndex === 1 ? (
                <Icon name={upSVG} size="20px" />
              ) : (
                <Icon name={downSVG} size="20px" />
              )}
            </Accordion.Title>
            <Accordion.Content active={activeAccIndex === 1}>
              <LinkToWidget
                data={data}
                openObjectBrowser={openObjectBrowser}
                onChange={(name, value) =>
                  onChangeBlock(block, {
                    ...data,
                    [name]: value,
                  })
                }
                showTarget={false}
              />
            </Accordion.Content>
          </Accordion>
        </>
      )}
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
