import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import { Helmet, flattenHTMLToAppURL } from '@plone/volto/helpers';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import {
  TypeLabel,
  ViewTitle,
  QuickInfo,
  BookingExperience,
  Services,
  RelatedItems,
  Prices,
  Location,
  ContactsInfos,
} from '@package/components';

import ViewCTABox from '@package/components/Blocks/CtaBox/View';
import { ConditionalEmbed } from 'volto-gdpr-privacy';

const messages = defineMessages({
  quando: {
    id: 'Quando',
    defaultMessage: 'Quando',
  },
});

const ExperienceView = ({ content, location }) => {
  const intl = useIntl();
  return (
    <Container className="experience-view">
      <Helmet title={content.title} />
      <article id="content">
        <Grid
          stackable
          columns={2}
          relaxed="very"
          className="grid-content-wrapper"
        >
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <header>
              <TypeLabel type={content['@type']} />
              <ViewTitle
                title={content.title}
                description={content.description}
                url={location?.pathname}
                content={content}
              />
            </header>

            <div className="mobile-only">
              <BookingExperience content={content} />
              <Prices prices={content.prices} />
            </div>

            <QuickInfo obj={content} />

            <section id="content-core">
              {content.text && (
                <div
                  className="p-no-margin"
                  dangerouslySetInnerHTML={{
                    __html: flattenHTMLToAppURL(content.text.data),
                  }}
                />
              )}
              <Services services={content.services} columns={2} />
            </section>
            {content.call_to_action && (
              <ViewCTABox data={content.call_to_action} />
            )}
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={4}>
            <aside className="right-column">
              <Prices prices={content.prices} />

              <BookingExperience content={content} />

              <ContactsInfos
                content={content}
                timetable_title={intl.formatMessage(messages.quando)}
              />

              <Location content={content} />
            </aside>
          </Grid.Column>
        </Grid>

        {content.video_link && (
          <section className="full-video">
            <ConditionalEmbed url={content.video_link}>
              <iframe
                title="Video"
                width="100%"
                height="500"
                src={content.video_link}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              />
            </ConditionalEmbed>
          </section>
        )}

        {content.related_experiences?.length > 0 && (
          <FormattedMessage
            id="relatedExperiences"
            defaultMessage="Potrebbe piacerti anche"
          >
            {(message) => (
              <RelatedItems
                items={content.related_experiences}
                title={message}
                className="related-experiences"
              />
            )}
          </FormattedMessage>
        )}

        {content.thematic_sheets?.length > 0 && (
          <FormattedMessage
            id="schedeTematicheCorrelate"
            defaultMessage="Per approfondire"
          >
            {(message) => (
              <RelatedItems
                items={content.thematic_sheets}
                title={message}
                className="thematic-sheets"
              />
            )}
          </FormattedMessage>
        )}
      </article>
    </Container>
  );
};
ExperienceView.propTypes = {
  content: PropTypes.shape({
    '@type': PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    text_body: PropTypes.shape({
      data: PropTypes.string,
    }),
    duration: PropTypes.string,
    needs_booking: PropTypes.bool,
    target_audience: PropTypes.shape({
      title: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        image_caption: PropTypes.string,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default ExperienceView;
