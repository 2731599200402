import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Grid, Form, Button } from 'semantic-ui-react';

import { IconsWidget, LinkToWidget } from '@package/components';
import IconList from '../icons';

const messages = defineMessages({
  block_style: {
    id: 'Block style',
    defaultMessage: 'Stile del blocco',
  },
  block_style_underline: {
    id: 'Block style underline',
    defaultMessage: 'Sottolineato',
  },
  block_style_simple: {
    id: 'Block style simple',
    defaultMessage: 'Semplice',
  },
});

const SidebarBlock = ({
  subblock,
  index,
  intl,
  onChangeBlock,
  openObjectBrowser,
}) => {
  let styles = [
    ['underline', intl.formatMessage(messages.block_style_underline)],
    ['simple', intl.formatMessage(messages.block_style_simple)],
  ];

  return (
    <>
      <Form.Field inline>
        <Grid>
          <Grid.Row>
            <Grid.Column width="4">
              <div className="wrapper">
                <label htmlFor="field-align">
                  {intl.formatMessage(messages.block_style)}
                </label>
              </div>
            </Grid.Column>
            <Grid.Column width="8" className="align-tools">
              {styles.map(s => (
                <Button.Group key={s}>
                  <Button
                    basic
                    icon
                    aria-label={s[1]}
                    className="with-text"
                    onClick={a =>
                      onChangeBlock(index, {
                        ...subblock,
                        block_style: s[0],
                      })
                    }
                    active={subblock.block_style === s[0]}
                  >
                    {intl.formatMessage(messages['block_style_' + s[0]])}
                  </Button>
                </Button.Group>
              ))}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Field>

      <LinkToWidget
        data={subblock}
        openObjectBrowser={openObjectBrowser}
        onChange={(name, value) => {
          onChangeBlock(index, {
            ...subblock,
            [name]: value,
          });
        }}
      />

      <Segment.Group>
        <IconsWidget
          value={subblock.icon}
          icons={IconList}
          onChange={icon => {
            onChangeBlock(index, {
              ...subblock,
              icon: icon,
            });
          }}
        />
      </Segment.Group>
    </>
  );
};

SidebarBlock.propTypes = {
  subblock: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(SidebarBlock);
