/**
 * Routes.
 * @module routes
 */

import { App, Search, Sitemap } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';
import WhishlistPage from '@package/components/Views/WhishlistPage/WhishlistPage';
import config from '@plone/volto/registry';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      // Add your routes here
      {
        path: '/**/search',
        component: Search,
      },
      {
        path: '/**/sitemap',
        component: Sitemap,
      },
      {
        path: '*/whishlist',
        component: WhishlistPage,
      },
      ...(config.addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
