import Exhibition from './exhibition.svg';
import Gift from './gift.svg';
import Museum from './museum.svg';
import SportsCar from './sports-car.svg';
import Beer from './beer.svg';
import Castle from './castle.svg';
import Info from './info.svg';
import WineGlass from './wine-glass.svg';

const CTAIcons = {
  Exhibition,
  Gift,
  Museum,
  SportsCar,
  Beer,
  Castle,
  Info,
  WineGlass,
};
export default CTAIcons;
