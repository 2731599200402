import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { getQueryStringResults } from '@plone/volto/actions';
import { Helmet } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import { useWhishlist } from '@package/helpers';
import { Modal, Grid, Popup, Button } from 'semantic-ui-react';
import leadimage from './whishlist-leadimage.jpg';
import Item from './Item';
import HeartFull from '@package/icons/heart-solid.svg';
import { Portal } from 'react-portal';
import PrintSVG from '@package/icons/print.svg';
import DeleteSVG from '@plone/volto/icons/delete.svg';

import config from '@plone/volto/registry';

const messages = defineMessages({
  wishlist_title: {
    id: 'wishlist_title',
    defaultMessage: 'La tua lista dei desideri',
  },
  whishlist_info1: {
    id: 'whishlist_info1',
    defaultMessage:
      'Con la Wishlist, la lista dei desideri, puoi salvare contenuti interessanti, eventi a cui partecipare, itinerari che vuoi percorrere, o luoghi di interesse da scoprire.',
  },
  whishlist_info2: {
    id: 'whishlist_info2',
    defaultMessage:
      'Usa il pulsante con il cuore che trovi su tutti i contenuti del sito per salvarli e consultarli in seguito.',
  },
  whishlist_info3: {
    id: 'whishlist_info3',
    defaultMessage:
      'Per rimuovere un elemento dalla lista, clicca sul pulsante con il cuore in corrispondenza del contenuto.',
  },
  delete_all: {
    id: 'whishlist_delete_all',
    defaultMessage: 'Elimina tutto',
  },
  confirm_delete_all: {
    id: 'whishlist_confirm_delete_all',
    defaultMessage:
      'Sei sicuro di volere eliminare tutti gli elementi presenti nella lista dei desideri?',
  },
  no: {
    id: 'whishlist_delete_all_no',
    defaultMessage: 'No',
  },
  yes_delete_all: {
    id: 'whishlist_yes_delete_all',
    defaultMessage: 'Si, cancella tutto',
  },
  close_modal: {
    id: 'whishlist_close_modal',
    defaultMessage: 'Chiudi',
  },
  remove_from_whishlist: {
    id: 'remove_from_whishlist',
    defaultMessage: 'Rimuovi dalla lista dei desideri',
  },
  print: {
    id: 'whishlist_print',
    defaultMessage: 'Stampa la lista',
  },
});

const WhishlistPage = ({ location }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pathname = config.settings.isMultilingual ? '/' + intl.locale : '/';
  const { whishlist = [], clearWhishlist, toggleWhishlist } = useWhishlist();
  const [deleteAll, setDeleteAll] = useState(false);
  const results = useSelector((state) => {
    return state.querystringsearch?.subrequests?.whishlist;
  });
  const [isClient, setIsClient] = React.useState(null);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  React.useEffect(() => {
    if (isClient) {
      const leadimage = document.getElementById('leadimage-portal');
      if (leadimage) {
        leadimage.classList.add('with-content');
      }
    }
  }, []);


  const [items, setItems] = useState([]);

  useEffect(() => {
    let res_items = results?.items ?? [];
    if (res_items.length > 0) {
      const reverseWhishlist = [...whishlist].reverse();
      res_items.sort((a, b) => {
        return (
          reverseWhishlist.indexOf(a['UID']) -
          reverseWhishlist.indexOf(b['UID'])
        );
      });
    }
    setItems(res_items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results?.items]);

  useEffect(() => {
    dispatch({
      type: 'RESET_CONTENT',
    });

    if (!results?.loading && whishlist?.length > 0) {
      dispatch(
        getQueryStringResults(
          pathname,
          {
            fullobjects: 1,
            query: [
              {
                i: 'UID',
                o: 'plone.app.querystring.operation.selection.any',
                v: whishlist,
              },
            ],
            b_size: 10000,
          },
          'whishlist',
          1,
        ),
      );

      // return dispatch({
      //   type: 'RESET_QUERYSTRING_RESULTS',
      //   subrequest: 'whishlist',
      // });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="page-whishlist" className="ui container">
      <Helmet title={intl.formatMessage(messages.wishlist_title)}></Helmet>

      {/* <Portal node={isClient && document.getElementById('leadimage-portal')}>
        <div className="lead-image" style={{ height: 600 + 'px' }}>
          <img
            src={leadimage}
            alt={intl.formatMessage(messages.wishlist_title)}
            loading="lazy"
            className="ui image"
          />
          <h1>{intl.formatMessage(messages.wishlist_title)}</h1>
        </div>
      </Portal> */}

      <div className="whishlist-infos">
        <div className="info-icon">
          <Icon name={HeartFull} />
        </div>
        <p>{intl.formatMessage(messages.whishlist_info1)}</p>
        <p>{intl.formatMessage(messages.whishlist_info2)}</p>
        <p>{intl.formatMessage(messages.whishlist_info3)}</p>
      </div>

      {whishlist.length > 0 && (
        <div className="whishlist-list-wrapper">
          <div className="whishlist-actions">
            <Button
              icon
              size="mini"
              onClick={(e) => {
                e.preventDefault();
                return window.print();
              }}
            >
              <Icon name={PrintSVG} className="icon" />
              {intl.formatMessage(messages.print)}
            </Button>
          </div>
          {items?.length > 0 && (
            <Grid stackable className="listing">
              {items.map((item, index) => (
                <Grid.Column
                  tablet={12}
                  computer={4}
                  widescreen={3}
                  largeScreen={3}
                  key={index}
                >
                  <div className="whislist-item-actions">
                    <Popup
                      trigger={
                        <Button
                          icon
                          size="mini"
                          primary
                          onClick={() => {
                            toggleWhishlist(item['UID']);
                            items.splice(index, 1);
                          }}
                          title={intl.formatMessage(
                            messages.remove_from_whishlist,
                          )}
                          animated="vertical"
                          key={index + 'button'}
                        >
                          <Button.Content hidden>
                            <Icon name={DeleteSVG} className="icon" />
                          </Button.Content>

                          <Button.Content visible>
                            <Icon name={HeartFull} className="icon" />
                          </Button.Content>
                        </Button>
                      }
                      content={intl.formatMessage(
                        messages.remove_from_whishlist,
                      )}
                      position="bottom center"
                      size="mini"
                      inverted
                    />
                  </div>
                  <Item item={item} />
                </Grid.Column>
              ))}
            </Grid>
          )}
          <div className="delete-all-wrapper">
            <Button
              primary
              onClick={() => {
                setDeleteAll(true);
              }}
            >
              {intl.formatMessage(messages.delete_all)}
            </Button>
          </div>
          <Modal
            id="modalWhishlist"
            closeIcon
            onClose={() => setDeleteAll(false)}
            closeOnEscape={true}
            open={deleteAll}
            size="small"
            centered
          >
            <Modal.Content>
              <Modal.Description>
                {intl.formatMessage(messages.confirm_delete_all)}
              </Modal.Description>
            </Modal.Content>

            <Modal.Actions>
              <Button color="black" onClick={() => setDeleteAll(false)}>
                {intl.formatMessage(messages.no)}
              </Button>
              <Button
                content={intl.formatMessage(messages.yes_delete_all)}
                primary
                onClick={() => {
                  clearWhishlist();
                  setDeleteAll(false);
                }}
              />
            </Modal.Actions>
          </Modal>{' '}
        </div>
      )}
    </div>
  );
};

export default WhishlistPage;
