import { flattenToAppURL } from '@plone/volto/helpers';
const SRCSET = { preview: 400, large: 768, leadimage: 1440 };

export const srcset = (image, maxSize) => {
  let srcsetList = [];
  let maxWidth = maxSize ? SRCSET[maxSize] : 0;

  if (typeof image === 'object') {
    Object.keys(SRCSET).map((size) => {
      if (maxWidth === 0 || SRCSET[size] <= maxWidth) {
        srcsetList.push(
          flattenToAppURL(image.scales[size].download) +
            ' ' +
            SRCSET[size] +
            'w',
        );
      }
    });
  }
  if (typeof image === 'string' && image.indexOf('@@images/') >= 0) {
    let src = image.substring(0, image.indexOf('@@images/'));
    Object.keys(SRCSET).map((size) => {
      if (maxWidth === 0 || SRCSET[size] <= maxWidth) {
        srcsetList.push(
          src + '@@images/image/' + size + ' ' + SRCSET[size] + 'w',
        );
      }
    });
  }

  if (srcsetList.length > 0) {
    return srcsetList.join(', ');
  }
  return null;
};
