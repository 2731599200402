/**
 * Box component.
 * @module components/Box
 */

import React from 'react';
import cx from 'classnames';

/**
 * Box image block class.
 * @class Box
 * @extends Component
 */
class Box extends React.Component {
  render() {
    return (
      <div className={cx('box', this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}

export default Box;
