import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import {
  TypeLabel,
  ViewTitle,
  QuickInfo,
  Ticket,
  BookingExperience,
  Services,
  Prices,
  Location,
  ContactsInfos,
  Gallery,
  ThematicAreaPoints,
  RelatedItems,
  TextOrBlocks,
} from '@package/components';

import ViewCTABox from '@package/components/Blocks/CtaBox/View';
import { ConditionalEmbed } from 'volto-gdpr-privacy';

const messages = defineMessages({
  tema: {
    id: 'Tema',
    defaultMessage: 'Tema',
  },
});

const SchedaTematicaView = ({ content, location, intl }) => {
  return (
    <Container className="schedatematica-view">
      <Helmet title={content.title} />
      <article id="content">
        <section className="pre-header">
          {content.thematic_area && (
            <TypeLabel>
              {intl.formatMessage(messages.tema)} {content.thematic_area.title}
            </TypeLabel>
          )}
          {content.items && <Gallery items={content.items} />}
        </section>
        <Grid stackable columns={2} relaxed="very">
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <header>
              <ViewTitle
                title={content.title}
                description={content.description}
                url={location?.pathname}
                content={content}
              />
            </header>

            <QuickInfo obj={content} />

            <section id="content-core">
              <TextOrBlocks content={content} />

              <Services services={content.services} columns={2} />
            </section>
            {content.call_to_action && (
              <ViewCTABox data={content.call_to_action} />
            )}
          </Grid.Column>

          <Grid.Column mobile={16} tablet={4} computer={4}>
            <aside className="right-column">
              <ContactsInfos content={content} />

              <Location content={content} />

              <Prices prices={content.prices} />

              <Ticket content={content} />

              <BookingExperience content={content} />
            </aside>
          </Grid.Column>
        </Grid>

        {content.video_link && (
          <section className="full-video">
            <ConditionalEmbed url={content.video_link}>
              <iframe
                title="Video"
                width="100%"
                height="500"
                src={content.video_link}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              />
            </ConditionalEmbed>
          </section>
        )}

        {/* <RelatedItineraries schedaTematica={content} /> */}

        {content.related_experiences?.length > 0 && (
          <FormattedMessage
            id="relatedExperiencesSchedaTematica"
            defaultMessage="Esperienze correlate"
          >
            {(message) => (
              <RelatedItems
                items={content.related_experiences}
                title={message}
                className="related-experiences"
              />
            )}
          </FormattedMessage>
        )}

        <ThematicAreaPoints path={content['@id']} />
      </article>
    </Container>
  );
};
SchedaTematicaView.propTypes = {
  content: PropTypes.shape({
    '@type': PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    text_body: PropTypes.shape({
      data: PropTypes.string,
    }),
    duration: PropTypes.string,
    needs_booking: PropTypes.bool,
    target_audience: PropTypes.shape({
      title: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        image_caption: PropTypes.string,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default injectIntl(SchedaTematicaView);
