/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';

import contextNavigationReducer from './contextNavigation/contextNavigation';
import searchFiltersStructuresReducer from './searchFiltersStructures';
import sitemapSettingsReducer from './sitemapSettings';
import eventsFiltersReducer from './eventsFilters';
import experiencesFiltersReducer from './experiencesFilters';
import experiencesResultReducer from './experiencesSearch';
import whishlistReducer from './whishlist';
import config from '@plone/volto/registry';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  ...config.addonReducers,
  contextNavigation: contextNavigationReducer,
  searchFilters: searchFiltersStructuresReducer,
  sitemapSettings: sitemapSettingsReducer,
  eventsFilters: eventsFiltersReducer,
  experiencesFilters: experiencesFiltersReducer,
  experiencesResult: experiencesResultReducer,
  whishlist: whishlistReducer,
};

export default reducers;
