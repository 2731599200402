import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { Grid, Image } from 'semantic-ui-react';
import { flattenHTMLToAppURL, flattenToAppURL } from '@plone/volto/helpers';
import cx from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import TappaCollapsed from './TappaCollapsed';
import { srcset } from '@package/helpers';
import { ConditionalEmbed } from 'volto-gdpr-privacy';

const messages = defineMessages({
  sliderDotTitle: {
    id: 'itinerario-day-slider-dot-title',
    defaultMessage: 'Vai alla tappa {idx}',
  },
});

const DayCollapsed = ({ day, intl }) => {
  const slider = React.useRef(null);
  const [activeSlide, setActiveSlide] = React.useState(0);

  const changeSlide = (index) => {
    if (slider?.current?.slickGoTo) slider.current.slickGoTo(index);
    setActiveSlide(index);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    accessibility: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots) => (
      <div className="paging">
        <div className="nav-label">
          <FormattedMessage
            id="Esplora le tappe"
            defaultMessage="Esplora le tappe"
          />
        </div>
        <ul>
          {dots.map((dot, idx) => {
            return (
              <li
                key={idx}
                className={idx === activeSlide ? 'slick-active' : ''}
              >
                <button
                  title={intl.formatMessage(messages.sliderDotTitle, { idx })}
                  onClick={() => changeSlide(idx)}
                >
                  {idx + 1}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    ),
    customPaging: (i) => <span>{i + 1}</span>,
  };
  return (
    <section className="day-collapsed">
      <div className="itinerary-day">
        <h2>{day.title}</h2>

        <div className="itinerary-day-content">
          {day.description && (
            <div className="description">{day.description}</div>
          )}
          {day.text && (
            <div className="text">
              <div
                className="p-no-margin"
                dangerouslySetInnerHTML={{
                  __html: flattenHTMLToAppURL(day.text.data),
                }}
              />
            </div>
          )}
          <Grid stackable verticalAlign="bottom">
            {day.image?.scales?.large?.download && (
              <Grid.Column width={8}>
                <figure>
                  <Image
                    src={flattenToAppURL(day.image.scales.large.download)}
                    alt=""
                    loading="lazy"
                    srcSet={srcset(day.image, 'large')}
                  />
                </figure>
              </Grid.Column>
            )}

            <Grid.Column width={4} className="map">
              {day.itinerary_map && (
                <>
                  <h3>
                    <FormattedMessage
                      id="Mappa itinerario"
                      defaultMessage="Itinerario"
                    />
                  </h3>
                  <ConditionalEmbed code={day.itinerary_map}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: day.itinerary_map,
                      }}
                    />
                  </ConditionalEmbed>
                </>
              )}
            </Grid.Column>
          </Grid>
        </div>
      </div>

      {day.steps?.length > 0 && (
        <Slider
          {...sliderSettings}
          ref={slider}
          className={cx('tappe', { 'small-dots': day.steps.length > 5 })}
        >
          {day.steps.map((tappa, index) => {
            return <TappaCollapsed tappa={tappa} key={`tappa${index}`} />;
          })}
        </Slider>
      )}
    </section>
  );
};
DayCollapsed.propTypes = {
  day: PropTypes.object.isRequired,
};

export default injectIntl(DayCollapsed);
