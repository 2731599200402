import React from 'react';
import { Map } from 'immutable';
import { Separator } from 'draft-js-inline-toolbar-plugin';

import Plugins from '@plone/volto/config/RichTextEditor/Plugins';
import Blocks from '@plone/volto/config/RichTextEditor/Blocks';
import FromHTMLCustomBlockFn from '@plone/volto/config/RichTextEditor/FromHTML';

import {
  richTextRenderers,
  toolbarButtons,
} from './RichTextEditor/ToolbarConfig';

/***** DraftJs *****/

const addBreaklines = (children) =>
  children.map((child) => {
    return child[1].map((child) => [
      <React.Fragment key={child}>
        {child}
        <br />
      </React.Fragment>,
    ]);
  });

const visitBlockRenderMap = Map({
  'align-center': {
    element: 'p',
  },
  'blockquote-big': {
    element: 'blockquote',
  },
});

const visitListBlockTypes = ['blockquote-big'];

export const VisitDraftJSConfig = (config) => {
  const visitBlockStyleFn = (baseBlockStyleFn) => {
    return (contentBlock) => {
      const type = contentBlock?.getType();
      let r = '';
      if (type) {
        r = baseBlockStyleFn(contentBlock) || '';
        r = r?.length > 0 ? ' ' : r;

        const styles = {
          'align-center': 'align-center',
          callout: 'callout',
          'blockquote-big': 'big',
        };

        r += styles[type] ?? '';
      }

      return r;
    };
  };

  config.settings.richtextEditorSettings = (props) => {
    const { plugins, inlineToolbarButtons } = Plugins(props);
    const { extendedBlockRenderMap, blockStyleFn, listBlockTypes } = Blocks(
      props,
    );
    const visitToolbarButtons = toolbarButtons(props);

    return {
      extendedBlockRenderMap: extendedBlockRenderMap.merge(visitBlockRenderMap),
      blockStyleFn: visitBlockStyleFn(blockStyleFn),
      listBlockTypes: listBlockTypes.concat(visitListBlockTypes),
      richTextEditorPlugins: plugins,
      richTextEditorInlineToolbarButtons: [
        visitToolbarButtons.AlignCenterButton,
        Separator,
        ...inlineToolbarButtons,
        Separator,
        visitToolbarButtons.BlockquoteBigButton,
      ],
      FromHTMLCustomBlockFn,
    };
  };

  config.settings.richtextViewSettings.ToHTMLRenderers = {
    ...config.settings.richtextViewSettings.ToHTMLRenderers,
    inline: {
      ...config.settings.richtextViewSettings.ToHTMLRenderers.inline,
    },
    blocks: {
      ...config.settings.richtextViewSettings.ToHTMLRenderers.blocks,
      ...richTextRenderers.AlignCenterToHtmlRenderer,
      blockquote: (children, { keys }) => (
        <blockquote key={keys[0]}>{addBreaklines(children)}</blockquote>
      ),
      'blockquote-big': (children, { keys }) => {
        return (
          <blockquote className="big" key={keys[0]}>
            {addBreaklines(children)}
          </blockquote>
        );
      },
    },
  };
};
