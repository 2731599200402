import React, { useState, useReducer, useEffect, createRef } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DateRangePicker } from 'react-dates';
import { motion, AnimatePresence } from 'framer-motion';

import {
  Button,
  Pagination,
  Icon as SemanticIcon,
  Input,
  Select,
  Grid,
  Container,
} from 'semantic-ui-react';
import moment from 'moment';

import { Icon } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';
import { getExperiencesResult } from '@package/actions';

import { EventItem } from '@package/components';
import { getExperiencesFilters } from '@package/actions';
import {
  getFilters as _getFilters,
  getFiltersValues,
  //getFiltersStrings,
} from './utils';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const messages = defineMessages({
  experienceSearchText: {
    id: 'experience_search_text',
    defaultMessage: 'Cerca per parola chiave',
  },
  experienceSearchSubject: {
    id: 'experience_search_subject',
    defaultMessage: 'Scegli la categoria',
  },
  experienceSearchPrices: {
    id: 'experience_search_prices',
    defaultMessage: 'Prezzo',
  },
  experienceSearchThematicArea: {
    id: 'experience_search_thematic_area',
    defaultMessage: 'Tema',
  },
  experienceSearchMunicipality: {
    id: 'experience_search_municipality',
    defaultMessage: 'Dove',
  },
  experienceSearchStartDate: {
    id: 'experience_search_startDate',
    defaultMessage: 'Data inizio',
  },
  experienceSearchEndDate: {
    id: 'experience_search_endDate',
    defaultMessage: 'Data fine',
  },
  experienceSearchEvents: {
    id: 'experience_search_experiences',
    defaultMessage: 'Eventi',
  },
  experienceSearchForText: {
    id: 'experience_search_for',
    defaultMessage: 'contenenti',
  },
  experienceSearchInSubject: {
    id: 'experience_search_in',
    defaultMessage: 'in',
  },
  experienceSearchInPrices: {
    id: 'experience_search_in_prices',
    defaultMessage: 'al prezzo',
  },
  experienceSearchInThematicArea: {
    id: 'experience_search_in_thematic_area',
    defaultMessage: 'nei temi',
  },
  experienceSearchInMunicipality: {
    id: 'experience_search_in_municipality',
    defaultMessage: 'a',
  },
  experienceSearchFromDate: {
    id: 'experience_search_fromdate',
    defaultMessage: 'dal',
  },
  experienceSearchToDate: {
    id: 'experience_search_todate',
    defaultMessage: 'al',
  },
  clearText: {
    id: 'clear_text',
    defaultMessage: 'Cancella',
  },
  no_results_found: {
    id: 'no_results_found',
    defaultMessage: 'Nessun risultato',
  },
});

const filtersActions = {
  DATE: 'DATE',
  TEXT: 'TEXT',
  SUBJECT: 'SUBJECT',
  SUBJECT_PRICES: 'SUBJECT_PRICES',
  MUNICIPALITY: 'MUNICIPALITY',
  THEMATIC_AREA: 'THEMATIC_AREA',
  RESET: 'RESET',
};

const DEFAULT_B_SIZE = 8;

const Body = ({ data, isEditMode, onChangeBlock }) => {
  const location = useLocation();
  const pathname = flattenToAppURL(location?.pathname).replace('/edit', '');
  const intl = useIntl();
  const [lastSubrequest, setLastSubrequest] = useState(null);

  moment.locale(intl.locale);
  const dispatch = useDispatch();
  const [b_size, setB_size] = useState(DEFAULT_B_SIZE);
  const [focusedDateInput, setFocusedDateInput] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const initialFiltersState = {
    date: {
      startDate: null,
      endDate: null,
    },
    text: '',
    subject: null,
    municipality: null,
    subject_prices: null,
    thematic_areas: null,
    changedFilters: false, //data.whichEvents != null,
  };

  const filtersReducer = (state = initialFiltersState, action) => {
    let newState = { ...state };

    switch (action.type) {
      case filtersActions.TEXT:
        newState.text = action.value;
        newState.changedFilters = true;
        break;

      case filtersActions.SUBJECT:
        newState.subject = action.value;
        newState.changedFilters = true;
        break;

      case filtersActions.SUBJECT_PRICES:
        newState.subject_prices = action.value;
        newState.changedFilters = true;
        break;

      case filtersActions.THEMATIC_AREA:
        newState.thematic_areas = action.value;
        newState.changedFilters = true;
        break;

      case filtersActions.MUNICIPALITY:
        newState.municipality = action.value;
        newState.changedFilters = true;
        break;

      case filtersActions.DATE:
        newState.date = {
          startDate: action.value.startDate ?? state.startDate,
          endDate: action.value.endDate ?? state.endDate,
        };
        newState.changedFilters = true;
        break;

      case filtersActions.RESET:
        newState = {
          ...initialFiltersState,
        };

        break;

      default:
        return newState;
    }

    return newState;
  };

  const [
    {
      text,
      subject,
      municipality,
      subject_prices,
      thematic_areas,
      date,
      changedFilters,
    },
    dispatchFilter,
  ] = useReducer(filtersReducer, initialFiltersState);

  const querystringResults = useSelector((state) => {
    return state.experiencesResult?.subrequests?.[lastSubrequest];
  });

  const items = useSelector((state) => {
    return state.experiencesResult?.subrequests?.[lastSubrequest]?.items ?? [];
  });

  const loading = useSelector((state) => {
    return (
      state.experiencesResult?.subrequests?.[lastSubrequest]?.loading || false
    );
  });

  //filters
  const filters_vocabulary = useSelector((state) => {
    return state.experiencesFilters || {};
  });

  const getFilters = () => {
    return _getFilters(
      data,
      text,
      subject,
      subject_prices,
      municipality,
      thematic_areas,
      date,
    );
  };

  //get filters
  useEffect(() => {
    if (!filters_vocabulary.loading) {
      dispatch(getExperiencesFilters('/' + intl.locale, getFilters()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.querystring, data, intl.locale]);

  //reload filters on change lang
  useEffect(() => {
    if (!filters_vocabulary.loading && filters_vocabulary.loaded) {
      dispatch(getExperiencesFilters(pathname, getFilters()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl.locale]);

  useEffect(() => {
    if (changedFilters) {
      doRequest(1);
      dispatch(getExperiencesFilters(pathname, getFilters()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    text,
    subject,
    subject_prices,
    municipality,
    thematic_areas,
    date,
    changedFilters,
    b_size,
  ]);

  useEffect(() => {
    //data.b_size for  back compatibility
    setB_size(data.querystring?.b_size ?? data.b_size ?? DEFAULT_B_SIZE);
  }, [data.querystring?.b_size, data.b_size]);

  //------FILTER VALUES------
  let filtersValues = getFiltersValues(data, filters_vocabulary);
  //------------------------

  // //------FILTERS STRINGS------
  // let filtersStrings = getFiltersStrings(
  //   intl,
  //   messages,
  //   filtersValues,
  //   date,
  //   subject,
  //   null /*municipality*/,
  //   subject_prices,
  // );
  // //--------------------------

  const doRequest = (page = currentPage) => {
    const filters = getFilters();
    const subrequestId = new Date().getTime();
    setLastSubrequest(subrequestId);

    dispatch(
      getExperiencesResult(
        pathname,
        {
          fullobjects: 1,
          query: filters,
          b_size: b_size,
        },
        subrequestId,
        page,
      ),
    );
  };

  const resultsRef = createRef();
  let isMobile = false;
  if (__CLIENT__) isMobile = window && window.innerWidth < 992;

  // Se cambiano i criteri impostati dalla sidebar (data.querystring), resetto lo stato dei filtri
  useEffect(() => {
    dispatchFilter({ type: filtersActions.RESET });
  }, [data.querystring, data.prices_filters]);

  const handleQueryPaginationChange = (e, { activePage }) => {
    resultsRef.current.scrollIntoView({ behavior: 'smooth' });
    const current = activePage ?? 1;

    setCurrentPage(current);
    doRequest(current);
  };

  /*
   * close picker when tabbing out
   */
  // for start date input (shift tab, prev)
  useEffect(() => {
    let startDateInput = document.getElementById('start-date-filter');

    if (startDateInput) {
      let removeStartDateListener = startDateInput.addEventListener(
        'keydown',
        (e) => {
          if ((e.key === 'Tab' && e.shiftKey) || e.key === 'Escape')
            setFocusedDateInput(null);
        },
      );

      if (removeStartDateListener) return () => removeStartDateListener();
    }
  }, []);
  // and for  end date input (tab, next)
  useEffect(() => {
    let endDateInput = document.getElementById('end-date-filter');

    if (endDateInput) {
      let removeEndDateListener = endDateInput.addEventListener(
        'keydown',
        (e) => {
          if ((e.key === 'Tab' && !e.shiftKey) || e.key === 'Escape')
            setFocusedDateInput(null);
        },
      );

      if (removeEndDateListener) return () => removeEndDateListener();
    }
  }, []);

  return (
    <div ref={resultsRef}>
      <div className="site-search-filters">
        <div className="site-search-filter-text">
          <Input
            placeholder={intl.formatMessage(messages.experienceSearchText)}
            aria-label={intl.formatMessage(messages.experienceSearchText)}
            value={text}
            className={text?.length > 0 ? 'has-value' : ''}
            icon
            onChange={(e, data) => {
              dispatchFilter({
                type: filtersActions.TEXT,
                value: data.value ?? '',
              });
            }}
          >
            <input />
            {text.length > 0 && (
              <Button
                inverted
                icon
                title={intl.formatMessage(messages.clearText)}
                aria-label={intl.formatMessage(messages.clearText)}
                onClick={() =>
                  dispatchFilter({
                    type: filtersActions.TEXT,
                    value: '',
                  })
                }
              >
                <Icon name="times" />
              </Button>
            )}
          </Input>
        </div>

        <DateRangePicker
          startDate={date.startDate}
          startDateId="start-date-filter"
          startDatePlaceholderText={intl.formatMessage(
            messages.experienceSearchStartDate,
          )}
          endDate={date.endDate}
          endDateId="end-date-filter"
          endDatePlaceholderText={intl.formatMessage(
            messages.experienceSearchEndDate,
          )}
          onDatesChange={({ startDate, endDate }) =>
            dispatchFilter({
              type: filtersActions.DATE,
              value: { startDate, endDate },
            })
          }
          showClearDates
          numberOfMonths={isMobile ? 1 : 2}
          minimumNights={0}
          focusedInput={focusedDateInput}
          onFocusChange={(focusedInput) => setFocusedDateInput(focusedInput)}
          displayFormat="DD/MM/YYYY"
        />

        {filtersValues.municipalities?.length > 0 && (
          <Select
            placeholder={intl.formatMessage(
              messages.experienceSearchMunicipality,
            )}
            aria-label={intl.formatMessage(
              messages.experienceSearchMunicipality,
            )}
            className="site-search-filter-select"
            options={filtersValues.municipalities}
            value={municipality}
            clearable
            onChange={(e, data) => {
              dispatchFilter({
                type: filtersActions.MUNICIPALITY,
                value: data.value,
              });
            }}
          />
        )}

        {filtersValues?.thematic_areas?.length > 0 && (
          <Select
            placeholder={intl.formatMessage(
              messages.experienceSearchThematicArea,
            )}
            aria-label={intl.formatMessage(
              messages.experienceSearchThematicArea,
            )}
            className="site-search-filter-select"
            options={filtersValues.thematic_areas}
            value={thematic_areas}
            clearable
            onChange={(e, data) => {
              dispatchFilter({
                type: filtersActions.THEMATIC_AREA,
                value: data.value?.filter((v) => v.length > 0),
              });
            }}
            multiple={true}
          />
        )}

        {filtersValues?.subjects?.length > 0 && (
          <Select
            placeholder={intl.formatMessage(messages.experienceSearchSubject)}
            aria-label={intl.formatMessage(messages.experienceSearchSubject)}
            className="site-search-filter-select"
            options={filtersValues.subjects}
            value={subject}
            clearable
            onChange={(e, data) => {
              dispatchFilter({
                type: filtersActions.SUBJECT,
                value: data.value?.filter((v) => v.length > 0),
              });
            }}
            multiple={true}
          />
        )}

        {filtersValues?.prices?.length > 0 && (
          <Select
            placeholder={intl.formatMessage(messages.experienceSearchPrices)}
            aria-label={intl.formatMessage(messages.experienceSearchPrices)}
            className="site-search-filter-select"
            options={filtersValues.prices}
            value={subject_prices}
            clearable
            onChange={(e, data) => {
              dispatchFilter({
                type: filtersActions.SUBJECT_PRICES,
                value: data.value?.filter((v) => v.length > 0),
              });
            }}
            multiple={true}
          />
        )}

        {/* <div className="site-search-filter-reset">
          <Button
            className="reset-filters"
            onClick={() => dispatchFilter({ type: filtersActions.RESET })}
          >
            Reset
          </Button>
        </div> */}
      </div>

      {/* <h2>
        <AnimatePresence>
          <motion.span
            key="items-length"
            className="colored"
          >{`${items_total} `}</motion.span>
          <motion.span key="filters-info">
            {`${intl.formatMessage(messages.experienceSearchEvents)}${
              filtersStrings.length > 0 ? ': ' : ''
            }`}
          </motion.span>
          {filtersStrings.length > 0 &&
            filtersStrings.map((filter) => (
              <motion.span
                key={filter}
                initial={{ scale: 0.7, opacity: 0.5 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.7, opacity: 0.5 }}
              >
                {filter}
              </motion.span>
            ))}
        </AnimatePresence>
      </h2> */}

      {/* mostra i risultati solamente dopo che l'utente ha inserito dei filtri (changedFilters) */}

      {loading && changedFilters ? (
        <Container textAlign="center">
          <SemanticIcon loading name="spinner" size="big" />
        </Container>
      ) : (
        <>
          {items?.length === 0 && changedFilters && (
            <h2>
              <AnimatePresence>
                <motion.span key="filters-info">
                  {intl.formatMessage(messages.no_results_found)}
                </motion.span>
              </AnimatePresence>
            </h2>
          )}
          {items?.length > 0 && (
            <>
              <Grid
                stackable
                columns={4}
                verticalAlign="top"
                className="listing"
              >
                <AnimatePresence initial={true}>
                  {items.map((item) => (
                    <motion.div
                      key={item['@id']}
                      className="column"
                      initial={{ scale: 0.7, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.7, opacity: 0 }}
                    >
                      <EventItem
                        item={item}
                        isEditMode={isEditMode}
                        withTags={true}
                        showDates={item['@type'] === 'Event'}
                      />
                    </motion.div>
                  ))}
                </AnimatePresence>
              </Grid>

              {querystringResults.total > b_size && (
                <Container textAlign="center">
                  <Pagination
                    activePage={currentPage}
                    totalPages={Math.ceil(querystringResults.total / b_size)}
                    onPageChange={handleQueryPaginationChange}
                    firstItem={null}
                    lastItem={null}
                    prevItem={{
                      content: <Icon name={paginationLeftSVG} size="18px" />,
                      icon: true,
                      'aria-disabled': !querystringResults.batching.prev,
                      className: !querystringResults.batching.prev
                        ? 'disabled'
                        : null,
                    }}
                    nextItem={{
                      content: <Icon name={paginationRightSVG} size="18px" />,
                      icon: true,
                      'aria-disabled': !querystringResults.batching.next,
                      className: !querystringResults.batching.next
                        ? 'disabled'
                        : null,
                    }}
                  />
                </Container>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
export default Body;
