import ExperienceItem from './ExperienceItem';
import EventItem from './EventItem';
import ItinerarioItem from './ItinerarioItem';

const ItemTemplates = {
  //ContentTypeName: ComponentName,
  Esperienza: ExperienceItem,
  Event: EventItem,
  Itinerario: ItinerarioItem,
};

export default ItemTemplates;
