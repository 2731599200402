/**
 * Edit image block.
 * @module components/Blocks/Download/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Map } from 'immutable';

import { Container, Icon as IconS, Grid } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';

import { SidebarPortal } from '@plone/volto/components';
import { createContent } from '@plone/volto/actions';

import {
  ImageDropzoneWidget,
  ImageFromUrl,
  TextEditorWidget,
} from '@package/components';

import Sidebar from './Sidebar';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Descrizione',
  },
});

const blockTitleRenderMap = Map({
  unstyled: {
    element: 'div',
  },
});

/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    if (!__SERVER__) {
      if (!this.props.data.link) {
        this.props.data.link = {};
      }

      this.state = {
        currentFocused: 'title',
      };
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div className="download-block">
        <ImageDropzoneWidget
          {...this.props}
          onChangeBlock={(name, value) =>
            this.props.onChangeBlock(this.props.block, {
              ...this.props.data,
              [name]: value,
            })
          }
        >
          {this.props.data.url && (
            <Grid stackable stretched verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={8}>
                  <Container className="image">
                    <ImageFromUrl
                      url={this.props.data.url}
                      title={this.props.data.title}
                      size="large"
                    />
                  </Container>
                </Grid.Column>
                <Grid.Column width={4} className="text">
                  <TextEditorWidget
                    data={this.props.data}
                    fieldName="title"
                    selected={this.state.currentFocused === 'title'}
                    block={this.props.block}
                    onChangeBlock={(data) =>
                      this.props.onChangeBlock(this.props.block, data)
                    }
                    placeholder={this.props.intl.formatMessage(messages.title)}
                    showToolbar={false}
                    blockRenderMap={blockTitleRenderMap}
                    blockStyleFn={() => 'title-editor title'}
                    enablePlugins={false}
                    textPlain={true}
                  />

                  <div>
                    <IconS name="download" />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </ImageDropzoneWidget>

        <SidebarPortal selected={this.props.selected}>
          <Sidebar {...this.props} />
        </SidebarPortal>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      request: state.content.create,
      content: state.content.data,
    }),
    { createContent },
  ),
)(Edit);
