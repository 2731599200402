/**
 * Edit text block.
 * @module components/manage/Blocks/Title/Edit
 */

import React from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import { Placeholder } from 'semantic-ui-react';
import ViewIcon from './ViewIcon';
//import { SubblockEdit, DNDSubblocks } from '@package/components'; //da errore se usato così
//import SubblockEdit from '../../common/Subblocks/SubblockEdit';
//import DNDSubblocks from '../../common/Subblocks/DNDSubblocks';
import { DNDSubblocks, SubblockEdit, Subblock } from 'volto-subblocks';

/**
 * Edit text block class.
 * @class Edit
 * @extends Component
 */
class EditIcon extends SubblockEdit {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    if (!__SERVER__) {
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <Subblock subblock={this}>
        {!this.props.data.name && (
          <Placeholder
            style={{ height: 100, width: 100 }}
            className={this.props.selected ? 'selected' : null}
          >
            <Placeholder.Image />
          </Placeholder>
        )}
        <ViewIcon data={this.props.data} />
      </Subblock>
    );
  }
}

export default React.memo(compose(injectIntl, ...DNDSubblocks)(EditIcon));
