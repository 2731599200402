/**
 * Search structures reducers.
 * @module reducers/searchStructures
 */
import { GET_SEARCH_FILTERS_STRUCTURES } from '@package/actions/searchFiltersStructures';

const initialState = {
  error: null,
  items: [],
  loaded: false,
  loading: false,
  subrequests: {},
};

// /**
//  * Get request key
//  * @function getRequestKey
//  * @param {string} actionType Action type.
//  * @returns {string} Request key.
//  */
// function getRequestKey(actionType) {
//   return actionType.split('_')[0].toLowerCase();
// }

/**
 * Structures filters reducer.
 * @function searchFiltersReducer
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */

export default function searchFiltersStrucutresReducer(
  state = initialState,
  action = {},
  subrequests = {},
) {
  // const filtersState = state[action.filters] || {};

  switch (action.type) {
    case `${GET_SEARCH_FILTERS_STRUCTURES}_PENDING`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            ...(state.subrequests[action.subrequest] || {
              data: null,
            }),
            loaded: false,
            loading: true,
            error: null,
          },
        },
      };
    case `${GET_SEARCH_FILTERS_STRUCTURES}_SUCCESS`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            loading: false,
            loaded: true,
            error: null,
            data: action.result,
          },
        },
      };
    case `${GET_SEARCH_FILTERS_STRUCTURES}_FAIL`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            data: null,
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };
    default:
      return state;
  }
}
