/**
 * Search structures reducers.
 * @module reducers/searchStructures
 */
import { omit } from 'lodash';
import { GET_EXPERIENCES_FILTERS } from '@package/actions/experiencesFilters';

const RESET_EXPERIENCES_FILTERS_RESULTS = 'RESET_EXPERIENCES_FILTERS_RESULTS';

const initialState = {
  error: null,
  items: [],
  loaded: false,
  loading: false,
  subrequests: {},
};

// /**
//  * Get request key
//  * @function getRequestKey
//  * @param {string} actionType Action type.
//  * @returns {string} Request key.
//  */
// function getRequestKey(actionType) {
//   return actionType.split('_')[0].toLowerCase();
// }

/**
 * Structures filters reducer.
 * @function searchFiltersReducer
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */

export default function experiencesFiltersReducer(
  state = initialState,
  action = {},
  subrequests = {},
) {
  // const filtersState = state[action.filters] || {};

  switch (action.type) {
    case `${GET_EXPERIENCES_FILTERS}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  data: null,
                }),
                error: null,
                loaded: false,
                loading: true,
              },
            },
          }
        : {
            ...state,
            error: null,
            loading: true,
            loaded: false,
          };

    case `${GET_EXPERIENCES_FILTERS}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                data: action.result,
                error: null,
                loaded: true,
                loading: false,
              },
            },
          }
        : {
            ...state,
            data: action.result,
            error: null,
            loaded: true,
            loading: false,
          };

    case `${GET_EXPERIENCES_FILTERS}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                data: null,
                error: action.error,
                loading: false,
                loaded: false,
              },
            },
          }
        : {
            ...state,
            data: null,
            error: action.error,
            loading: false,
            loaded: false,
          };
    case RESET_EXPERIENCES_FILTERS_RESULTS:
      return action.subrequest
        ? {
            ...state,
            subrequests: omit(state.subrequests, [action.subrequest]),
          }
        : {
            ...state,
            data: null,
            error: null,
            loading: false,
            loaded: false,
          };
    default:
      return state;
  }
}
