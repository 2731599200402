/**
 * ButtonLink block.
 * @module components/Blocks/ButtonLink/ButtonLink
 */

import React from 'react';
import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import rightArrowSVG from './arrow-link.svg';
import { Icon } from 'semantic-ui-react';

/**
 * ButtonLink image block class.
 * @class ButtonLink
 * @extends Component
 */
const ButtonLink = ({
  to,
  title,
  targetBlank,
  showArrow,
  color,
  _className,
  children,
  button = true,
}) => {
  let link = null;
  if (to) {
    let text = <>{title ? title : to}</>;

    link = (
      <UniversalLink
        href={to}
        className={cx(
          button && 'ui button',
          button && 'buttonLink',
          color,
          _className,
        )}
        openLinkInNewTab={targetBlank}
      >
        {text}
        {showArrow && (
           <Icon name="angle right" />
        )}
        {children}
      </UniversalLink>
    );
  }

  return link;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string,
  targetBlank: PropTypes.bool,
  showArrow: PropTypes.bool,
  color: PropTypes.string,
  _className: PropTypes.string,
  button: PropTypes.bool,
};

export default ButtonLink;
