import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Accordion } from 'semantic-ui-react';
import redraft from 'redraft';
import SidebarBlock from './Block/SidebarBlock';
import { Icon } from '@plone/volto/components';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';

import config from '@plone/volto/registry';

const Sidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
  selected,
  setSelected,
}) => {
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="IconsBlocks" defaultMessage="Blocco di icone" />
          :
        </h2>
      </header>

      <Accordion fluid styled className="form">
        {data.subblocks &&
          data.subblocks.map((subblock, index) => (
            <div key={'subblock' + index}>
              <Accordion.Title
                active={selected === index}
                index={index}
                onClick={() => {
                  setSelected(selected === index ? null : index);
                }}
              >
                {subblock.description
                  ? redraft(
                      subblock.description,
                      config.settings.richtextViewSettings.ToHTMLRenderers,
                      config.settings.richtextViewSettings.ToHTMLOptions,
                    )
                  : 'Blocco ' + (index + 1)}
                {selected === index ? (
                  <Icon name={upSVG} size="20px" />
                ) : (
                  <Icon name={downSVG} size="20px" />
                )}
              </Accordion.Title>
              <Accordion.Content active={selected === index}>
                <SidebarBlock
                  subblock={subblock}
                  index={index}
                  onChangeBlock={onChangeBlock}
                  openObjectBrowser={openObjectBrowser}
                />
              </Accordion.Content>
            </div>
          ))}
      </Accordion>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
