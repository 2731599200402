import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import {
  EventWhen,
  PriceRangeIndications,
  LinkMore,
  ListingImage,
} from '@package/components';

const ListTemplate = (data) => {
  const { items, isEditMode } = data;

  return items.length > 0 ? (
    <div className="list-template">
      {items.map((item) => {
        const infos =
          item['@type'] === 'Event' ? (
            <div className="item-info">
              <EventWhen
                start={item.start}
                end={item.end}
                whole_day={item.whole_day}
                open_end={item.open_end}
                show_times={false}
              />
            </div>
          ) : null;
        return (
          <div className="item" key={item['@id']}>
            <div className="image">
              <PriceRangeIndications item={item} />
              <ConditionalLink
                to={flattenToAppURL(item['@id'])}
                condition={!isEditMode}
                aria-hidden="true"
                role="presentation"
                tabIndex="-1"
              >
                <ListingImage item={item} />
              </ConditionalLink>
            </div>
            <div className="content">
              <div className="item-title">
                <ConditionalLink
                  to={flattenToAppURL(item['@id'])}
                  condition={!isEditMode}
                >
                  {infos}
                  {item.title ? item.title : item.id}
                </ConditionalLink>
              </div>
              {item.description && (
                <div className="item-description">{item.description}</div>
              )}
            </div>
          </div>
        );
      })}

      <LinkMore {...data} />
    </div>
  ) : null;
};

ListTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default ListTemplate;
