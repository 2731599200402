import React from 'react';

import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Segment, Form } from 'semantic-ui-react';

import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';

import { CheckboxWidget } from '@plone/volto/components';

//import { ColorListWidget } from '@package/components/';
import { AlignWidget, LinkToWidget } from '@package/components/';

const messages = defineMessages({
  align: {
    id: 'Align',
    defaultMessage: 'Allinea',
  },

  Color: {
    id: 'Color',
    defaultMessage: 'Colore',
  },
  showArrowOnLink: {
    id: 'Show arrow on button',
    defaultMessage: 'Mostra la freccia sul bottone',
  },
});

const Sidebar = ({ data, onChangeBlock, block, intl, openObjectBrowser }) => {
  if (!data.align) {
    data.align = 'center';
  }
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Button" defaultMessage="Bottone" />
        </h2>
      </header>

      <Segment className="form sidebar-button">
        <Form>
          <AlignWidget
            align={data.align}
            onChange={align => {
              onChangeBlock('align', align);
            }}
            alignments={['center', 'left']}
            label={intl.formatMessage(messages.align)}
          />

          <LinkToWidget
            data={data}
            openObjectBrowser={openObjectBrowser}
            onChange={onChangeBlock}
          />

          <CheckboxWidget
            id="showArrowOnLink"
            title={intl.formatMessage(messages.showArrowOnLink)}
            value={data.showArrowOnLink ? data.showArrowOnLink : false}
            onChange={onChangeBlock}
          />

          {/* <ColorListWidget
            id="color"
            title={intl.formatMessage(messages.Color)}
            required={false}
            value={data.color}
            onChange={onChangeBlock}
          /> */}
        </Form>
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  block: PropTypes.string.isRequired,
};

export default compose(withObjectBrowser, injectIntl)(Sidebar);
