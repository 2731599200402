/**
 * NewsletterForm
 * @module components/Blocks/NewsletterBox/NewsletterForm
 */

import React from 'react';

import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { Form } from 'semantic-ui-react';

const messages = defineMessages({
  submit: {
    id: 'Submit',
    defaultMessage: 'Invia',
  },
});

/**
 * NewsletterForm class.
 * @class View
 * @extends Component
 */
const NewsletterForm = ({ data, intl }) => {
  return (
    <Form action={data.actionUrl}>
      <Form.Group>
        <Form.Input
          name={data.fieldName ? data.fieldName : 'mail'}
          placeholder="e-mail"
          aria-labelledby="newsletterBox-title"
        />

        <Form.Button
          type="submit"
          content={intl.formatMessage(messages.submit)}
          size="small"
        />
      </Form.Group>
    </Form>
  );
};

NewsletterForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(NewsletterForm);
