import React, { useState, useReducer, useEffect, createRef } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DateRangePicker } from 'react-dates';
import { motion, AnimatePresence } from 'framer-motion';
import { union } from 'lodash';
import {
  Button,
  Pagination,
  Icon as SemanticIcon,
  Input,
  Select,
  Grid,
  Container,
} from 'semantic-ui-react';
import moment from 'moment';

import { Icon } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';
import { getQueryStringResults } from '@plone/volto/actions';

import { EventItem } from '@package/components';
import { getEventsFilters } from '@package/actions';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const messages = defineMessages({
  eventSearchText: {
    id: 'event_search_text',
    defaultMessage: 'Cerca per parola chiave',
  },
  eventSearchSubject: {
    id: 'event_search_subject',
    defaultMessage: 'Scegli la categoria',
  },
  eventSearchMunicipality: {
    id: 'event_search_municipality',
    defaultMessage: 'Dove',
  },
  eventSearchStartDate: {
    id: 'event_search_startDate',
    defaultMessage: 'Data inizio',
  },
  eventSearchEndDate: {
    id: 'event_search_endDate',
    defaultMessage: 'Data fine',
  },
  eventSearchEvents: {
    id: 'event_search_events',
    defaultMessage: 'Eventi',
  },
  eventSearchForText: {
    id: 'event_search_for',
    defaultMessage: 'contenenti',
  },
  eventSearchInSubject: {
    id: 'event_search_in',
    defaultMessage: 'in',
  },
  eventSearchInMunicipality: {
    id: 'event_search_in_municipality',
    defaultMessage: 'a',
  },
  eventSearchFromDate: {
    id: 'event_search_fromdate',
    defaultMessage: 'dal',
  },
  eventSearchToDate: {
    id: 'event_search_todate',
    defaultMessage: 'al',
  },
  clearText: {
    id: 'clear_text',
    defaultMessage: 'Cancella',
  },
  no_results_found: {
    id: 'no_results_found',
    defaultMessage: 'Nessun risultato',
  },
});

const datesForDisplay = (start, end) => {
  const mStart = moment(start);
  const mEnd = moment(end);
  if (!mStart.isValid() || !mEnd.isValid()) {
    return null;
  }
  const sameYear = mStart.isSame(mEnd, 'year');
  const sameMonth = mStart.isSame(mEnd, 'month');
  const sameDay = mStart.isSame(mEnd, 'day');
  const sameTime = mStart.isSame(mEnd, 'minute');

  return {
    sameYear,
    sameMonth,
    sameDay,
    sameTime,
    mStart,
    mEnd,
  };
};

const filtersActions = {
  DATE: 'DATE',
  TEXT: 'TEXT',
  SUBJECT: 'SUBJECT',
  MUNICIPALITY: 'MUNICIPALITY',
  RESET: 'RESET',
};

const getDefaultStartEndDate = (whichEvents) => {
  let d = { startDate: null, endDate: null };
  switch (whichEvents) {
    case 'today':
      d.startDate = moment().startOf('day');
      d.endDate = moment().endOf('day');
      break;
    case 'weekend':
      const friday = 5;
      const sunday = 7;
      const now_weekday = moment().isoWeekday();
      const nextFriday =
        now_weekday < friday
          ? moment().add(friday - now_weekday, 'days')
          : moment();
      const nextSunday =
        now_weekday < sunday
          ? moment().add(sunday - now_weekday, 'days')
          : moment();
      d.startDate = nextFriday.startOf('day');
      d.endDate = nextSunday.endOf('day');
      break;
    case 'month':
      d.startDate = moment().startOf('month');
      d.endDate = moment().endOf('month');
      break;
    default:
      break;
  }

  return d;
};
const DEFAULT_B_SIZE = 8;

const Body = ({ data, isEditMode, onChangeBlock }) => {
  const location = useLocation();
  const pathname = flattenToAppURL(location?.pathname).replace('/edit', '');
  const intl = useIntl();
  const [lastSubrequest, setLastSubrequest] = useState(null);

  moment.locale(intl.locale);
  const dispatch = useDispatch();
  const [b_size, setB_size] = useState(DEFAULT_B_SIZE);
  const [focusedDateInput, setFocusedDateInput] = useState(null);

  const defaultDates = getDefaultStartEndDate(data.whichEvents);
  const excludeExperiences = data.excludeExperiences;
  const [defaultStartDate, setDefaultStartDate] = useState(
    defaultDates.startDate,
  );
  const [defaultEndDate, setDefaultEndDate] = useState(defaultDates.endDate);

  const initialFiltersState = {
    date: {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
    text: '',
    subject: null,
    municipality: null,
    changedFilters: data.whichEvents != null,
  };

  const filtersReducer = (state = initialFiltersState, action) => {
    let newState = { ...state };

    switch (action.type) {
      case filtersActions.TEXT:
        newState.text = action.value;
        newState.changedFilters = true;
        break;

      case filtersActions.SUBJECT:
        newState.subject = action.value;
        newState.changedFilters = true;
        break;
      case filtersActions.MUNICIPALITY:
        newState.municipality = action.value;
        newState.changedFilters = true;
        break;

      case filtersActions.DATE:
        newState.date = {
          startDate: action.value.startDate ?? state.startDate,
          endDate: action.value.endDate ?? state.endDate,
        };
        newState.changedFilters = true;
        break;

      case filtersActions.RESET:
        newState = {
          ...initialFiltersState,
        };

        break;

      default:
        return newState;
    }

    return newState;
  };

  const [
    { text, subject, municipality, date, changedFilters },
    dispatchFilter,
  ] = useReducer(filtersReducer, initialFiltersState);

  const [currentPage, setCurrentPage] = useState(1);

  const querystringResults = useSelector((state) => {
    return state.querystringsearch?.subrequests?.[lastSubrequest];
  });

  const items = useSelector((state) => {
    return state.querystringsearch?.subrequests?.[lastSubrequest]?.items ?? [];
  });

  const loading = useSelector((state) => {
    return (
      state.querystringsearch?.subrequests?.[lastSubrequest]?.loading || false
    );
  });

  //filters
  const filters_vocabulary = useSelector((state) => {
    return state.eventsFilters || {};
  });

  //get filters
  useEffect(() => {
    if (!filters_vocabulary.loading) {
      dispatch(getEventsFilters(pathname, getFilters()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultStartDate, defaultEndDate, data.querystring, data]);

  //reload filters on change lang
  useEffect(() => {
    if (!filters_vocabulary.loading && defaultStartDate && defaultEndDate) {
      dispatch(getEventsFilters(pathname, getFilters()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl.locale]);

  useEffect(() => {
    if (changedFilters) {
      doRequest(1);
      dispatch(getEventsFilters(pathname, getFilters()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    text,
    subject,
    municipality,
    date,
    changedFilters,
    b_size,
    data.querystring?.sort_on,
    data.querystring?.sort_order,
  ]);

  useEffect(() => {
    //data.b_size for  back compatibility
    setB_size(data.querystring?.b_size ?? data.b_size ?? DEFAULT_B_SIZE);
  }, [data.querystring?.b_size, data.b_size]);

  const subjects = filters_vocabulary?.data?.Subject?.filter(
    (subj) => !subj.startsWith('APP'),
  ).map((subj) => ({
    key: subj,
    text: subj,
    value: subj,
  }));

  const municipalities = filters_vocabulary?.data?.municipality?.map(
    (municipality) => ({
      key: municipality.value,
      text: municipality.label,
      value: municipality.value,
    }),
  );

  //filters string
  let filtersStrings = [];

  if (date.startDate && date.endDate) {
    const { sameYear, sameMonth, mStart, mEnd } = datesForDisplay(
      date.startDate,
      date.endDate,
    );

    filtersStrings.push(
      ` ${intl.formatMessage(messages.eventSearchFromDate)} ${mStart.format(
        sameMonth && sameYear ? 'D' : sameYear ? 'D MMMM' : 'LL',
      )} ${intl.formatMessage(messages.eventSearchToDate)} ${mEnd.format(
        'LL',
      )}`,
    );
  }

  if (subject) {
    filtersStrings.push(
      ` ${intl.formatMessage(messages.eventSearchInSubject)} '${subject}'`,
    );
  }

  if (municipality && municipalities) {
    let m = municipalities.filter((mun) => {
      if (mun.key === municipality) {
        return true;
      }
      return false;
    })[0];

    filtersStrings.push(
      ` ${intl.formatMessage(messages.eventSearchInMunicipality)} '${m.text}'`,
    );
  }

  const getQueryFilter = (query, i, o) => {
    let ret = null;
    query.forEach((f) => {
      if (i && f.i === i && o && o === f.o) {
        ret = f;
      }
    });
    return ret;
  };

  const getFilters = () => {
    const date_fmt = 'YYYY-MM-DD HH:mm';

    const defaultQuery = data.querystring?.query ?? [];

    let filters = JSON.parse(JSON.stringify(defaultQuery));

    if (
      !getQueryFilter(
        filters,
        'portal_type',
        'plone.app.querystring.operation.selection.any',
      )
    ) {
      filters.push({
        i: 'portal_type',
        o: 'plone.app.querystring.operation.selection.any',
        v: ['Event'],
      });
    }

    if (text && text.length) {
      filters.push({
        i: 'SearchableText',
        o: 'plone.app.querystring.operation.string.contains',
        v: text + '*',
      });
    }

    if (excludeExperiences) {
      filters.push({
        i: 'is_experience',
        o: 'plone.app.querystring.operation.boolean.isFalse',
        v: true,
      });
    }

    if (subject) {
      let defaultSubjectCondition = getQueryFilter(
        filters,
        'Subject',
        'plone.app.querystring.operation.selection.all',
      );

      if (defaultSubjectCondition) {
        defaultSubjectCondition.v = union(defaultSubjectCondition.v, [subject]);
      } else {
        filters.push({
          i: 'Subject',
          o: 'plone.app.querystring.operation.selection.all',
          v: [subject],
        });
      }
    }

    if (municipality) {
      let defaultMunicipalityCondition = getQueryFilter(
        filters,
        'municipality',
        'plone.app.querystring.operation.selection.any',
      );

      if (defaultMunicipalityCondition) {
        defaultMunicipalityCondition.v = union(defaultMunicipalityCondition.v, [
          municipality,
        ]);
      } else {
        filters.push({
          i: 'municipality',
          o: 'plone.app.querystring.operation.selection.any',
          v: [municipality],
        });
      }
    }

    if (date.startDate) {
      filters.push({
        i: 'start',
        o: 'plone.app.querystring.operation.date.largerThan',
        v: date.startDate.startOf('day')?.format(date_fmt),
      });
    }

    if (date.endDate) {
      filters.push({
        i: 'end',
        o: 'plone.app.querystring.operation.date.lessThan',
        v: date.endDate.endOf('day')?.format(date_fmt),
      });
    }

    return filters;
  };

  const doRequest = (page = currentPage) => {
    const filters = getFilters();
    const subrequestId = new Date().getTime();
    setLastSubrequest(subrequestId);

    dispatch(
      getQueryStringResults(
        pathname,
        {
          fullobjects: 1,
          query: filters,
          b_size: b_size,
          sort_on: data.querystring.sort_on ?? undefined,
          sort_order: data.querystring.sort_order ?? undefined,
        },
        subrequestId,
        page,
      ),
    );
  };

  const resultsRef = createRef();
  let isMobile = false;
  if (__CLIENT__) isMobile = window && window.innerWidth < 992;

  // Se cambia il tipo di wichEvents o i criteri impostati dalla sidebar (data.querystring), resetto lo stato dei filtri
  useEffect(() => {
    let defaults = getDefaultStartEndDate(data.whichEvents);
    setDefaultStartDate(defaults.startDate);
    setDefaultEndDate(defaults.endDate);

    dispatchFilter({ type: filtersActions.RESET });
  }, [data.whichEvents, data.querystring]);

  const handleQueryPaginationChange = (e, { activePage }) => {
    resultsRef.current.scrollIntoView({ behavior: 'smooth' });
    const current = activePage ?? 1;

    setCurrentPage(current);
    doRequest(current);
  };

  /*
   * close picker when tabbing out
   */
  // for start date input (shift tab, prev)
  useEffect(() => {
    let startDateInput = document.getElementById('start-date-filter');

    if (startDateInput) {
      let removeStartDateListener = startDateInput.addEventListener(
        'keydown',
        (e) => {
          if ((e.key === 'Tab' && e.shiftKey) || e.key === 'Escape')
            setFocusedDateInput(null);
        },
      );

      if (removeStartDateListener) return () => removeStartDateListener();
    }
  }, []);
  // and for  end date input (tab, next)
  useEffect(() => {
    let endDateInput = document.getElementById('end-date-filter');

    if (endDateInput) {
      let removeEndDateListener = endDateInput.addEventListener(
        'keydown',
        (e) => {
          if ((e.key === 'Tab' && !e.shiftKey) || e.key === 'Escape')
            setFocusedDateInput(null);
        },
      );

      if (removeEndDateListener) return () => removeEndDateListener();
    }
  }, []);

  return (
    <div ref={resultsRef}>
      <div className="site-search-filters">
        <div className="site-search-filter-text">
          <Input
            placeholder={intl.formatMessage(messages.eventSearchText)}
            aria-label={intl.formatMessage(messages.eventSearchText)}
            value={text}
            className={text?.length > 0 ? 'has-value' : ''}
            icon
            onChange={(e, data) => {
              dispatchFilter({
                type: filtersActions.TEXT,
                value: data.value ?? '',
              });
            }}
          >
            <input />
            {text.length > 0 && (
              <Button
                inverted
                icon
                title={intl.formatMessage(messages.clearText)}
                aria-label={intl.formatMessage(messages.clearText)}
                onClick={() =>
                  dispatchFilter({
                    type: filtersActions.TEXT,
                    value: '',
                  })
                }
              >
                <Icon name="times" />
              </Button>
            )}
          </Input>
        </div>

        <DateRangePicker
          startDate={date.startDate}
          startDateId="start-date-filter"
          startDatePlaceholderText={intl.formatMessage(
            messages.eventSearchStartDate,
          )}
          endDate={date.endDate}
          endDateId="end-date-filter"
          endDatePlaceholderText={intl.formatMessage(
            messages.eventSearchEndDate,
          )}
          onDatesChange={({ startDate, endDate }) =>
            dispatchFilter({
              type: filtersActions.DATE,
              value: { startDate, endDate },
            })
          }
          showClearDates
          numberOfMonths={isMobile ? 1 : 2}
          minimumNights={0}
          focusedInput={focusedDateInput}
          onFocusChange={(focusedInput) => setFocusedDateInput(focusedInput)}
          displayFormat="DD/MM/YYYY"
        />

        {municipalities?.length > 0 && (
          <Select
            placeholder={intl.formatMessage(messages.eventSearchMunicipality)}
            aria-label={intl.formatMessage(messages.eventSearchMunicipality)}
            className="site-search-filter-select"
            options={municipalities}
            value={municipality}
            clearable
            onChange={(e, data) => {
              dispatchFilter({
                type: filtersActions.MUNICIPALITY,
                value: data.value,
              });
            }}
          />
        )}
        {subjects?.length > 0 && (
          <Select
            placeholder={intl.formatMessage(messages.eventSearchSubject)}
            aria-label={intl.formatMessage(messages.eventSearchSubject)}
            className="site-search-filter-select"
            options={subjects}
            value={subject}
            clearable
            onChange={(e, data) => {
              dispatchFilter({
                type: filtersActions.SUBJECT,
                value: data.value,
              });
            }}
          />
        )}

        {/* <div className="site-search-filter-reset">
          <Button
            className="reset-filters"
            onClick={() => dispatchFilter({ type: filtersActions.RESET })}
          >
            Reset
          </Button>
        </div> */}
      </div>

      {/* <h2>
        <AnimatePresence>
          <motion.span
            key="items-length"
            className="colored"
          >{`${items_total} `}</motion.span>
          <motion.span key="filters-info">
            {`${intl.formatMessage(messages.eventSearchEvents)}${
              filtersStrings.length > 0 ? ': ' : ''
            }`}
          </motion.span>
          {filtersStrings.length > 0 &&
            filtersStrings.map((filter) => (
              <motion.span
                key={filter}
                initial={{ scale: 0.7, opacity: 0.5 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.7, opacity: 0.5 }}
              >
                {filter}
              </motion.span>
            ))}
        </AnimatePresence>
      </h2> */}

      {/* mostra i risultati solamente dopo che l'utente ha inserito dei filtri (changedFilters) */}

      {loading && changedFilters ? (
        <Container textAlign="center">
          <SemanticIcon loading name="spinner" size="big" />
        </Container>
      ) : (
        <>
          {items?.length === 0 && changedFilters && (
            <h2>
              <AnimatePresence>
                <motion.span key="filters-info">
                  {intl.formatMessage(messages.no_results_found)}
                </motion.span>
              </AnimatePresence>
            </h2>
          )}
          {items?.length > 0 && (
            <>
              <Grid
                stackable
                columns={4}
                verticalAlign="top"
                className="listing"
              >
                <AnimatePresence initial={true}>
                  {items.map((item) => (
                    <motion.div
                      key={item['@id']}
                      className="column"
                      initial={{ scale: 0.7, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.7, opacity: 0 }}
                    >
                      <EventItem
                        item={item}
                        isEditMode={isEditMode}
                        withTags={true}
                      />
                    </motion.div>
                  ))}
                </AnimatePresence>
              </Grid>

              {querystringResults.total > b_size && (
                <Container textAlign="center">
                  <Pagination
                    activePage={currentPage}
                    totalPages={Math.ceil(querystringResults.total / b_size)}
                    onPageChange={handleQueryPaginationChange}
                    firstItem={null}
                    lastItem={null}
                    prevItem={{
                      content: <Icon name={paginationLeftSVG} size="18px" />,
                      icon: true,
                      'aria-disabled': !querystringResults.batching.prev,
                      className: !querystringResults.batching.prev
                        ? 'disabled'
                        : null,
                    }}
                    nextItem={{
                      content: <Icon name={paginationRightSVG} size="18px" />,
                      icon: true,
                      'aria-disabled': !querystringResults.batching.next,
                      className: !querystringResults.batching.next
                        ? 'disabled'
                        : null,
                    }}
                  />
                </Container>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
export default Body;
