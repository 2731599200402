import React from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';

const CitazioneStoria = ({ storia }) => {
  return storia?.quote?.length > 0 ? (
    <div className="citazione-storia">
      <span className="border-top"></span>
      <span className="border-bottom"></span>

      <div className="citazione">
        {storia.quote_author_image && (
          <div className="image">
            <figure>
              <img
                src={flattenToAppURL(
                  storia.quote_author_image.scales.preview.download,
                )}
                alt={storia.quote_author}
                loading="lazy"
              />
            </figure>
          </div>
        )}

        <div className="quote">{storia.quote}</div>
      </div>

      {(storia.quote_author || storia.quote_author_description) && (
        <div className="author">
          {storia.quote_author && (
            <div className="author-name">{storia.quote_author}</div>
          )}
          {storia.quote_author_description && (
            <div className="author-description">
              {storia.quote_author_description}
            </div>
          )}
        </div>
      )}
    </div>
  ) : null;
};
export default CitazioneStoria;
