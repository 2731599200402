import {
  ExperienceView,
  ItinerarioView,
  StrutturaRicettivaView,
  RistoranteView,
  SchedaTematicaView,
  GiornataItinerarioView,
  StoriaView,
} from '@package/components';

export const VisitViews = (config) => {
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      Esperienza: ExperienceView,
      Itinerario: ItinerarioView,
      StrutturaRicettiva: StrutturaRicettivaView,
      Ristorante: RistoranteView,
      SchedaTematica: SchedaTematicaView,
      GiornataItinerario: GiornataItinerarioView,
      Storia: StoriaView,
    },
  };
};
