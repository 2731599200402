/**
 * Edit image block.
 * @module components/Blocks/FullImage/Edit
 */

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Map } from 'immutable';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';

import {
  withDNDContext,
  SubblocksEdit,
  SubblocksWrapper,
} from 'volto-subblocks';

import { Grid } from 'semantic-ui-react';

import { SidebarPortal } from '@plone/volto/components';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';
import { createContent } from '@plone/volto/actions';
import { EditTextBlock } from '@plone/volto/components';

import Sidebar from './Sidebar';
import EditIcon from './Icon/EditIcon';
import { ButtonLink, Box, TextEditorWidget } from '@package/components';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Descrizione',
  },
  addIcon: {
    id: 'Add icon',
    defaultMessage: "Aggiungi un'icona",
  },
});

const blockTitleRenderMap = Map({
  unstyled: {
    element: 'div',
  },
});

/**
 * Edit cta box class.
 * @class Edit
 * @extends Component
 */
class EditCTABox extends SubblocksEdit {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    if (!__SERVER__) {
      if (!props.data.iconsPosition) {
        this.props.onChangeBlock(this.props.block, {
          ...this.props.data,
          iconsPosition: 'bottom', //default
        });
      }

      this.state = {
        ...this.state,
        currentFocused: 'title',
      };
    }
  }

  /**
   * Change Title handler
   * @method onChangeTitle
   * @param {object} titleEditorState Editor state.
   * @returns {undefined}
   */
  onChangeTitle = (titleEditorState) => {
    this.setState({ titleEditorState }, () => {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        title: titleEditorState.getCurrentContent().getPlainText(),
      });
    });
  };

  /**
   * Change Description handler
   * @method onChangeDescription
   * @param {object} descriptionEditorState Editor state.
   * @returns {undefined}
   */
  onChangeDescription = (block, value) => {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      description: value,
    });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    const iconsPosition = this.props.data.iconsPosition ?? 'bottom';

    return (
      <SubblocksWrapper node={this.node}>
        <Box className={cx('ctaBox', 'icons-' + iconsPosition)}>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={iconsPosition === 'bottom' ? 12 : 7}>
                <TextEditorWidget
                  data={this.props.data}
                  fieldName="title"
                  selected={this.state.currentFocused === 'title'}
                  block={this.props.block}
                  blockStyleFn={() => 'text-editor'}
                  blockRenderMap={blockTitleRenderMap}
                  disableMoveToNearest={true}
                  onChangeBlock={(data) =>
                    this.props.onChangeBlock(this.props.block, data)
                  }
                  placeholder={this.props.intl.formatMessage(messages.title)}
                  showToolbar={false}
                  textPlain={true}
                  prevFocus={() => {
                    this.props.onFocusPreviousBlock(
                      this.props.block,
                      this.props.blockNode.current,
                    );
                  }}
                  nextFocus={() => {
                    this.setState({
                      currentFocused: 'description',
                    });
                  }}
                />

                <div className="description">
                  <EditTextBlock
                    {...this.props}
                    selected={this.state.currentFocused === 'description'}
                    detached={true}
                    data={
                      this.props.data.description
                        ? this.props.data.description
                        : {}
                    }
                    onChangeBlock={(block, value) =>
                      this.onChangeDescription(block, value)
                    }
                  />
                </div>
              </Grid.Column>

              {/*------ column icons -----*/}
              <Grid.Column
                width={this.props.data.iconsPosition === 'bottom' ? 12 : 5}
                verticalAlign="middle"
              >
                <Grid
                  className="icons subblocks"
                  stackable
                  columns="equal"
                  verticalAlign="middle"
                  centered
                >
                  <Grid.Row>
                    {this.state.subblocks.map((subblock, subindex) => (
                      <Grid.Column
                        key={subblock.id}
                        width={
                          this.props.data.iconsPosition === 'bottom'
                            ? this.state.subblocks.length > 1
                              ? 2
                              : 10
                            : this.state.subblocks.length > 1
                            ? 4
                            : 10
                        }
                      >
                        <EditIcon
                          data={subblock}
                          index={subindex}
                          selected={this.isSubblockSelected(subindex)}
                          {...this.subblockProps}
                          openObjectBrowser={this.props.openObjectBrowser}
                        />
                      </Grid.Column>
                    ))}
                  </Grid.Row>

                  {this.props.selected && (
                    <Grid.Row>
                      <Grid.Column textAlign="center">
                        {this.renderAddBlockButton(
                          this.props.intl.formatMessage(messages.addIcon),
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              </Grid.Column>
            </Grid.Row>
            {this.props.data.href && (
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <ButtonLink
                    to={'#'}
                    title={this.props.data.linkTitle}
                    targetBlank={this.props.data.openLinkInNewTab}
                    color="red"
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Box>
        <SidebarPortal selected={this.props.selected}>
          <Sidebar
            {...this.props}
            onChangeSubblocks={this.onChangeSubblocks}
            selected={this.state.subIndexSelected}
            setSelected={this.onSubblockChangeFocus}
          />
        </SidebarPortal>
      </SubblocksWrapper>
    );
  }
}

export default compose(
  injectIntl,
  withObjectBrowser,
  withDNDContext,
  connect(
    (state) => ({
      request: state.content.create,
      content: state.content.data,
    }),
    { createContent },
  ),
)(EditCTABox);
