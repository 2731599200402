export const GET_EVENTS_FILTERS = 'GET_EVENTS_FILTERS';

export function getEventsFilters(path = '', query, subrequest) {
  return {
    type: GET_EVENTS_FILTERS,
    subrequest,
    request: {
      op: 'post',
      path: `${path}/@search-filters-events`,
      data: {
        query,
      },
    },
  };
}
