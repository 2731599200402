export const GET_SEARCH_FILTERS_STRUCTURES = 'GET_SEARCH_FILTERS_STRUCTURES';

export function getSearchFiltersStructures(portal_type) {
  return {
    type: GET_SEARCH_FILTERS_STRUCTURES,
    subrequest: portal_type,
    request: {
      op: 'get',
      path: `/@search-filters-structures?portal_type=${portal_type}`,
    },
  };
}
