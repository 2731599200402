export const GET_SITEMAP_SETTINGS = 'GET_SITEMAP_SETTINGS';

/**
 * Get sitemap settings
 * @function getSitemapSettings
 * @returns {Object} Get sitemap settings action.
 */
export function getSitemapSettings() {
  return {
    type: GET_SITEMAP_SETTINGS,
    request: {
      op: 'get',
      path: '/@sitemap-settings',
    },
  };
}
