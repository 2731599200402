/**
 * Edit image block.
 * @module components/Blocks/SectionLinks/Edit
 */

import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { Container, Grid } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';

import { SidebarPortal } from '@plone/volto/components';
import { createContent } from '@plone/volto/actions';

import {
  ImageDropzoneWidget,
  ImageFromUrl,
  TextEditorWidget,
} from '@package/components';

import { SubblocksEdit, SubblocksWrapper } from 'volto-subblocks';

import Sidebar from './Sidebar';
import EditBlock from './Block/EditBlock';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Descrizione',
  },
  goToSection: {
    id: 'Go to section',
    defaultMessage: 'Vai alla sezione',
  },
  addLink: {
    id: 'Add link',
    defaultMessage: 'Aggiungi link',
  },
});

/**
 * Edit image block class.
 * @class Edit
 * @extends SubblocksEdit
 */
class Edit extends SubblocksEdit {
  componentDidMount() {
    super.componentDidMount();
    if (this.props.data.subblocks?.length === 0) {
      [0, 1, 2].map((i) => {
        this.addSubblock();
        return i;
      });
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <SubblocksWrapper
        node={this.node}
        className={`section-links-block ${this.props.data.backgroundColor}`}
      >
        <Container>
          <Grid stackable>
            <Grid.Column width={3}>
              <ImageDropzoneWidget
                {...this.props}
                smallToolbar={true}
                onChangeBlock={(name, value) =>
                  this.props.onChangeBlock(this.props.block, {
                    ...this.props.data,
                    [name]: value,
                  })
                }
              >
                {this.props.data.url && (
                  <Container className="image">
                    <ImageFromUrl url={this.props.data.url} size="preview" />
                  </Container>
                )}
              </ImageDropzoneWidget>
            </Grid.Column>
            <Grid.Column width={9}>
              <h2>
                <TextEditorWidget
                  data={this.props.data}
                  fieldName="title"
                  selected={true}
                  block={this.props.block}
                  onChangeBlock={(data) =>
                    this.props.onChangeBlock(this.props.block, data)
                  }
                  placeholder={this.props.intl.formatMessage(messages.title)}
                  showToolbar={false}
                />
              </h2>
              <div className="description">
                <TextEditorWidget
                  data={this.props.data}
                  fieldName="description"
                  selected={false}
                  block={this.props.block}
                  onChangeBlock={(data) =>
                    this.props.onChangeBlock(this.props.block, data)
                  }
                  placeholder={this.props.intl.formatMessage(
                    messages.description,
                  )}
                />
              </div>
              <Grid className="links subblocks" columns={2} stackable>
                {this.state.subblocks.map((subblock, subindex) => (
                  <Grid.Column key={'subblock_' + subindex}>
                    <EditBlock
                      data={subblock}
                      index={subindex}
                      selected={this.isSubblockSelected(subindex)}
                      {...this.subblockProps}
                      openObjectBrowser={this.props.openObjectBrowser}
                    />
                  </Grid.Column>
                ))}
              </Grid>
              {this.renderAddBlockButton(
                this.props.intl.formatMessage(messages.addLink),
              )}
            </Grid.Column>
          </Grid>
          {this.props.data.href && (
            <div className="footer">
              <span className="link">
                {this.props.intl.formatMessage(messages.goToSection)}
              </span>
            </div>
          )}

          <SidebarPortal selected={this.props.selected}>
            <Sidebar
              {...this.props}
              onChangeSubblocks={this.onChangeSubblocks}
              selected={this.state.subIndexSelected}
              setSelected={this.onSubblockChangeFocus}
            />
          </SidebarPortal>
        </Container>
      </SubblocksWrapper>
    );
  }
}

export default React.memo(
  compose(
    injectIntl,
    connect(
      (state) => ({
        request: state.content.create,
        content: state.content.data,
      }),
      { createContent },
    ),
  )(Edit),
);
