import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import { Helmet, flattenHTMLToAppURL } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';

import {
  TypeLabel,
  ViewTitle,
  Ticket,
  Services,
  Prices,
  Location,
  ContactsInfos,
  Gallery,
  //RelatedItems,
} from '@package/components';

//import ViewCTABox from '@package/components/Blocks/CtaBox/View';

const GallerySettings = {
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const RistoranteView = ({ content, location }) => {
  let type = '';
  if (content.restaurant_type) {
    content.restaurant_type.forEach((r_type, i) => {
      type +=
        r_type.title + (i < content.restaurant_type.length - 1 ? ', ' : '');
    });
  }
  return (
    <Container className="ristorante-view">
      <Helmet title={content.title} />
      <article id="content">
        <Grid stackable columns={2} relaxed="very">
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <header>
              <TypeLabel type={content['@type']} />
              <ViewTitle
                title={content.title}
                description={type}
                url={location?.pathname}
                content={content}
              />
            </header>

            {content.description && (
              <section id="description">{content.description}</section>
            )}

            {(content.cuisine_type || content.cuisine_detail) && (
              <section className="cuisine">
                <h3>
                  <FormattedMessage id="Cuisine" defaultMessage="Cucina">
                    {(message) => message}
                  </FormattedMessage>
                </h3>
                <div className="cuisine-type">
                  {content.cuisine_type &&
                    content.cuisine_type.map((type, index) => (
                      <span>
                        {type.title}
                        {index < content.cuisine_type.length - 1 ? ', ' : ''}
                      </span>
                    ))}
                </div>

                {content.cuisine_detail && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: flattenHTMLToAppURL(content.cuisine_detail.data),
                    }}
                  />
                )}
              </section>
            )}

            {/* <section id="content-core">
              {content.text && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: flattenHTMLToAppURL(content.text.data),
                  }}
                />
              )}
            </section> */}

            {/* <section className="more-infos">
              <h3>
                <FormattedMessage
                  id="More infos"
                  defaultMessage="Altre informazioni"
                />
              </h3>
              {content.inside_seats && (
                <div className="info">
                  <div className="info-title">
                    <FormattedMessage
                      id="Inside seats"
                      defaultMessage="Posti al coperto"
                    />
                    :
                  </div>
                  <div>{content.inside_seats}</div>
                </div>
              )}

              {content.outside_seats && (
                <div className="info">
                  <div className="info-title">
                    <FormattedMessage
                      id="Outside seats"
                      defaultMessage="Posti all'aperto"
                    />
                    :
                  </div>
                  <div>{content.outside_seats}</div>
                </div>
              )}

              {content.available_rooms && (
                <div className="info">
                  <div className="info-title">
                    <FormattedMessage
                      id="Available rooms"
                      defaultMessage="Sale disponibili"
                    />
                    :
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: flattenHTMLToAppURL(content.available_rooms.data),
                    }}
                  />
                </div>
              )}
              {content.accepted_credit_cards && (
                <div className="info">
                  <div className="info-title">
                    <FormattedMessage
                      id="Accepted credit cards"
                      defaultMessage="Carte di credito accettate"
                    />
                    :
                  </div>
                  <div>
                    {content.accepted_credit_cards.map((card, i) => (
                      <span>
                        {card}
                        {i < content.accepted_credit_cards.length - 1 && ', '}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </section> */}

            {/* {content.booking_button_url && (
              <section className="booking">
                <ButtonLink
                  to={content.booking_button_url}
                  title={
                    content.booking_button_label || (
                      <FormattedMessage
                        id="PrenotaIlTuoSoggiorno"
                        defaultMessage="Prenota il tuo soggiorno"
                      >
                        {(message) => message}
                      </FormattedMessage>
                    )
                  }
                  targetBlank={true}
                  color="red"
                  showArrow={false}
                  _className="fluid"
                />
              </section>
            )} */}

            {/* {content.call_to_action && (
              <ViewCTABox data={content.call_to_action} />
            )} */}
          </Grid.Column>

          <Grid.Column mobile={16} tablet={4} computer={4}>
            <aside className="right-column">
              <Prices prices={content.prices} />

              <Ticket content={content} />

              <ContactsInfos content={content} />

              {content.price_range && (
                <section className="info-box no-bg center price_range">
                  <div className="info-box-header">
                    <FormattedMessage
                      id="Price range"
                      defaultMessage="Facia di prezzo"
                    />
                  </div>
                  {content.price_range.title}
                </section>
              )}

              <Location content={content} />

              {/* {content.how_to_reach && (
                <section className="info-box no-bg center how_to_reach">
                  <div className="info-box-header">
                    <FormattedMessage
                      id="How to reach"
                      defaultMessage="Come arrivare"
                    >
                      {(message) => message}
                    </FormattedMessage>
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: flattenHTMLToAppURL(content.how_to_reach.data),
                    }}
                  />
                </section>
              )} */}
            </aside>
          </Grid.Column>
        </Grid>
        <section className="images">
          {content.items && (
            <Gallery
              items={content.items}
              customSettings={GallerySettings}
              imageScale="large"
            />
          )}
        </section>

        <Services services={content.services} columns={3} />

        {/* {content.additional_infos && (
          <section className="additional_infos">
            <h3>
              <FormattedMessage
                id="Additional infos"
                defaultMessage="Informazioni aggiuntive"
              >
                {(message) => message}
              </FormattedMessage>
            </h3>

            <div
              dangerouslySetInnerHTML={{
                __html: flattenHTMLToAppURL(content.additional_infos.data),
              }}
            />
          </section>
        )} */}

        {/* {content.around_here_map && (
          <section className="around">
            <h3>
              <FormattedMessage
                id="Discover around the hotel"
                defaultMessage="Scopri i dintorni dell'hotel"
              >
                {(message) => message}
              </FormattedMessage>
            </h3>

            <div
              dangerouslySetInnerHTML={{
                __html: content.around_here_map,
              }}
            />
          </section>
        )} */}

        {/* {content.related_events?.length > 0 && (
          <FormattedMessage
            id="Ristorante_relatedEvents"
            defaultMessage="Eventi per completare il tuo soggiorno"
          >
            {(message) => (
              <RelatedItems items={content.related_events} title={message} />
            )}
          </FormattedMessage>
        )} */}
      </article>
    </Container>
  );
};
RistoranteView.propTypes = {
  content: PropTypes.shape({
    '@type': PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    text_body: PropTypes.shape({
      data: PropTypes.string,
    }),
    duration: PropTypes.string,
    needs_booking: PropTypes.bool,
    target_audience: PropTypes.shape({
      title: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        image_caption: PropTypes.string,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default RistoranteView;
