import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { TextWidget, Icon } from '@plone/volto/components';
import { ImageFromUrl } from '@package/components';
import { Segment } from 'semantic-ui-react';
import imageSVG from '@plone/volto/icons/image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';

import config from '@plone/volto/registry';

const messages = defineMessages({
  Image: {
    id: 'Image',
    defaultMessage: 'Immagine',
  },
  Origin: {
    id: 'Origin',
    defaultMessage: 'Origine',
  },
  NoImageSelected: {
    id: 'No image selected',
    defaultMessage: 'Nessuna immagine selezionata',
  },
  imageURL: {
    id: 'Image URL',
    defaultMessage: 'URL immagine',
  },
});

class ImageSidebarWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.object.isRequired,
    filed: PropTypes.string,
    titleFiled: PropTypes.string,
    onChange: PropTypes.func,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    field: 'url',
    titleField: 'title',
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      data,
      field,
      titleField,
      onChange,
      openObjectBrowser,
      intl,
    } = this.props;

    let title = data[titleField] ?? 'image';

    return (
      <>
        <header className="header pulled">
          <h2>{intl.formatMessage(messages.Image)}</h2>
        </header>
        {!data[field] && (
          <>
            <Segment className="sidebar-metadata-container">
              {intl.formatMessage(messages.NoImageSelected)}
              <Icon name={imageSVG} size="100px" color="#b8c6c8" />
            </Segment>
          </>
        )}
        {data[field] && (
          <>
            <Segment className="sidebar-metadata-container">
              {data[field].split('/').slice(-1)[0]}
              <ImageFromUrl url={data[field]} title={title} size="mini" />
            </Segment>
            <Segment className="form sidebar-image-data">
              {data[field].includes(config.settings.apiPath) && (
                <TextWidget
                  id="Origin"
                  title={intl.formatMessage(messages.Origin)}
                  required={false}
                  value={data[field].split('/').slice(-1)[0]}
                  icon={navTreeSVG}
                  iconAction={() => openObjectBrowser()}
                  onChange={() => {}}
                />
              )}
              {!data[field].includes(config.settings.apiPath) && (
                <TextWidget
                  id="external"
                  title={intl.formatMessage(messages.imageURL)}
                  required={false}
                  value={data[field]}
                  icon={clearSVG}
                  iconAction={() =>
                    onChange({
                      ...data,
                      [field]: '',
                    })
                  }
                  onChange={() => {}}
                />
              )}
            </Segment>
          </>
        )}
      </>
    );
  }
}

export default injectIntl(ImageSidebarWidget);
