import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import { getBounds } from 'geolib';

import MapMarker from './MapMarker';
import { ConditionalEmbed } from 'volto-gdpr-privacy';

const GoogleMap = ({
  height = '300px',
  showTitle = false,
  showPrice = false,
  showPopup = true,
  addMarkerLink = false,
  venues,
  mainMarker = -1,
  defaultZoom = 14,
}) => {
  const RAZZLE_GMAPS_API_KEY = __CLIENT__
    ? window?.env?.RAZZLE_GMAPS_API_KEY || process.env.RAZZLE_GMAPS_API_KEY
    : process.env.RAZZLE_GMAPS_API_KEY;

  const mapSettings = {
    defaultCenter: [venues[0].lat, venues[0].lng],
    defaultZoom,
    bootstrapURLKeys: {
      key: RAZZLE_GMAPS_API_KEY,
      language: 'it',
      region: 'it',
    },
    options: {
      zoomControl: false,
      fullscreenControl: false,
    },
  };

  if (venues.length > 1) {
    const bounds = getBounds(
      venues.map((venue) => ({
        latitude: venue.lat,
        longitude: venue.lng,
      })),
    );

    const { center, zoom } = fitBounds(
      {
        ne: {
          lat: bounds.maxLat,
          lng: bounds.maxLng,
        },
        sw: {
          lat: bounds.minLat,
          lng: bounds.minLng,
        },
      },
      {
        width: 480,
        height: parseInt(height, 10),
      },
    );

    mapSettings.defaultCenter = center;
    mapSettings.defaultZoom = zoom;
  }

  return (
    <div style={{ width: '100%', height, position: 'relative' }}>
      <ConditionalEmbed url="google.com/maps">
        <GoogleMapReact {...mapSettings} title="Google Map">
          {venues &&
            venues.map((el, idx) => (
              <MapMarker
                venue={el}
                id={idx}
                lat={el.lat}
                lng={el.lng}
                mainMarker={mainMarker === idx}
                showTitle={showTitle}
                showPrice={showPrice}
                showPopup={showPopup}
                addMarkerLink={addMarkerLink}
                key={`${el.lat},${el.lng}${idx}`}
              />
            ))}
        </GoogleMapReact>
      </ConditionalEmbed>
    </div>
  );
};

GoogleMap.propTypes = {
  height: PropTypes.string,
  showTitle: PropTypes.bool,
  showPrice: PropTypes.bool,
  showPopup: PropTypes.bool,
  addMarkerLink: PropTypes.bool,
  venues: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      title: PropTypes.string,
      price: PropTypes.string,
    }),
  ).isRequired,
  mainMarker: PropTypes.number,
  defaultZoom: PropTypes.number,
};

export default GoogleMap;
