/**
 * Edit icons block.
 * @module components/manage/Blocks/Title/Edit
 */

import React, { Component } from 'react';

import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebar';
import Body from './Body';

/**
 * Edit icons block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <>
        <Body {...this.props.data} />

        <SidebarPortal selected={this.props.selected}>
          <Sidebar {...this.props} />
        </SidebarPortal>
      </>
    );
  }
}

export default Edit;
