import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Icon, TextWidget } from '@plone/volto/components';

import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';

import { AlignWidget, LinkToWidget } from '@package/components/';
import CTAIcons from './Icon/icons';

import { IconsWidget } from '@package/components';

const messages = defineMessages({
  iconsPositionBottom: {
    id: 'Icons position bottom',
    defaultMessage: 'in basso',
  },
  iconsPositionRight: {
    id: 'Icons position right',
    defaultMessage: 'a destra',
  },
  LinkSettings: {
    id: 'Link settings',
    defaultMessage: 'Impostazioni Link',
  },
  LinkToTitle: {
    id: 'Link title',
    defaultMessage: 'Titolo link',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Apri in un nuovo tab',
  },
  iconsPosition: {
    id: 'Icons position',
    defaultMessage: 'Posizione delle icone',
  },
  Settings: {
    id: 'Settings',
    defaultMessage: 'Impostazioni',
  },
});

const Sidebar = ({
  data,
  block,
  onChangeBlock,
  onChangeSubblocks,
  openObjectBrowser,
  required = false,
  intl,
  selected,
  setSelected,
}) => {
  const [activeAccIndex, setActiveAccIndex] = useState(0);

  function handleAccClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeAccIndex === index ? -1 : index;

    setActiveAccIndex(newIndex);
  }

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Cta Box" defaultMessage="Box Call to action" />:{' '}
          {data.title}
        </h2>
      </header>
      <Accordion fluid styled className="form">
        <Accordion.Title
          active={activeAccIndex === 0}
          index={0}
          onClick={handleAccClick}
        >
          {intl.formatMessage(messages.Settings)}
          {activeAccIndex === 0 ? (
            <Icon name={upSVG} size="20px" />
          ) : (
            <Icon name={downSVG} size="20px" />
          )}
        </Accordion.Title>
        <Accordion.Content active={activeAccIndex === 0}>
          <AlignWidget
            align={data.iconsPosition ?? 'bottom'}
            onChange={(align) => {
              onChangeBlock(block, {
                ...data,
                iconsPosition: align,
              });
            }}
            alignments={['bottom', 'right']}
            label={intl.formatMessage(messages.iconsPosition)}
            showLabel={true}
            iconsWithArrow={true}
          />
        </Accordion.Content>
        <Accordion.Title
          active={activeAccIndex === 1}
          index={1}
          onClick={handleAccClick}
        >
          {intl.formatMessage(messages.LinkSettings)}
          {activeAccIndex === 1 ? (
            <Icon name={upSVG} size="20px" />
          ) : (
            <Icon name={downSVG} size="20px" />
          )}
        </Accordion.Title>
        <Accordion.Content active={activeAccIndex === 1}>
          <TextWidget
            id="linkText"
            title={intl.formatMessage(messages.LinkToTitle)}
            required={false}
            value={data.linkTitle}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                linkTitle: value,
              });
            }}
          />
          <LinkToWidget
            data={data}
            openObjectBrowser={openObjectBrowser}
            onChange={(name, value) =>
              onChangeBlock(block, {
                ...data,
                [name]: value,
              })
            }
          />
        </Accordion.Content>
      </Accordion>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Icone" defaultMessage="Icone" />
        </h2>
      </header>
      <Accordion fluid styled className="form">
        {data.subblocks &&
          data.subblocks.map((subblock, index) => (
            <div key={`subblock-${index}`}>
              <Accordion.Title
                active={selected === index}
                index={index}
                onClick={() => {
                  setSelected(selected === index ? null : index);
                }}
              >
                <FormattedMessage id="Icona" defaultMessage="Icona" />{' '}
                {index + 1}
                {selected === index ? (
                  <Icon name={upSVG} size="20px" />
                ) : (
                  <Icon name={downSVG} size="20px" />
                )}
              </Accordion.Title>
              <Accordion.Content active={selected === index}>
                <IconsWidget
                  value={subblock.name}
                  icons={CTAIcons}
                  onChange={(icon) => {
                    onChangeSubblocks(index, {
                      ...subblock,
                      name: icon,
                    });
                  }}
                />
              </Accordion.Content>
            </div>
          ))}
      </Accordion>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
