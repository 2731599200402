import React, { useState, useRef } from 'react';
import { Icon } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const messages = defineMessages({
  viewImage: {
    id: "Vedi l'immagine",
    defaultMessage: "Vedi l'immagine",
  },
  play: {
    id: 'Play slider',
    defaultMessage: 'Play',
  },
  pause: {
    id: 'Pause slider',
    defaultMessage: 'Metti in pausa',
  },
});

const SliderWrapper = ({
  autoplay = false,
  autoplay_speed = 5,
  slidesToShow = 1,
  show_dots = true,
  n_items = 0,
  children,
}) => {
  const intl = useIntl();
  const slider = useRef(null);
  const [userAutoplay, setUserAutoplay] = useState(autoplay);
  const nSlidesToShow = parseInt(slidesToShow);

  const toggleAutoplay = () => {
    if (!slider?.current) return;
    if (userAutoplay) {
      setUserAutoplay(false);
      slider.current.slickPause();
    } else {
      setUserAutoplay(true);
      slider.current.slickPlay();
    }
  };

  const settings = {
    dots: show_dots,
    infinite: true,
    autoplay: autoplay,
    speed: 1000,
    slidesToShow: nSlidesToShow,
    slidesToScroll: nSlidesToShow,
    autoplaySpeed: autoplay_speed * 1000,
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    swipe: true,
    swipeToSlide: true,
    focusOnSelect: true,
    draggable: true,
    accessibility: true,
    adaptiveHeight: false,

    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return n_items > 0 ? (
    <div className="slider-wrapper">
      {n_items > nSlidesToShow && (
        <div className="play-pause-wrapper">
          <button
            onClick={() => toggleAutoplay()}
            title={
              userAutoplay
                ? intl.formatMessage(messages.pause)
                : intl.formatMessage(messages.play)
            }
          >
            <Icon name={userAutoplay ? 'pause' : 'play'} />
            <span>{userAutoplay ? 'pause' : 'play'}</span>
          </button>
        </div>
      )}

      <Slider {...settings} ref={slider}>
        {children}
      </Slider>
    </div>
  ) : null;
};

export default React.memo(SliderWrapper);
