import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  Event: {
    id: 'Event',
    defaultMessage: 'Evento',
  },
  Esperienza: {
    id: 'Esperienza',
    defaultMessage: 'Esperienza',
  },
  StrutturaRicettiva: {
    id: 'StrutturaRicettiva',
    defaultMessage: 'Struttura ricettiva',
  },
  Ristorante: {
    id: 'Ristorante',
    defaultMessage: 'Ristorante',
  },
  Itinerario: {
    id: 'Itinerario',
    defaultMessage: 'Itinerario',
  },
});

const TypeLabel = ({ type, children, intl }) => {
  let typeName = type
    ? messages[type]
      ? intl.formatMessage(messages[type])
      : type
    : null;

  return (
    <div className="type-label">
      <span>
        {typeName}
        {children}
      </span>
    </div>
  );
};
export default injectIntl(TypeLabel);
