/**
 * View title block.
 * @module components/manage/Blocks/Title/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ButtonLink } from '@package/components/';

/**
 * View title block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  return (
    <div className={cx('button-block', data.align)}>
      <ButtonLink
        to={data.href}
        title={data.title}
        targetBlank={data.openLinkInNewTab}
        color={data.color}
        showArrow={data.showArrowOnLink}
      />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
