/**
 * ViewBlock.
 * @module components/Blocks/IconsBlock/Block/ViewIcon
 */

import React from 'react';
import PropTypes from 'prop-types';
import ListIcons from '../icons';
import { Icon } from '@plone/volto/components';

/**
 * ViewIcon class.
 * @class ViewIcon
 * @extends Component
 */
const ViewIcon = ({ icon, size }) => {
  return (
    <>
      {icon && (
        <div className="block-icon">
          <Icon name={ListIcons[icon]} size={size} />
        </div>
      )}
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ViewIcon.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default ViewIcon;
