/**
 * CtaBoxWidget component.
 * @module components/manage/Widgets/CtaBoxWidget
 */

import React, { Component } from 'react';
import { compose } from 'redux';

import PropTypes from 'prop-types';
import { Form, Grid, Icon, Label } from 'semantic-ui-react';
import { map } from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';

import { Portal } from 'react-portal';
import { Sidebar } from '@plone/volto/components';

import EditCTABox from '@package/components/Blocks/CtaBox/EditCTABox';

const messages = defineMessages({
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  idCTATitle: {
    id: 'Call to action',
    defaultMessage: 'Call to action',
  },
  idCTADescription: {
    id: 'Used to show a CTA box',
    defaultMessage: 'Usato per mostrare un box di tipo Call to Action',
  },
});

/**
 * CtaBoxWidget component class.
 * @function CtaBoxWidget
 * @returns {string} Markup of the component.
 */
class CtaBoxWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    onChange: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    description: null,
    required: false,
    error: [],
    value: null,
    onChange: null,
    onEdit: null,
    onDelete: null,
  };

  schema = {
    fieldsets: [
      {
        id: 'default',
        title: this.props.intl.formatMessage(messages.default),
        fields: ['call_to_action'],
      },
    ],
    properties: {
      call_to_action: {
        type: 'string',
        title: this.props.intl.formatMessage(messages.idCTATitle),
        description: this.props.intl.formatMessage(messages.idCTADescription),
      },
    },
  };

  onChangeWidget = (id, value) => {
    this.props.onChange(this.props.id, value);
  };

  ref = React.createRef();

  render() {
    const {
      required,
      error,
      description,
      fieldSet,
      id,
      onEdit,
      title,
      onDelete,
      value,
    } = this.props;

    const data = value ? value : {};

    return (
      <Form.Field
        inline
        required={required}
        error={error.length > 0}
        className={description ? 'help ctaBoxWidget' : 'ctaBoxWidget'}
        id={`${fieldSet || 'field'}-${id}`}
      >
        <Grid>
          <Grid.Row stretched>
            <Grid.Column width="4">
              <div className="wrapper">
                <label htmlFor={`field-${id}`}>
                  {onEdit && (
                    <i
                      aria-hidden="true"
                      className="grey bars icon drag handle"
                    />
                  )}
                  {title}
                </label>
              </div>
            </Grid.Column>
            <Grid.Column width="8">
              {onEdit && (
                <div className="toolbar">
                  <button
                    className="item ui noborder button"
                    onClick={() => onEdit(id, this.schema)}
                  >
                    <Icon name="write square" size="large" color="blue" />
                  </button>
                  <button
                    aria-label={this.props.intl.formatMessage(messages.delete)}
                    className="item ui noborder button"
                    onClick={() => onDelete(id)}
                  >
                    <Icon name="close" size="large" color="red" />
                  </button>
                </div>
              )}
              <EditCTABox
                selected={true}
                block={id}
                index={1}
                data={data}
                onChangeBlock={this.onChangeWidget}
                blockNode={this.ref}
                onSelectBlock={() => {}}
                onFocusNextBlock={() => {}}
                onFocusPreviousBlock={() => {}}
                onMutateBlock={() => {}}
                onDeleteBlock={() => {}}
                onAddBlock={() => {}}
              />

              <Portal node={__CLIENT__ && document.getElementById('sidebar')}>
                <Sidebar />
              </Portal>

              {map(error, message => (
                <Label key={message} basic color="red" pointing>
                  {message}
                </Label>
              ))}
            </Grid.Column>
          </Grid.Row>
          {description && (
            <Grid.Row stretched>
              <Grid.Column stretched width="12">
                <p className="help">{description}</p>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Form.Field>
    );
  }
}

export default compose(injectIntl)(CtaBoxWidget);
