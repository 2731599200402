import React from 'react';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ConditionalLink } from '@plone/volto/components';
import TagsView from './TagsView';
import redraft from 'redraft';
import { PriceRangeIndications, ListingImage } from '@package/components';
import config from '@plone/volto/registry';

const DefaultItem = ({ item, isEditMode, belowTitle = false, showDescription = true}) => {
  return (
    <div className="item default-item">

      {belowTitle == false && (
        <div className="item-title">
          <ConditionalLink
            to={flattenToAppURL(item['@id'])}
            condition={!isEditMode}
          >
            <h3>{item.title ? item.title : item.id}</h3>
          </ConditionalLink>
        </div>
      )}

      <div className="item-border" />

      <div className="item-content">
        <ConditionalLink
          to={flattenToAppURL(item['@id'])}
          condition={!isEditMode}
          aria-hidden="true"
          role="presentation"
          tabIndex="-1"
        >
          {item.thematic_area && (
            <TagsView subjects={[item.thematic_area.title]} />
          )}

          <ListingImage item={item} />

          <PriceRangeIndications item={item} />
        </ConditionalLink>
        {belowTitle == true && (
              <div className="item-title">
                <ConditionalLink
                  to={flattenToAppURL(item['@id'])}
                  condition={!isEditMode}
                >
                  <h3>{item.title ? item.title : item.id}</h3>
                </ConditionalLink>
              </div>

            )}
        {showDescription == true && item.description && (
          <div className="item-description">
            {item.description}
          </div>
        )}
      </div>
    </div>
  );
};

DefaultItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DefaultItem;
