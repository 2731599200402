import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  book_now: {
    id: 'Book now',
    defaultMessage: 'Prenota ora!',
  },
});

const Booking = ({ content, intl }) => {
  if (!content.booking_button_url) {
    return null;
  }

  const url_label =
    content.booking_button_label ?? intl.formatMessage(messages.book_now);

  let link = (
    <a
      href={content.booking_button_url}
      rel="noopener noreferrer"
      target="_blank"
    >
      {url_label}
    </a>
  );

  return (
    <section className="cta-ticket">
      <div className="cta-ticket-link">{link}</div>
    </section>
  );
};

Booking.propTypes = {
  content: PropTypes.object.isRequired,
};

export default injectIntl(Booking);
