/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';

import { ModenaTurFooter } from '@package/components';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */

const Footer = () => {
  return <ModenaTurFooter />;
};
export default Footer;
