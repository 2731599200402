/**
 * ViewBlock.
 * @module components/manage/Blocks/ImageBlocks/Block/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import redraft from 'redraft';
import { Container } from 'semantic-ui-react';
import { ImageFromUrl } from '@package/components';
import { blockTextRenderMap } from './settings';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';

/**
 * ViewBlock class.
 * @class ViewBlock
 * @extends Component
 */
const ViewBlock = ({ data, big = false }) => {
  let content = (
    <>
      <div className="header">
        <ImageFromUrl
          url={data.url}
          className="bg"
          role="presentation"
          alt=""
          size={big ? 'leadimage' : 'preview'}
        />
        {data.title && (
          <div className="overlay-text">
            <Container>
              <div className="title">
                {redraft(
                  data.title,
                  {
                    ...config.settings.richtextViewSettings.ToHTMLRenderers,
                    blocks: {
                      ...config.settings.richtextViewSettings.ToHTMLRenderers
                        .blocks,
                      ...blockTextRenderMap,
                    },
                  },
                  config.settings.richtextViewSettings.ToHTMLOptions,
                )}
              </div>
            </Container>
          </div>
        )}
      </div>
      {data.description && (
        <div className="description">
          {redraft(
            data.description,
            {
              ...config.settings.richtextViewSettings.ToHTMLRenderers,
              blocks: {
                ...config.settings.richtextViewSettings.ToHTMLRenderers.blocks,
                ...blockTextRenderMap,
              },
            },
            config.settings.richtextViewSettings.ToHTMLOptions,
          )}
        </div>
      )}
    </>
  );

  if (data.href) {
    content = (
      <UniversalLink href={data.href} openLinkInNewTab={data.openLinkInNewTab}>
        {content}
      </UniversalLink>
    );
  }

  return <div className="single-block">{content}</div>;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ViewBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewBlock;
