/**
 * View text block.
 * @module components/manage/Blocks/Text/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import ViewBlock from './Block/ViewBlock';
/**
 * View text block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  return (
    <div
      className={
        'contactsBlocks' + (data.subblocks.length >= 3 ? ' three-columns' : '')
      }
    >
      {data.subblocks.map((subblock, index) => (
        <ViewBlock data={subblock} nblock={data.subblocks.length} key={index} />
      ))}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
