/**
 * View image block.
 * @module components/Blocks/FullImage/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';
import { ImageFromUrl, ButtonLink } from '@package/components/';
import { Grid, Container } from 'semantic-ui-react';
import redraft from 'redraft';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import config from '@plone/volto/registry';

const messages = defineMessages({
  goToSection: {
    id: 'Go to section',
    defaultMessage: 'Vai alla sezione',
  },
});

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = ({ data, detached, intl }) => {
  return (
    <div className="block section_links">
      <div
        className={cx('section-links-block full-width', data.backgroundColor, {
          detached,
        })}
      >
        <Container>
          <Grid stackable>
            {data.url && (
              <Grid.Column computer={3} tablet={12}>
                <ImageFromUrl
                  url={data.url}
                  role="presentation"
                  alt=""
                  size="preview"
                />
              </Grid.Column>
            )}
            <Grid.Column
              computer={data.url ? 9 : 12}
              tablet={data.url ? 9 : 12}
            >
              {data.title && <h2>{redraft(data.title)}</h2>}
              {data.description && (
                <div className="description">
                  {redraft(
                    data.description,
                    config.settings.richtextViewSettings.ToHTMLRenderers,
                    config.settings.richtextViewSettings.ToHTMLOptions,
                  )}
                </div>
              )}
              {data.subblocks?.length > 0 && (
                <Grid className="links subblocks" stackable>
                  {data.subblocks.map((subblock, index) => (
                    <>
                      {subblock.href && (
                        <Grid.Column
                          className="single-block"
                          key={`subblock_${index}`}
                          computer={6}
                          tablet={12}
                        >
                          <ConditionalLink
                            to={flattenToAppURL(subblock.href)}
                            condition={subblock.href !== null}
                          >
                            {redraft(subblock.title)}
                          </ConditionalLink>
                        </Grid.Column>
                      )}
                    </>
                  ))}
                </Grid>
              )}
            </Grid.Column>
          </Grid>

          {data.href && (
            <div className="footer">
              <ButtonLink
                to={data.href}
                title={intl.formatMessage(messages.goToSection)}
                button={false}
                _className="link"
              />
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default compose(injectIntl)(View);
