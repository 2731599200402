import React from 'react';
import PropTypes from 'prop-types';

import { defineMessages, injectIntl } from 'react-intl';
import { Image } from 'semantic-ui-react';
import { flattenHTMLToAppURL, flattenToAppURL } from '@plone/volto/helpers';
import TappaExpanded from './TappaExpanded';
import { srcset } from '@package/helpers';
import { ConditionalEmbed } from 'volto-gdpr-privacy';

const messages = defineMessages({
  OrdinalNumF_1: {
    id: 'OrdinalNumF_1',
    defaultMessage: 'Prima',
  },
  OrdinalNumF_2: {
    id: 'OrdinalNumF_2',
    defaultMessage: 'Seconda',
  },
  OrdinalNumF_3: {
    id: 'OrdinalNumF_3',
    defaultMessage: 'Terza',
  },
  OrdinalNumF_4: {
    id: 'OrdinalNumF_4',
    defaultMessage: 'Quarta',
  },
  OrdinalNumF_5: {
    id: 'OrdinalNumF_5',
    defaultMessage: 'Quinta',
  },
  OrdinalNumF_6: {
    id: 'OrdinalNumF_6',
    defaultMessage: 'Sesta',
  },
  OrdinalNumF_7: {
    id: 'OrdinalNumF_7',
    defaultMessage: 'Settima',
  },
  OrdinalNumF_8: {
    id: 'OrdinalNumF_8',
    defaultMessage: 'Ottava',
  },
  OrdinalNumF_9: {
    id: 'OrdinalNumF_9',
    defaultMessage: 'Nona',
  },
  OrdinalNumF_10: {
    id: 'OrdinalNumF_10',
    defaultMessage: 'Decima',
  },
  OrdinalNumF_11: {
    id: 'OrdinalNumF_11',
    defaultMessage: 'Undicesima',
  },
  OrdinalNumF_12: {
    id: 'OrdinalNumF_12',
    defaultMessage: 'Dodicesima',
  },
  OrdinalNumF_13: {
    id: 'OrdinalNumF_13',
    defaultMessage: 'Tredicesima',
  },
  OrdinalNumF_14: {
    id: 'OrdinalNumF_14',
    defaultMessage: 'Quattordicesima',
  },
  OrdinalNumF_15: {
    id: 'OrdinalNumF_15',
    defaultMessage: 'Quindicesima',
  },
});

const DayExpanded = ({ day, intl }) => {
  return (
    <section className="day-expanded">
      <h2>{day.title}</h2>
      {day.itinerary_map && (
        <div className="itinerary-map">
          {/* <h3>
            <FormattedMessage id="panoramica" defaultMessage="Panoramica" />
          </h3> */}
          <ConditionalEmbed code={day.itinerary_map}>
            <div
              dangerouslySetInnerHTML={{
                __html: day.itinerary_map,
              }}
            />
          </ConditionalEmbed>
        </div>
      )}

      {(day.description || day.text || day.image?.scales?.large?.download) && (
        <div className="itinerary-day">
          <div className="itinerary-day-content">
            {day.description && (
              <div className="description">{day.description}</div>
            )}
            {day.text && (
              <div className="text">
                <div
                  className="p-no-margin"
                  dangerouslySetInnerHTML={{
                    __html: flattenHTMLToAppURL(day.text.data),
                  }}
                />
              </div>
            )}

            {day.image?.scales?.large?.download && (
              <figure>
                <Image
                  src={flattenToAppURL(day.image.scales.large.download)}
                  alt=""
                  loading="lazy"
                  srcSet={srcset(day.image, 'large')}
                />
              </figure>
            )}
          </div>
        </div>
      )}

      {day.steps?.map((tappa, index) => {
        var number = messages['OrdinalNumF_' + (index + 1)];
        return (
          <TappaExpanded
            tappa={tappa}
            number={number ? intl.formatMessage(number) : index + '°'}
            key={`step${index}`}
          />
        );
      })}
    </section>
  );
};
DayExpanded.propTypes = {
  day: PropTypes.object.isRequired,
};

export default injectIntl(DayExpanded);
