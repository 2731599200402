import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { searchContent, resetSearchContent } from '@plone/volto/actions';
import { EventWhen } from '@package/components';

const WeekendEvents = ({ intl }) => {
  moment.locale(intl.locale);

  const today = moment();

  const start = today.startOf('week').add(5, 'days').format('YYYY-MM-DD'); // monday+5 to get friday
  const end = today
    .endOf('week')
    .add(1, 'days') //add 1 day to get sunday
    .format('YYYY-MM-DD');
  const url = '/' + intl.locale;
  const searchResults = useSelector((state) => state.search.subrequests);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      searchContent(
        url,
        {
          'start.query': end,
          'start.range': 'max',
          'end.query': start,
          'end.range': 'min',
          Subject: 'itinerariEventi',
          portal_type: 'Event',
          review_state: 'published',
          sort_on: 'start',
          fullobjects: 1,
          block: 'weekendevents',
          b_size: 10,
        },
        'weekend_events',
      ),
    );

    return () => {
      dispatch(resetSearchContent('weekend_events'));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, url]);

  const events = searchResults?.['weekend_events']?.items || [];

  return events?.length > 0 ? (
    <section className="weekend-events">
      <div className="info-box-header">
        <FormattedMessage
          id="ThisWeekendEvents"
          defaultMessage="Eventi di questo weekend"
        />
      </div>
      <div className="info-box-subheader">
        <EventWhen start={start} end={end} intl={intl} whole_day={true} />
      </div>
      <div className="link-list">
        {events.map((event) => (
          <Link to={event['@id']} key={event['@id']} className="link-item">
            {event.title}
          </Link>
        ))}
      </div>
    </section>
  ) : null;
};

export default injectIntl(WeekendEvents);
