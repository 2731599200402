/**
 * View text block.
 * @module components/manage/Blocks/Text/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import ViewBlock from './Block/ViewBlock';
import { Grid, Container } from 'semantic-ui-react';
import redraft from 'redraft';
import config from '@plone/volto/registry';
/**
 * View text block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  return (
    <div className="block imageBlocks">
      <div
        className={`subblocks full-width ${
          data.backgroundColor ? data.backgroundColor : ''
        }`}
      >
        <Container>
          {data.title ? (
            <div className="titleVM center">
              {redraft(
                data.title,
                {
                  ...config.settings.richtextViewSettings.ToHTMLRenderers,
                  blocks: {
                    ...config.settings.richtextViewSettings.ToHTMLRenderers
                      .blocks,
                    unstyled: (children, { keys }) =>
                      children.map((child, i) => (
                        <h2 id={keys[i]} key={keys[i]}>
                          {child}
                        </h2>
                      )),
                  },
                },
                config.settings.richtextViewSettings.ToHTMLOptions,
              )}
            </div>
          ) : (
            ''
          )}

          {data.description
            ? redraft(
                data.description,
                config.settings.richtextViewSettings.ToHTMLRenderers,
                config.settings.richtextViewSettings.ToHTMLOptions,
              )
            : ''}

          <Grid stackable columns="equal" verticalAlign="top">
            <Grid.Row columns={3}>
              {data.subblocks.map((subblock, index) => {
                const big =
                  data.subblocks.length % 3 > 0 &&
                  data.subblocks.length - (index + 1) <
                    data.subblocks.length % 3;

                return (
                  <Grid.Column key={subblock.id}>
                    <ViewBlock data={subblock} big={big} />
                  </Grid.Column>
                );
              })}
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
