/**
 * Edit image block.
 * @module components/Blocks/FullImage/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Map } from 'immutable';
import cx from 'classnames';

import { Container, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';

import { SidebarPortal } from '@plone/volto/components';
import { createContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

import {
  ImageDropzoneWidget,
  ImageFromUrl,
  ButtonLink,
  TextEditorWidget,
} from '@package/components';

import Sidebar from './Sidebar';

const messages = defineMessages({
  ImageBlockInputPlaceholder: {
    id: 'Browse the site, drop an image, or type an URL',
    defaultMessage: "Cerca nel sito, trascina un'immagine, o inserisci un URL",
  },
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Descrizione',
  },
});

const blockTitleRenderMap = Map({
  unstyled: {
    element: 'div',
  },
});

const blockDescriptionRenderMap = Map({
  unstyled: {
    element: 'div',
  },
});

const OverlayText = ({ key, state, setState, props }) => (
  <div className="overlay-text" key={key}>
    <Container>
      <div className="title">
        <TextEditorWidget
          data={props.data}
          fieldName="title"
          selected={state.currentFocused === 'title'}
          block={props.block}
          onChangeBlock={(data) => props.onChangeBlock(props.block, data)}
          placeholder={props.intl.formatMessage(messages.title)}
          showToolbar={false}
          blockRenderMap={blockTitleRenderMap}
          blockStyleFn={() => 'title-editor title'}
          nextFocus={() => {
            setState({
              currentFocused: 'description',
            });
          }}
          enablePlugins={false}
          textPlain={true}
        />
      </div>
      <div className="description">
        <TextEditorWidget
          data={props.data}
          fieldName="description"
          selected={state.currentFocused === 'description'}
          block={props.block}
          onChangeBlock={(data) => props.onChangeBlock(props.block, data)}
          placeholder={props.intl.formatMessage(messages.description)}
          showToolbar={false}
          blockRenderMap={blockDescriptionRenderMap}
          blockStyleFn={() => 'description-editor description'}
          prevFocus={() => {
            setState({
              currentFocused: 'title',
            });
          }}
          enablePlugins={false}
          textPlain={true}
        />
      </div>

      <ButtonLink
        to={'#'}
        title={props.data.linkTitle}
        targetBlank={props.data.openLinkInNewTab}
        color={props.data.buttonLinkColor}
        showArrow={props.data.showArrowOnLink}
        _className={props.data.buttonLinkAlign}
      />
    </Container>
  </div>
);
/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    if (!__SERVER__) {
      if (!props.data.align) {
        props.data.align = 'full'; //default
      }
      if (!props.data.size) {
        props.data.size = 'small'; //default
      }
      if (!this.props.data.link) {
        this.props.data.link = {};
      }
      if (!props.data.buttonLinkColor) {
        props.data.buttonLinkColor = 'red'; //default
      }

      this.state = {
        currentFocused: 'title',
      };
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const videoPoster = this.props.data.video_poster?.[0]?.image?.scales
      ?.leadimage?.download;
    const video = this.props.data.video?.[0];
    const videoUrl = video
      ? video.file?.download ?? video.getURL + '/@@download/file'
      : null;
    const videoMimeType = video?.file?.['content-type'] ?? video?.mime_type;

    return (
      <div className={cx('block')}>
        {this.props.data.bgType === 'video' ? (
          <>
            {videoUrl ? (
              <div
                className={cx(
                  {
                    'full-width': this.props.data.align === 'full',
                    'bg-video': this.props.data.bgType === 'video',
                  },
                  this.props.data.size,
                )}
              >
                <video
                  video
                  loop={true}
                  muted={true}
                  autoPlay={true}
                  poster={videoPoster ? flattenToAppURL(videoPoster) : null}
                >
                  <source
                    type={videoMimeType}
                    src={flattenToAppURL(videoUrl)}
                  />
                </video>
                <OverlayText
                  key={'overlay-text-video'}
                  state={this.state}
                  setState={this.setState}
                  props={this.props}
                />
              </div>
            ) : (
              <Message>Seleziona un video dalla barra a lato</Message>
            )}
          </>
        ) : (
          <ImageDropzoneWidget
            {...this.props}
            onChangeBlock={(name, value) =>
              this.props.onChangeBlock(this.props.block, {
                ...this.props.data,
                [name]: value,
              })
            }
          >
            <div
              className={cx(
                {
                  'full-width': this.props.data.align === 'full',
                },
                this.props.data.size,
              )}
            >
              {this.props.data.url && (
                <ImageFromUrl
                  url={this.props.data.url}
                  title={this.props.data.title}
                  className="bg"
                  size="leadimage"
                />
              )}
              <OverlayText
                key={'overlay-text-image'}
                state={this.state}
                setState={this.setState}
                props={this.props}
              />
            </div>
          </ImageDropzoneWidget>
        )}
        <SidebarPortal selected={this.props.selected}>
          <Sidebar {...this.props} />
        </SidebarPortal>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      request: state.content.create,
      content: state.content.data,
    }),
    { createContent },
  ),
)(Edit);
