import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { ItemTemplates, DefaultItem } from '@package/components';

const RelatedItems = ({ title, items = [], withTags = true, className }) => {
  return (
    <section className={'related-items ' + (className || '')}>
      {title && <h2>{title}</h2>}

      <Grid stackable columns={4} verticalAlign="top">
        {items.map((item) => {
          let ItemTemplate = ItemTemplates[item['@type']] || DefaultItem;
          return (
            <Grid.Column key={item['@id']}>
              <ItemTemplate item={item} withTags={withTags} />
            </Grid.Column>
          );
        })}
      </Grid>
    </section>
  );
};

RelatedItems.propTypes = {
  items: PropTypes.array,
  withTags: PropTypes.bool,
};

export default RelatedItems;
