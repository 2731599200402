/**
 * ViewBlock.
 * @module components/manage/Blocks/Text/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import redraft from 'redraft';
import cx from 'classnames';
import { UniversalLink } from '@plone/volto/components';
import ViewIcon from './ViewIcon';

import config from '@plone/volto/registry';

/**
 * ViewBlock class.
 * @class ViewBlock
 * @extends Component
 */
const ViewBlock = ({ data }) => {
  let content = (
    <>
      <ViewIcon icon={data.icon} size="40px" />
      {data.description && (
        <div className="description">
          {redraft(
            data.description,
            config.settings.richtextViewSettings.ToHTMLRenderers,
            config.settings.richtextViewSettings.ToHTMLOptions,
          )}
        </div>
      )}
    </>
  );

  if (data.href) {
    content = (
      <UniversalLink href={data.href} openLinkInNewTab={data.openLinkInNewTab}>
        {content}
      </UniversalLink>
    );
  }

  return <div className={cx('single-block', data.block_style)}>{content}</div>;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ViewBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewBlock;
