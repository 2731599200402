import { ObjectBrowserWidgetMode } from '@plone/volto/components/manage/Widgets/ObjectBrowserWidget';
import { CtaBoxWidget, GeoLocationWidget } from '@package/components';

export const VisitWidgets = (config) => {
  config.widgets = {
    ...config.widgets,
    id: {
      ...config.widgets.id,
      call_to_action: CtaBoxWidget,
      geolocation: GeoLocationWidget,
      root_side_menu: ObjectBrowserWidgetMode('link'),
    },
  };
};
