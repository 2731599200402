/**
 * Edit title block.
 * @module components/Blocks/TitleVM/Edit
 */

import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';

import { SidebarPortal } from '@plone/volto/components';

import { TextEditorWidget } from '@package/components';
import rightArrowSVG from '@package/components/ButtonLink/arrow-link.svg';
import Sidebar from './Sidebar';

const messages = defineMessages({
  title: {
    id: 'Type the title…',
    defaultMessage: 'Type the title…',
  },
});

const blockRenderMap = Map({
  unstyled: {
    element: 'span',
  },
});

/**
 * Edit title block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    properties: PropTypes.objectOf(PropTypes.any).isRequired,
    selected: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onAddBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    block: PropTypes.string.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    if (!__SERVER__) {
      let editorState;
      if (!this.props.data.color) {
        this.props.data.color = 'yellow';
      }
      this.state = { editorState, focus: true };
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.selected && nextProps.selected) {
      this.setState({ focus: true });
    }
  }

  onUpdateProperties = (field, value) => {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      [field]: value,
    });
  };
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <div className={cx('button-block', this.props.data.align)}>
        <div className={cx('ui button buttonLink', this.props.data.color)}>
          <TextEditorWidget
            data={this.props.data}
            fieldName="title"
            selected={true}
            block={this.props.block}
            onChangeBlock={(data) =>
              this.props.onChangeBlock(this.props.block, data)
            }
            placeholder={this.props.intl.formatMessage(messages.title)}
            showToolbar={false}
            blockRenderMap={blockRenderMap}
            enablePlugins={false}
            textPlain={true}
          />

          {this.props.data.showArrowOnLink && (
            <img src={rightArrowSVG} alt="" className="icon" loading="lazy" />
          )}
        </div>
        <SidebarPortal selected={this.props.selected}>
          <Sidebar
            data={this.props.data}
            onChangeBlock={this.onUpdateProperties}
            block={this.props.block}
          />
        </SidebarPortal>
      </div>
    );
  }
}

export default injectIntl(Edit);
