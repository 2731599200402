import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Grid, Icon } from 'semantic-ui-react';
import { Icon as VoltoIcon } from '@plone/volto/components';
import CarDriverIcon from './icons/car-driver.svg';
import TastingIcon from './icons/tasting.svg';
import CarRent from './icons/car-rent.svg';

const IconList = {
  'car-driver': CarDriverIcon,
  tasting: TastingIcon,
  'car-rent': CarRent,
};

const messages = defineMessages({
  IncludedServices: {
    id: 'Included services',
    defaultMessage: 'Servizi inclusi',
  },
});

const Services = ({ services, columns, intl }) => {
  return (
    <>
      {services && services.length > 0 && (
        <section className="services">
          <h3>{intl.formatMessage(messages.IncludedServices)}</h3>

          <Grid
            columns={columns ? columns : 3}
            className="services-list"
            stackable
          >
            {services.map((service) => (
              <Grid.Column key={service.token}>
                <div className="service">
                  <div className="service-icon">
                    {Object.keys(IconList).indexOf(service.token) >= 0 ? (
                      <VoltoIcon name={IconList[service.token]} size="1em" />
                    ) : (
                      <Icon name={service.token}></Icon>
                    )}
                  </div>
                  <div className="service-label">{service.title}</div>
                </div>
              </Grid.Column>
            ))}
          </Grid>
        </section>
      )}
    </>
  );
};
export default injectIntl(Services);
