import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  price: {
    id: 'Price',
    defaultMessage: 'Prezzo',
  },
});

const getPriceParts = (price) => {
  const split = price.match(/[a-zA-Z]+|[€]+|[0-9]+(?:\.[0-9]+)?|\.[0-9]+/g);

  let parts = { order: [] };
  split.map((s) => {
    if (/^\d+$/.test(s)) {
      //number
      parts.amount = s;
      parts.order.push('amount');
    } else if (s === '€') {
      parts.currency = s;
      parts.order.push('currency');
    } else {
      parts.description =
        (parts.description ? parts.description + ' ' : '') + s;
      if (parts.order.indexOf('description') < 0) {
        parts.order.push('description');
      }
    }
  });

  return (
    <>
      {parts.order.map((f, index) => (
        <span className={f} key={f + index}>
          {parts[f]}
        </span>
      ))}
    </>
  );
};

const Prices = ({ prices, title, intl }) => {
  return (
    <>
      {prices && prices.length > 0 && (
        <section className="price">
          <div className="price-header">
            {title ? title : intl.formatMessage(messages.price)}
          </div>
          {prices.map((price) => (
            <div className="price-value" key={price}>
              {getPriceParts(price)}
            </div>
          ))}
        </section>
      )}
    </>
  );
};
export default injectIntl(Prices);
