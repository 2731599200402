import {
  TOGGLE_WHISHLIST,
  CLEAR_WHISHLIST,
  SET_WHISHLIST,
} from '@package/actions';

export default function whishlistReducer(state = [], action) {
  let newState = Object.assign([], state);

  switch (action.type) {
    case TOGGLE_WHISHLIST:
      const index = newState.indexOf(action.item);
      if (index >= 0) {
        newState.splice(index, 1);
      } else {
        newState.push(action.item);
      }
      break;
    case SET_WHISHLIST:
      newState = action.list ?? [];
      break;
    case CLEAR_WHISHLIST:
      newState = [];
      break;
    default:
      break;
  }

  // return the modified state
  return newState;
}
